import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { persistStore } from 'redux-persist';
import rootReducer from '../reducers';
import persistReducer from 'redux-persist/es/persistReducer';

const initialState = {};
const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['user'],
    version: 4
};
const persistedReducer = persistReducer<ReturnType<typeof rootReducer>>(persistConfig, rootReducer);

const store = createStore(
    persistedReducer,
    initialState,
    compose(applyMiddleware(thunk))
);

export default store;
export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
