import React from 'react';
import {
    FooterContainer,
    ErrorContainer,
    ErrorText,
    StyledContainer,
    AnnualPremiumText,
    ButtonsContainer,
    PremiumBreakdownContainer,
    StyledAnnualAmountText,
    StyledAnnualPremiumContainer,
    StyledBtnWrapper
} from './styles';
import { Button, Loader } from '..';
import { ISummaryFooter } from './types';
import { isCheckoutDisabled } from '../../../utils/featureFlags';

const SummaryFooter: React.FunctionComponent<ISummaryFooter> = ({
    memberTopUpPremium,
    onGoBackClick,
    onSaveDetailsClick,
    loading,
    policyId,
    error
}) => {
    return (
        <FooterContainer>
            <StyledContainer>
                {/* {!memberTopUpPremium && <div></div>} */}
                <PremiumBreakdownContainer>
                    <StyledAnnualPremiumContainer>
                        <AnnualPremiumText>Your Annual Contribution</AnnualPremiumText>
                        <StyledAnnualAmountText>
                            &#8377; {memberTopUpPremium?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </StyledAnnualAmountText>
                    </StyledAnnualPremiumContainer>
                    {/* <StyledAnnualPremiumContainer>
                        <AnnualPremiumText>| Total Coverage:</AnnualPremiumText>
                        <StyledAnnualAmountText>&#8377; {memberTopUpPremium}</StyledAnnualAmountText>
                    </StyledAnnualPremiumContainer> */}
                </PremiumBreakdownContainer>
                <ButtonsContainer>
                    <StyledBtnWrapper>
                        <Button type="secondary" fullWidth={true} disabled={false} onClick={onGoBackClick}>
                            {'Go Back'}
                        </Button>
                    </StyledBtnWrapper>
                    {(isCheckoutDisabled(policyId) && <></>) || (
                        <StyledBtnWrapper>
                            <Button type="primary" fullWidth={true} onClick={onSaveDetailsClick}>
                                {loading ? <Loader inverted /> : 'Checkout'}
                            </Button>
                        </StyledBtnWrapper>
                    )}
                </ButtonsContainer>
            </StyledContainer>
            {error?.message ? (
                <ErrorContainer>
                    <ErrorText>{error?.message}</ErrorText>
                </ErrorContainer>
            ) : null}
        </FooterContainer>
    );
};

export default SummaryFooter;
