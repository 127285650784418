import styled from 'styled-components';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    @media (max-width: 768px) {
        padding: 0px 10px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
    }
`;
export const StyledTitleRow = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
`;
export const StyledTableContainer = styled.div`
    display: table;
    width: 100%;
    border-collapse: collapse;
`;

export const StyledTableRow = styled.div`
    display: table-row;
    min-width: 100%;
    width: 100%;
    border-bottom: 1px solid ${(p) => p.theme.colors.border};
`;
export const StyledTableCell = styled.div<{ $showMobile: boolean }>(
    (p) => `
    display: table-cell;
    font-size: ${p.theme.fontSizes.label};
    color: ${p.theme.colors.mutedBG};
    text-align: center;
    border-radius: 8px;
    -moz-border-radius: 8px;
    position: relative;
    padding: 15px 0px;
    ${
    p.$showMobile ?
        'text-align: center;' :
        `@media (max-width: 768px) {
            display: none
        }`
}
`
);
export const StyledTableCellName = styled.div(
    (p) => `
    display: table-cell;
    font-size: ${p.theme.fontSizes.label};
    color: ${p.theme.colors.mutedBG};
    border-radius: 8px;
    -moz-border-radius: 8px;
    position: relative;
    width: 25%;
    padding: 15px 0px;
    @media (max-width: 768px) {
        width: 50%;
    }
`
);

export const StyledTableCellBody = styled.div<{ $showMobile: boolean }>(
    (p) => `
    display: table-cell;
    font-size: ${p.theme.fontSizes.body};
    color: ${p.theme.colors.black};
    line-height: 18px;
    text-align: center;
    border-radius: 8px;
    -moz-border-radius: 8px;
    position: relative;
    padding: 15px 0px;
    font-weight: bold;
    ${
    p.$showMobile ?
        'text-align: center;' :
        `@media (max-width: 768px) {
        display: none
    }`
}
`
);
export const StyledTableCellBodyName = styled.div(
    (p) => `
    display: table-cell;
    font-size: ${p.theme.fontSizes.body};
    color: ${p.theme.colors.black};
    line-height: 18px;
    border-radius: 8px;
    -moz-border-radius: 8px;
    position: relative;
    padding: 15px 0px;
    font-weight: bold;
`
);
export const StyledTitle = styled.div(
    (p) => `
    font-size: ${p.theme.fontSizes.heading};
    line-height: 22px;
    color: ${p.theme.colors.black};
    font-weight: bold;
`
);
