import styled from 'styled-components';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: space-between;
    max-width: 390px;
    min-height: 240px;
`;
export const StyledContent = styled.div`
    padding: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    @media (max-width: 768px) {
        padding: 10px;
    }
`;
export const StyledTitle = styled.div(
    (p) => `
    line-height: 22px;
    font-weight: bold;
    font-size: ${p.theme.fontSizes.heading};
    color: ${p.theme.colors.black};
    display: flex;
    justify-content: center;
    align-items: center;
`
);
export const StyledDescription = styled.p(
    (p) => `
    color: ${p.theme.colors.muted};
    font-size: ${p.theme.fontSizes.body};
    margin: 0 auto;
    margin-top: 15px;
    text-align: center;
    @media (max-width: 768px) {
        width: 285px;
    }
`
);
export const StyledRow = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
`;
export const CancelButton = styled.div(
    (p) => `
    color: ${p.theme.colors.primary};
    font-size: ${p.theme.fontSizes.body};
    border: 1px solid ${p.theme.colors.primary};
    font-weight: 700;
    min-width: 150px;
    cursor: pointer;
    border-radius: 8px;
    padding: 14px 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    @media (max-width: 768px) {
        width: 136px;
    }
`
);
export const ConfirmButton = styled.div(
    (p) => `
    color: ${p.theme.colors.white};
    font-size: ${p.theme.fontSizes.body};
    border: 1px solid ${p.theme.colors.error};
    background-color: ${p.theme.colors.error};
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    font-weight: 700;
    min-width: 150px;
    cursor: pointer;
    border-radius: 8px;
    padding: 14px 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
        width: 136px;
    }
`
);

export const StyledErrorText = styled.div(
    (p) => `
    color: ${p.theme.colors.error};
    font-size: ${p.theme.fontSizes.label};
    margin-bottom:20px;
    text-align: center;
`
);
