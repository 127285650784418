import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    StyledContainer,
    StyledCardWrapper,
    StyledImage,
    StyledPara,
    StyledPendingLabel,
    StyledPendingText,
    StyledWrapper,
    ConfirmationCard,
    ConfirmationContent,
    ConfirmationTextContainer,
    ConfirmationTextTitle,
    PendingInsuranceWrapper,
    PolicyDetailsCardHeader,
    TopupCard
} from './styles';
import { ConfirmationImage } from '../../../../../assets/img';
import { ReduxState } from '../../../../../redux';
import { setCurrentlySelectedPolicy, updateSelectedTopUp } from '../../../../../redux/slices/EnrolmentSlice';
import { IPolicy } from '../../../../../redux/slices/PolicyListSlice/types';
import { Loader, PendingInsuranceCard } from '../../../../atoms';
import { BeyondInsurance, ConfirmationCard as PolicyDetailsCard } from '../../../../containers';
import { useHistory, useLocation } from 'react-router';
import { INTERNAL_ROUTES } from '../../../../../utils/constants';
import {
    getDaysLeft,
    redirect,
    getPremiumAmount,
    insuranceCardDateFormat,
    checkIfTopUpAvailable,
    getSegmentPageName
} from '../../../../../utils/common';
import useSegment from '../../../../../utils/hooks/useSegment';
import { SEGMENT_ACTIONS } from '../../../../../utils/constants/SegmentActionConstants';
import { REDIRECT_URL } from '../../../../../utils/constants/RedirectConstants';
import { IMemberTopUp } from '../../../../../redux/slices/EnrolmentSlice/types';
import SuperTopUpPendingCard from '../../../../containers/SuperTopUpCard';
import { fetchUserPolicyList } from '../../../../../redux/slices/PolicyListSlice';
import {
    checkTopUpAvailable,
    createFamilyStructure,
    getEnrolledPolicyCompany
} from '../../../../../utils/PolicyServices';
const ConfirmationPage: React.FunctionComponent = () => {
    interface LocationState {
        makeApiCallEnrollUser: boolean;
        gmcPolicyStatus: string;
    }
    const trackClick = useSegment('click');
    const trackPage = useSegment('page');
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const [confirmationLoader, setConfirmationLoader] = useState(false);
    const currentlySelectedPolicy = useSelector((state: ReduxState) => state.enrolment.currentlySelectedPolicy);
    const currentlySelectedTopUp = useSelector((state: ReduxState) => state.enrolment.selectedMemberTopUp);
    const policyList = useSelector((state: ReduxState) => state.policyList.policyList.data) || [];
    const filteredPolicyList = policyList.filter((policy) => !policy.isTopUpPolicy);
    const currentPageAction = SEGMENT_ACTIONS.PAGE[getSegmentPageName(location.pathname) as any];
    const [enroledPolicy, setEnroledPolicy] = React.useState<IPolicy | null>(null);
    const [enroledTopUp, setEnroledTopUp] = React.useState<IMemberTopUp[] | null>(null);
    const checkMakeApiCallEnrollUser = location.state as LocationState;
    const userData = useSelector((state: ReduxState) => state.user.userData);
    const checkSuperTopUpAvailable = checkTopUpAvailable();
    const superTopUpAvailable = checkIfTopUpAvailable(checkSuperTopUpAvailable, currentlySelectedPolicy);
    const getEnrolledGMCPolicies = getEnrolledPolicyCompany(policyList);
    React.useEffect(() => {
        if (checkMakeApiCallEnrollUser.makeApiCallEnrollUser) {
            setConfirmationLoader(true);
            if (!getEnrolledGMCPolicies.length) {
                dispatch(
                    updateSelectedTopUp({
                        policyId: currentlySelectedPolicy?.policyId || '',
                        topUpDetails: currentlySelectedTopUp,
                        userId: userData.data?.userId
                    })
                );
            }
            setTimeout(() => {
                const userId = userData.data?.userId;
                dispatch(fetchUserPolicyList({ userId }));
                setConfirmationLoader(false);
            }, 4000);
        }
    }, [checkMakeApiCallEnrollUser]);

    React.useEffect(() => {
        setEnroledPolicy(currentlySelectedPolicy);
        setEnroledTopUp(currentlySelectedTopUp);
    }, []);
    React.useEffect(() => {
        if (!currentlySelectedPolicy && !enroledPolicy) {
            history.push(INTERNAL_ROUTES.dashboard, { previousUrl: location.pathname });
        }
    }, [currentlySelectedPolicy, enroledPolicy]);

    const getPendingPoliciesCount = () =>
        policyList
            ? policyList.filter((policy: IPolicy) =>
                getPolicyPendingValidity(policy.enrolmentStatus, policy.enrolmentDueDate)
            ).length
            : 0;
    const getSuperTopUpPolicy = () =>
        policyList ? policyList.filter((policy: IPolicy) => policy.policyType === 'SUPER_TOP_UP') : [];
    const getPolicyPendingValidity = (enrolmentStatus: string | null, date: number) => {
        let overdue = true;
        const dateToFormat = new Date(date * 1000);
        const today = new Date();
        const diffTime = +dateToFormat - +today;
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (diffDays <= 0) {
            overdue = false;
        }
        return (enrolmentStatus ? enrolmentStatus.toLowerCase() !== 'enroled' : true) && overdue;
    };
    const redirectToEnrolment = (policy: IPolicy) => {
        dispatch(setCurrentlySelectedPolicy({ currentlySelectedPolicy: policy }));
        history.push(INTERNAL_ROUTES.enrolment, { previousUrl: location.pathname });
    };
    const redirectToStore = (url: string) => {
        const segmentAction = SEGMENT_ACTIONS.CLICK.CONFIRMATION_REDIRECT_TO_STORE;
        const store = url === REDIRECT_URL.APP_STORE ? 'App' : 'Play';
        trackClick({
            name: segmentAction.name,
            properties: {
                describe: (segmentAction.properties?.describe as (store: string) => string)(store) || '',
                store: (segmentAction.properties?.store as (store: string) => string)(store) || ''
            }
        });
        redirect(url);
    };
    const handlePendingPolicyClick = (policy: IPolicy) => {
        const segmentAction = SEGMENT_ACTIONS.CLICK.CONFIRMATION_ENROL_PENDING_POLICIES;
        trackClick({
            name: segmentAction.name,
            properties: {
                describe: (segmentAction.properties?.describe as (policyId: string) => string)(policy.policyId) || '',
                policy_id: (segmentAction.properties?.policy_id as (policyId: string) => string)(policy.policyId) || '',
                policy_type:
                    (segmentAction.properties?.policy_type as (policyType: string) => string)(policy.policyType) || '',
                policy_status:
                    (segmentAction.properties?.policy_status as (policyStatus: string) => string)(
                        policy.enrolmentStatus || 'Pending'
                    ) || '',
                page_title: segmentAction.properties?.page_title,
                login_status: segmentAction.properties?.login_status,
                enrolling_in:
                    (segmentAction.properties?.enrolling_in as (enrolledIN: string) => string)(policy.policyId) || ''
            }
        });
        if (!(getDaysLeft(policy.enrolmentDueDate) === 'OVERDUE')) {
            redirectToEnrolment(policy);
        }
    };

    const getTopupAmount = () => {
        if (enroledTopUp) {
            return enroledTopUp.map((slab) => slab.sumInsured).reduce((prev, curr) => prev + curr, 0);
        }
        return 0;
    };
    const getPremium = (annualPremium: number) => {
        if (annualPremium) {
            if (enroledTopUp) {
                return (
                    annualPremium +
                    enroledTopUp
                        .map((slab) => getPremiumAmount(slab.annualPremium, slab.employeeContribution))
                        .reduce((prev, curr) => prev + curr, 0)
                );
            } else {
                return annualPremium;
            }
        }
        if (enroledTopUp) {
            return enroledTopUp
                .map((slab) => getPremiumAmount(slab.annualPremium, slab.employeeContribution))
                .reduce((prev, curr) => prev + curr, 0);
        }
        return 0;
    };

    const getEnrolledPolicy = () => {
        if (checkMakeApiCallEnrollUser.gmcPolicyStatus === 'ENROLED') {
            if (getSuperTopUpPolicy().length > 0 || superTopUpAvailable) {
                return 'topup';
            }
            return 'GMC';
        } else {
            if (getSuperTopUpPolicy().length > 0 || superTopUpAvailable) {
                return 'both';
            }
            return 'GMC';
        }
    };
    React.useEffect(() => {
        trackPage({
            name: currentPageAction.name,
            properties: {
                page_url: (currentPageAction.properties?.page_url as (path: string) => string)(location.pathname) || '',
                page_id: (currentPageAction.properties?.page_id as (path: string) => string)(location.pathname) || '',
                page_title: currentPageAction.properties?.page_title,
                login_status: currentPageAction.properties?.login_status,
                referrer_page_title: currentPageAction.properties?.referrer_page_title,
                enrolling_in: (currentPageAction.properties?.enrolling_in as (enrolledIN: string) => string)(
                    getEnrolledPolicy() ?? ''
                )
            }
        });
    }, [currentPageAction]);
    return confirmationLoader ? (
        <Loader />
    ) : (
        <StyledContainer>
            <StyledWrapper>
                <ConfirmationCard>
                    <ConfirmationContent>
                        <StyledImage src={ConfirmationImage} alt={'confirmed!'} />
                        <ConfirmationTextContainer>
                            <ConfirmationTextTitle>Confirmation</ConfirmationTextTitle>
                            <StyledPara>
                                Congratulations, you’ve been enroled into the policy! Loop covers you beyond insurance.
                            </StyledPara>
                        </ConfirmationTextContainer>
                    </ConfirmationContent>
                    <PolicyDetailsCardHeader>SUMMARY</PolicyDetailsCardHeader>
                    {filteredPolicyList &&
                        filteredPolicyList.map((policy) =>
                            policy.enrolmentStatus?.toLowerCase() === 'enroled' ? (
                                <PolicyDetailsCard
                                    policyCategory={policy.policyType}
                                    policyImage={policy.policyImage}
                                    policyName={policy.policyName}
                                    coveredMembers={createFamilyStructure(policy, getEnrolledGMCPolicies)}
                                    premiumToPay={getPremium(policy.annualPremium)}
                                    sumInsured={policy.sumInsured}
                                    topUpAmount={getTopupAmount()}
                                    familyStructure={createFamilyStructure(policy, getEnrolledGMCPolicies)}
                                    key={policy.policyId}
                                    annualPremium={policy.annualPremium}
                                    shouldConsiderTopUpAsOPD={policy.shouldConsiderTopUpAsOPD}
                                    policyEndDate={
                                        policy?.policyEndDate
                                            ? insuranceCardDateFormat(policy?.policyEndDate?._seconds)
                                            : 'N/A'
                                    }
                                />
                            ) : null
                        )}
                </ConfirmationCard>
                {policyList && policyList.length && getPendingPoliciesCount() > 0 && (
                    <ConfirmationCard>
                        <StyledPendingText>
                            <div>Enrol in Pending Policies</div>
                            <StyledPendingLabel>{getPendingPoliciesCount()}</StyledPendingLabel>
                        </StyledPendingText>
                        <ConfirmationTextContainer>
                            Enrol in the policies before the due date to ensure policy coverage
                        </ConfirmationTextContainer>
                        <PendingInsuranceWrapper>
                            {policyList.map(
                                (policy: IPolicy, index: number) =>
                                    getPolicyPendingValidity(policy.enrolmentStatus, policy.enrolmentDueDate) && (
                                        <StyledCardWrapper
                                            onClick={() => handlePendingPolicyClick(policy)}
                                            key={`pending-policy-${index}`}
                                        >
                                            <PendingInsuranceCard
                                                policyImage={policy.policyImage}
                                                policyName={policy.policyType}
                                                policyType={policy.policyName}
                                                daysLeft={getDaysLeft(policy.enrolmentDueDate)}
                                            />
                                        </StyledCardWrapper>
                                    )
                            )}
                        </PendingInsuranceWrapper>
                    </ConfirmationCard>
                )}
                {getSuperTopUpPolicy().length === 0 && superTopUpAvailable ? (
                    <TopupCard>
                        <SuperTopUpPendingCard />
                    </TopupCard>
                ) : null}
            </StyledWrapper>

            <BeyondInsurance
                redirectToAppStore={() => redirectToStore(REDIRECT_URL.APP_STORE)}
                redirectToPlayStore={() => redirectToStore(REDIRECT_URL.PLAY_STORE)}
            />
        </StyledContainer>
    );
};

export default ConfirmationPage;
