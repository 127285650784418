import { IAllDependents, IDependent } from '../../../redux/slices/PolicyListSlice/types';


export const getAllowedDependents = (dependentList: IAllDependents,
    existingDependents: IDependent[]): IDependent[] | undefined => {
    return dependentList?.data?.filter((dep: IDependent) => {
        return !existingDependents?.find((obj: IDependent) => obj.userId === dep.userId);
    });
};


export const checkHasDependent = (allowedDependents: IDependent[]
    | undefined, selectedRelationship: string): IDependent | undefined => {
    return allowedDependents?.find(
        (dependent: IDependent) => (dependent.relationship as string) === selectedRelationship.toLowerCase()
    );
};

export const checkRelationship = (selectedRelationship: string, dependent: IDependent): boolean => {
    if ((selectedRelationship?.toLowerCase() === 'parent/parent-in-law' &&
        (dependent.relationship.toLowerCase() === 'parent' ||
         dependent.relationship.toLowerCase() === 'parent-in-law')) ||
        (dependent.relationship.toLowerCase() ===
        selectedRelationship?.toLowerCase())
    ) {
        return true;
    }
    return false;
};
