import React from 'react';
import { StyledContainer, StyledImage } from './styles';
import { SecureWhiteIcon, WarningIcon, WarningSuccessIcon } from '../../../assets/img';
import { IEmployeeSummaryNotice } from './types';

const EmployeeSummaryNotice: React.FunctionComponent<IEmployeeSummaryNotice> = ({
    showBG = true,
    children,
    errorNotice = false,
    Icon,
    fontColor = false
}) => {
    return (
        <StyledContainer $showBG={showBG} $errorBG={errorNotice} $color={fontColor}>
            <StyledImage src={Icon ? SecureWhiteIcon : errorNotice ? WarningIcon : WarningSuccessIcon} />
            {children}
        </StyledContainer>
    );
};

export default EmployeeSummaryNotice;
