import useFormInput from '../../utils/hooks/useFormInput';
import { IUseNameValidationFunctionType, IUseNameValidationReturnType } from '../containers/ManageMemberModal/types';

export const useNameValidation = (
    formLastName: string | undefined
): IUseNameValidationReturnType => {
    const firstName = useFormInput('');
    const lastName = useFormInput(formLastName ? formLastName : '');

    const validateName = (): IUseNameValidationFunctionType => ({
        firstNameValidation: firstName.finalValidationCheck(),
        lastNameValidation: lastName.finalValidationCheck()
    });

    return {
        name: {
            firstName,
            lastName,
            validateName
        }
    };
};
