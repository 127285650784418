import React, { Suspense } from 'react';
import { Switch, Redirect, useLocation, Route, useHistory } from 'react-router-dom';
import { SPAContainer } from '.';
import { checkSegmentTrackPageCall, getSegmentPageName } from '../../utils/common';
import { MAIN_ROUTES } from '../../utils/constants';
import { SEGMENT_ACTIONS } from '../../utils/constants/SegmentActionConstants';
import useSegment from '../../utils/hooks/useSegment';
import { AuthRoute } from '../../utils/RouteGuards';
import LoginContainer from './LoginContainer';
import { Loader, LoginPanel } from '../atoms';
import { TOKENS } from '../../utils/constants';
import { ReduxState } from '../../redux';
import { useSelector } from 'react-redux';
import { LocationState } from '../../topup-src/utils/types';

const MainRouter: React.FunctionComponent = () => {
    const location = useLocation<LocationState>();
    const history = useHistory();
    const trackPage = useSegment('page');
    const userId = useSelector((state: ReduxState) => state.user?.userData?.data?.userId);
    React.useEffect(() => {
        if (checkSegmentTrackPageCall(location.pathname)) {
            const currentPageAction = SEGMENT_ACTIONS.PAGE[getSegmentPageName(location.pathname) as any];
            const refPageAction = SEGMENT_ACTIONS.PAGE[getSegmentPageName(location?.state?.previousUrl) as any];
            trackPage({
                name: currentPageAction.name,
                properties: {
                    page_url:
                        (currentPageAction.properties?.page_url as (path: string) => string)(location.pathname) || '',
                    page_id:
                        (currentPageAction.properties?.page_id as (path: string) => string)(location.pathname) || '',
                    page_title: currentPageAction.properties?.page_title,
                    login_status: (currentPageAction.properties?.login_status as (loginStatus: boolean) => boolean)(
                        userId ? true : false
                    ),
                    referrer_page_title:
                        (currentPageAction.properties?.referrer_page_title as (refPage: string) => string)(
                            refPageAction?.name
                        ) || '',
                    referrer_page_url:
                        (currentPageAction.properties?.referrer_page_url as (refUrl: string) => string)(
                            location?.state?.previousUrl
                        ) || ''
                }
            });
        }
    }, [location.pathname]);
    return (
        <Suspense fallback={<Loader />}>
            <Switch>
                <Route path={MAIN_ROUTES.login}>
                    <LoginContainer
                        redirectTo={() => history.push(MAIN_ROUTES.app)}
                        PanelComponent={LoginPanel}
                        title={'Get started'}
                        subTitle={'Login to view your policy details'}
                        authTokenName={TOKENS.FIREBASE_AUTH_TOKEN}
                    />
                </Route>
                <AuthRoute path={MAIN_ROUTES.app} Component={SPAContainer} />
                <Redirect from={'/'} to={MAIN_ROUTES.app} />
            </Switch>
        </Suspense>
    );
};

export default MainRouter;
