import styled from 'styled-components';

export const StyledTableRow = styled.div(
    (p) => `
    display: table-row;
    max-width: 100%;
    border-bottom: 1px solid ${p.theme.colors.border};`
);

export const StyledTableCell = styled.div(
    (p) => `
    display: table-cell;
    font-size: ${p.theme.fontSizes.label};
    color: ${p.theme.colors.mutedBG};
    vertical-align: middle;
    border-radius: 8px;
    -moz-border-radius: 8px;
    position: relative;
    @media (max-width: 768px) {
        padding-left: 5px;
        display: none;
    }
    @media (max-height: 550px) and (orientation: landscape) {
        padding-left: 5px;
        display: none;
    }
`
);
export const StyledTableCellContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -15px;
    @media (max-width: 768px) {
        padding-left: 5px;
        display: none;
    }
    @media (max-height: 550px) and (orientation: landscape) {
        padding-left: 5px;
        display: none;
    }
`;
export const StyledTableCellName = styled.div(
    (p) => `
    display: table-cell;
    font-size: ${p.theme.fontSizes.label};
    color: ${p.theme.colors.mutedBG};
    border-radius: 8px;
    -moz-border-radius: 8px;
    position: relative;
    width: 30%;
    @media (max-width: 768px) {
        width: 100%;
        padding-left: 5px;
        display: none;
    }
    @media (max-height: 550px) and (orientation: landscape) {
        width: 100%;
        padding-left: 5px;
    }
`
);

export const DisplayOnlyMobile = styled.div`
    visibility: hidden;
    height: 0;
    width: 0;
    @media (max-width: 768px) {
        visibility: visible;
        height: auto;
        width: auto;
        margin-right: 3px;
    }
    @media (max-height: 550px) and (orientation: landscape) {
        visibility: visible;
        height: auto;
        width: auto;
        margin-right: 3px;
    }
`;
export const WrapperDiv = styled.div`
    display: flex;
`;

export const StyledLogo = styled.img`
    width: 50px;
`;

export const StyledPText = styled.p`
    display: flex;
    margin: -5px 0px 2px 0px;
`;
