import styled from 'styled-components';

export const StyledContainer = styled.div(
    (p) => `
    max-width: 45%;
    min-width: 434px;
    width: 434px;
    height: 150px;
    padding: 20px 15px;
    border-radius: 12px;
    margin: 15px;
    position: relative;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: ${p.theme.colors.secondary};
    border: 2px solid ${p.theme.colors.border};
    @media (max-width: 768px) {
        max-width: 100%;
        min-width: 0px;
        height: 210px;
        margin: 0px 15px 15px 15px;
    }
`
);
export const StyledText = styled.div(
    (p) => `
    font-size: ${p.theme.fontSizes.body};
    text-transform: uppercase;
    color: ${p.theme.colors.primary};
    margin-left: 5px;
    font-weight: 600;
    letter-spacing: 0.05em;
`
);
export const StyledImage = styled.img`
    height: 18px;
    width: 18px;
`;
export const StyledButton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;
