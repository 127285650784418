import styled from 'styled-components';

export const Container = styled.div<{ $margin?: string }>`
    display: flex;
    /* flex-direction: column; */
    margin: ${(p) => p.$margin || '0px'};
    flex: 2;
`;

export const ButtonContainer = styled.div`
    max-width: 230px;
    display: flex;
    flex: 1;
`;

export const StyledCheckboxSection = styled.div`
    display: flex;
    margin: 20px 0px 0px 0px;
    align-items: flex-end;
`;

export const StyledImg = styled.img`
    width: 28px;
    margin-bottom: -3px;
`;

export const StyledInput = styled.input.attrs({ type: 'checkbox' })`
    width: 35px;
    height: 20px;
    border-radius: 4px;
    cursor: pointer;
    accent-color: #02a2768c;
    &::checked {
        background-color: #025f4c;
    }
`;

export const StyledContainer = styled.div`
    /* padding: 10px 30px; */
    display: flex;
    flex-direction: column;
`;
