import styled from 'styled-components';

export const StyledContainer = styled.div`
    box-shadow: 0px -2px 14px rgba(168, 196, 206, 0.1);
    border-radius: 16px;
    background-color: ${(p) => p.theme.colors.white};
    margin: 20px;
    max-width: 1100px;
`;
export const StyledTablePaddingContainer = styled.div`
    overflow: hidden;
    width: 100%;
    margin-top: 30px;
    padding: 25px;
    padding-top: 0px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    @media (max-width: 550px) {
        padding: 15px;
        margin-top: 0px;
    }
`;
export const StyledTableContainer = styled.div`
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    /* background-color: ${(p) => p.theme.colors.secondaryBG}; */
    position: relative;
    cursor: pointer;
`;
export const StyledTable = styled.div`
    display: table;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: fixed;
    border-radius: 8px;
`;
export const StyledTableCell = styled.div<{ $showBottomPadding: boolean }>(
    (p) => `
    display: table-cell;
    text-transform: capitalize;
    vertical-align:middle;
    font-size: ${p.theme.fontSizes.body};
    color: ${p.theme.colors.black};
    font-weight: 500;
    padding: 15px 0px;;
    ${p.$showBottomPadding ? '' : 'padding-bottom: 0px;'}
    border-radius: 8px;
    -moz-border-radius: 8px;
    @media (max-width: 768px){
        display: none;
    }
    @media (max-height: 550px) and (orientation:landscape) {
        display: none;
    }
`
);

export const StyledImage = styled.img`
    cursor: pointer;
`;
export const ImageContainer = styled.div`
    position: absolute;
    cursor: pointer;
    top: 15px;
    right: 20px;
    z-index: 1000000;
    @media (max-width: 768px) {
        top: 15px;
        right: 8px;
    }
`;

export const StyledPremiumTablePaddingContainer = styled.div`
    overflow: hidden;
    width: 100%;
    margin-top: 0px;
    padding: 25px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    @media (max-width: 550px) {
        padding: 15px;
        margin-top: 0px;
    }
`;
