import styled from 'styled-components';

export const StepperBox = styled.div<{ $isActive: boolean; $isDone: boolean }>(
    (p) => `
    max-width: 100%;
    margin-left: 17px;
    padding: 3px 23px;
    line-height: 20px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    font-size: ${p.theme.fontSizes.body};
    ${
    p.$isActive ?
        `
        border-left: 1px solid ${p.theme.colors.primary};
        ` :
        `
            border-left: 1px solid ${
    p.$isDone ? p.theme.colors.primary : p.theme.colors.muted
};
            height: 30px;
            max-height: 30px;
        `
}
    @media (max-width: 768px) {
        ${p.$isActive ? 'padding-bottom: 25px;' : 'display: none;'}
    }
`
);
export const DotContainer = styled.div<{
    $isActive: boolean;
    $isDone: boolean;
}>(
    (p) => `
    padding: 3px;
    max-height: 24px;
    max-width: 24px;
    border-radius: 50%;
    background-color: ${
    p.$isActive || p.$isDone ?
        p.theme.colors.primaryTransparent :
        p.theme.colors.mutedTransparent
};
    margin: 6px;
        @media (max-width: 768px) {
        ${p.$isActive ? '' : 'display: none;'}
    }
`
);
export const StepperDot = styled.div<{ $isActive: boolean; $isDone: boolean }>(
    (p) => `
    background-color: ${
    p.$isActive || p.$isDone ? p.theme.colors.primary : p.theme.colors.muted
};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 18px;
    width: 18px;
    min-height: 18px;
    min-width: 18px;
    max-height: 18px;
    max-width: 18px;
    @media (max-width: 768px) {
        ${p.$isActive ? '' : 'display: none;'}
    }
`
);
export const StyledStepperCheck = styled.img`
    max-height: 8px;
`;
export const StyledImage = styled.img`
    height: 150px;
    width: 210px;
    @media (max-width: 768px) {
        display: none;
    }
`;
export const StyledDotRow = styled.div<{ $isActive: boolean }>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    width: 100%;
    visibility: visible;
    @media (max-width: 768px) {
        ${(p) =>
        p.$isActive ? '' : 'visibility: hidden; height: 0; width: 0;'}
    }
`;
export const StyledText = styled.span<{ $isActive: boolean; $isDone: boolean }>(
    (p) => `
    margin-left: 5px;
    font-size: 16px;
    color: ${
    p.$isActive || p.$isDone ? p.theme.colors.black : p.theme.colors.muted
};
    font-weight: bold;
`
);
export const StepContainer = styled.div<{ $isActive: boolean }>`
    visibility: hidden;
    height: 0;
    @media (max-width: 768px) {
        visibility: visible;
        height: auto;
        max-width: 100%;
        margin-left: 17px;
        padding: 0px 23px;
        line-height: 20px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        border-left: 1px solid ${(p) => p.theme.colors.primary};
        font-size: ${(p) => p.theme.fontSizes.label};
        color: ${(p) => p.theme.colors.mutedBG};
        font-weight: 600;
        margin-top: 25px;
    }
    ${(p) => (p.$isActive ? '' : 'display: none;')}
`;
export const StepperContent = styled.div`
    margin-bottom: 25px;
`;
