import React from 'react';
import { Modal } from '../../atoms';
import Dialog from '../../atoms/Dialog';
import { ModalAlertIcon } from '../../../assets/img';

interface INomineeValidationModalProps {
    isVisible: boolean;
    title: string
    description: string

    setIsVisible(isVisible: boolean): void;
}

const NomineeValidationModal: React.FunctionComponent<INomineeValidationModalProps> = ({
    isVisible,
    setIsVisible,
    title,
    description

}) => {
    return (
        <Modal isVisible={isVisible} setIsVisible={setIsVisible}>
            <Dialog icon={ModalAlertIcon} title={title} description={description} isLoading={false}
                primaryButtonText="Got it"
                onPrimaryButtonClicked={() => setIsVisible(false)} isSecondaryButton={false}/>
        </Modal>
    );
};

export default NomineeValidationModal;
