import React from 'react';
import { StyledCheck, StyledActiveCheck, StyledContainer, StyledCheckText } from './styles';
import { IRadio } from './types';

const RadioButton: React.FunctionComponent<IRadio> = ({ name, id, selectedId, onClick, disabled = false }) => {
    return (
        <StyledContainer onClick={() => (disabled ? '' : onClick(id))}>
            <StyledCheck $isActive={id === selectedId} $isDisabled={disabled}>
                <StyledActiveCheck $isActive={id === selectedId} $isDisabled={disabled} />
            </StyledCheck>
            <StyledCheckText $isActive={id === selectedId} $isDisabled={disabled}>
                {name}
            </StyledCheckText>
        </StyledContainer>
    );
};

export default RadioButton;
