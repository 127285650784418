import React from 'react';
import {
    StyledContainer,
    StyledSectionWrapper,
    StyledSectionLeft,
    StyledSectionHeading,
    StyledSectionSpan,
    StyledButtonContainer,
    StyledContainerSection,
    StyledContainerLeftSection,
    StyledContainerRightSection,
    StyledContainerUL,
    StyledContainerLI,
    StyledPolicySection,
    StyledPolicyInfoSection,
    StyledSectionRightSpan,
    StyledButton,
    StyledHeadingContainer,
    StyledHeadingImg,
    StyledHeaderSection
} from './styles';
import { DefaultInsuranceIcon, SecureIcon } from '../../../assets/img';
import { INTERNAL_ROUTES } from '../../../utils/constants';
import { useHistory } from 'react-router-dom';

const SuperTopUpPendingCard: React.FunctionComponent = () => {
    const history = useHistory();
    return (
        <StyledContainer>
            <StyledHeadingContainer>
                <StyledHeadingImg src={SecureIcon} />
                <StyledHeaderSection>
                    <StyledSectionHeading>Boost your base policy with a top-up</StyledSectionHeading>
                    <StyledSectionRightSpan>
                        Policies to enhance coverage for your family and parents
                    </StyledSectionRightSpan>
                </StyledHeaderSection>
            </StyledHeadingContainer>
            <StyledSectionWrapper>
                <StyledSectionLeft>
                    <StyledSectionHeading>Family Top-Ups</StyledSectionHeading>
                    <StyledContainerSection>
                        <StyledContainerLeftSection>
                            <StyledContainerUL>
                                <StyledContainerLI>PEDs coverage</StyledContainerLI>
                                <StyledContainerLI>Easy claims at network hospitals</StyledContainerLI>
                                <StyledContainerLI>Claim assist</StyledContainerLI>
                            </StyledContainerUL>
                        </StyledContainerLeftSection>
                        <StyledContainerRightSection>
                            <StyledSectionRightSpan>Insurance partner:</StyledSectionRightSpan>
                            <StyledPolicySection>
                                <img src={DefaultInsuranceIcon} />
                                <StyledPolicyInfoSection>
                                    ICICI Lombard <br />
                                    General Insurance
                                </StyledPolicyInfoSection>
                            </StyledPolicySection>
                        </StyledContainerRightSection>
                    </StyledContainerSection>
                    <StyledSectionSpan>
                        Sum Insured options ranging from 4L to 20L Starting at ₹ 7200/year | Members eligible for cover
                        6
                    </StyledSectionSpan>
                </StyledSectionLeft>
                <StyledButtonContainer>
                    <StyledButton onClick={() => history.push(INTERNAL_ROUTES.dashboard)}>Explore Plans</StyledButton>
                </StyledButtonContainer>
            </StyledSectionWrapper>
        </StyledContainer>
    );
};

export default SuperTopUpPendingCard;
