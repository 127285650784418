/* eslint-disable */
import {
    IDependent,
    IPolicy,
    IPolicyDetail,
    ITopUpPolicyDetail,
    IPremium,
    IPolicyBenefit,
    IPaymentOrderData,
    IPaymentOrderStatus,
    IRatings,
    IEnrollUserToPolicy
} from '../redux/slices/PolicyListSlice/types';

import { get, post, del, put } from './provider';
export const LoopEndpoints = {
    DEFAULT_TIMEOUT: 30,
    fetchPolicyWiseFamilyDetails: (userId: string): string =>
        `/userPolicies/fetchBaseGmcPolicyWithSuperTopup/${userId}`,
    fetchParentalPoliciesDetails: (userId: string, parentalPolicyId: string): string =>
        `/userPolicies/fetchParentalPolicySuperTopUp/${userId}/${parentalPolicyId}`,
    fetchDependents: (userId: string): string => `/user?parentId=${userId}`,
    fetchUserPolicyByType: (userId: string): string => `/userPolicies/fetchUserPolicyByType/${userId}`,
    postTopUpLanding: () => '/topup/callback',
    topupFeedback: () => '/topup/sendFeedback',
    getTopUpInfo: (policyId: string | null) => `/policyPlan${policyId ? '/' + policyId : ''}`,
    fetchPolicyDetailsByID: (policyId: string): string => `/policyPlan/${policyId}`,
    generateEcards: (policyId: string): string => `/bulk/generateEcards//${policyId}`,
    calculateAnnualPremium: () => '/topup/calculateAnnualPremium',
    enrolUserToPolicy: (userId: string, policyId: string): string =>
        `/userPolicies/addUserPolicyForFamily/${userId}/${policyId}`,
    createOrder: () => '/razorpay/payment/order',
    verifyOrder: () => '/razorpay/payment/verify',
    saveFeedback: () => '/rating',
    fetchFAQs: () => '/faqs/iciciTopup',
    fetchPolicyBenefits: (policyId: string, slabId: string): string =>
        `/policyPlan/policyBenefits/${policyId}/${slabId}`
};

export const postTopUpLanding = async (req: Record<string, unknown>) => {
    try {
        const result: any = await post(LoopEndpoints.postTopUpLanding(), req);
        return result;
    } catch (error) {
        throw error;
    }
};

export const fetchFaqs = async () => {
    try {
        const result: any = await get(LoopEndpoints.fetchFAQs());
        return result.data?.faqs?.data;
    } catch (error) {
        throw error;
    }
};

export const getTopUpData = async (policyId: string) => {
    try {
        const result: any = await get(LoopEndpoints.getTopUpInfo(policyId));
        return result;
    } catch (error) {
        throw error;
    }
};
export const saveTopUpFeedback = async (req: Record<string, unknown>) => {
    await post(LoopEndpoints.topupFeedback(), req);
};
export const fetchPolicyBenefits = async (policyId: string, slabId: string) => {
    try {
        const result = await get(LoopEndpoints.fetchPolicyBenefits(policyId, slabId));
        return {
            coverageData: (result as any).data?.coverageData,
            nonCoverageData: (result as any).data?.nonCoverageData
        } as IPolicyBenefit;
    } catch (e) {
        throw e;
    }
};

export const fetchPolicyWiseFamilyDetails = async (userId: string) => {
    try {
        const result: any = await get(LoopEndpoints.fetchPolicyWiseFamilyDetails(userId));
        let baseGmcPolicyData = result.data?.baseGmcpolicyFamilyDetail;
        let topUpPolicyData = result.data?.superTopupPolicy;
        return {
            baseGmcPolicyData: baseGmcPolicyData ? formatPolicyData(baseGmcPolicyData) : {},
            topUpPolicyData: topUpPolicyData ? formatPolicyData(topUpPolicyData) : {}
        };
    } catch (e) {
        throw e;
    }
};

export const fetchParentalPolicyDetails = async (userId: string, parentalPolicyId: string) => {
    try {
        const result: any = await get(LoopEndpoints.fetchParentalPoliciesDetails(userId, parentalPolicyId));
        let baseGmcPolicyData = result.data?.response?.baseGmcpolicyFamilyDetail;
        let topUpPolicyData = result.data?.response?.superTopupPolicy;
        return {
            baseGmcPolicyData: baseGmcPolicyData ? formatPolicyData(baseGmcPolicyData) : {},
            topUpPolicyData: topUpPolicyData ? formatPolicyData(topUpPolicyData) : {}
        };
    } catch (e) {
        throw e;
    }
};

const formatPolicyData = (policyData: any) => {
    if (Object.keys(policyData).length > 0) {
        const dependents: IDependent[] = policyData.family.map((dep: any) => {
            return {
                doB: dep.dob._seconds,
                gender: dep.gender,
                // some entries will have Child 1 and Child 2, thats the way ops use to send the data. Need to cleanse it.
                relationship:
                    dep?.relationship?.toLowerCase() === 'child 1' || dep?.relationship?.toLowerCase() === 'child 2'
                        ? 'Child'
                        : dep?.relationship?.toLowerCase(),
                userId: dep.userId,
                firstName: dep.firstName || '',
                lastName: dep.lastName || '',
                // we are not getting the dependents mobile number separately, so currently going to use userId to get the dependents mobile number
                mobile: dep.mobile
            } as IDependent;
        });
        return {
            enrolmentStatus: policyData.enrolmentStatus,
            enrolmentDueDate: policyData?.enrolmentDueDate?._seconds,
            familyStructure: policyData.familyDefinition,
            policyId: policyData.policyId,
            policyImage: policyData.policyImage,
            policyName: policyData.insurerName,
            policyType: policyData.policyType,
            sumInsured: policyData.sumInsured,
            topUpAmount: policyData.topUpAmount,
            dependents,
            availableTopUp: policyData.topUpAvailable,
            topUpAdded: policyData.topUpAdded,
            annualPremium: policyData.annualPremium,
            policyStartDate: policyData.policyStartDate?._seconds,
            policyEndDate: policyData.policyEndDate?._seconds,
            topUpAvailable: policyData.topUpAvailable,
            slabId: policyData.slabId,
            tpaName: policyData.tpaName,
            digitPremiumWithoutGST: policyData.digitPremiumWithoutGST
        } as IPolicy;
    } else {
        return {};
    }
};

export const fetchPolicyDetailsByID = async (policyId: string) => {
    try {
        const result = await get(LoopEndpoints.fetchPolicyDetailsByID(policyId));
        return {
            sumAssuredSlabs: (result as any).data.policyPlans?.sumAssuredSlabs,
            policyTermsAndConditions: (result as any).data.policyPlans?.policyTermsAndConditions
        } as IPolicyDetail;
    } catch (e) {
        throw e;
    }
};

export const fetchTopPolicyDetailsByID = async (policyId: string) => {
    try {
        const result = await get(LoopEndpoints.fetchPolicyDetailsByID(policyId));
        const sumAssuredSlabs = (result as any)?.data?.policyPlans?.sumAssuredSlabs;
        const processData: { deductible: number; slabId: any; sumInsured: number; familyDefinition: string }[] = [];
        sumAssuredSlabs.map((item: any) => {
            const data = {
                deductible: item.deductible,
                slabId: item.slabId,
                sumInsured: item.sumInsured,
                familyDefinition: item?.familyDefinition?.value ?? ''
            };
            processData.push(data);
        });
        return ({
            sumAssuredSlabs: sumAssuredSlabs || [],
            deductible: [],
            policyName: (result as any)?.data?.policyPlans?.tpaName || '',
            policyProvider: (result as any)?.data?.policyPlans?.insurerName || '',
            policyLogo: (result as any)?.data?.policyPlans?.insurerLogo || '',
            gstPercentage: (result as any)?.data?.policyPlans?.gst,
            discountPercentage: (result as any)?.data?.policyPlans?.discountPercentage || 0,
            slabs: processData,
            enrolmentThreshold: (result as any)?.data?.policyPlans?.enrolmentThreshold || 0
        } as unknown) as ITopUpPolicyDetail;
        return;
    } catch (e) {
        throw e;
    }
};

export const calculateAP = async (req: Record<string, unknown>) => {
    try {
        const result = await post(LoopEndpoints.calculateAnnualPremium(), req);
        return {
            premium: (result as any)?.data?.annualPremium || 0,
            annualPremiumGST: (result as any)?.data?.annualPremiumGST || 0,
            loopMarginWithoutGST: (result as any)?.data?.loopMarginWithoutGST || 0,
            digitPremiumWithoutGST: (result as any)?.data?.digitPremiumWithoutGST || 0,
            annualPremiumWithoutGST: (result as any)?.data?.annualPremiumWithoutGST || 0,
            slabId: (result as any)?.data?.slabId
        } as IPremium;
    } catch (e) {
        throw e;
    }
};

export const verifyPayment = async (req: Record<string, unknown>) => {
    try {
        const result = await post(LoopEndpoints.verifyOrder(), req);
        return {
            orderId: (result as any)?.data?.orderId,
            msg: (result as any)?.data?.msg || 'Failed',
            paymentId: (result as any)?.data?.paymentId
        } as IPaymentOrderStatus;
    } catch (e) {
        throw e;
    }
};

export const createOrder = async (req: Record<string, unknown>) => {
    try {
        const result: any = await post(LoopEndpoints.createOrder(), req);
        return {
            orderId: result.data.order.id,
            entity: result.data.order.entity,
            amount: result.data.order.amount,
            amountDue: result.data.order.amount_due,
            currency: result.data.order.currency,
            receipt: result.data.order.receipt,
            status: result.data.order.status,
            offerId: result.data.order.offer_id,
            createdAt: result.data.order.created_at
        } as IPaymentOrderData;
    } catch (e) {
        throw e;
    }
};

export const saveFeedback = async (req: Record<string, unknown>) => {
    try {
        const result: any = await post(LoopEndpoints.saveFeedback(), req);
        return {
            ratingId: result.data.ratingId,
            feedbackText: result.data.feedbackText,
            userId: result.data.patientId
        } as IRatings;
    } catch (e) {
        throw e;
    }
};

export const generateEcards = async (req: Record<string, unknown>, policyId: string) => {
    try {
        const result: any = await post(LoopEndpoints.generateEcards(policyId), req);
        return result;
    } catch (e) {
        throw e;
    }
};

export const enrolUserToPolicy = async (req: Record<string, unknown>, userId: string, policyId: string) => {
    try {
        const response: any = await post(LoopEndpoints.enrolUserToPolicy(userId, policyId), req);
        return {
            policyId: response?.data?.policy?.id,
            userId: response?.data?.policy?.userId
        } as IEnrollUserToPolicy;
    } catch (error) {
        throw error;
    }
};
