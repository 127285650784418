import React from 'react';
import {
    StyledInsuranceCategory,
    StyledInsuranceImage,
    StyledInsuranceName,
    StyledInsuranceNameContainer,
    StyledNameContainer
} from './styles';
import { DefaultInsuranceIcon } from '../../../assets/img';
import { IInsuranceIdentity } from './types';
import { TextView } from '../TextView';
import theme from '../../../theme';

const InsuranceIdentity: React.FunctionComponent<IInsuranceIdentity> = ({
    policyCategory,
    policyImage,
    policyName,
    textColor,
    policyType,
    uin
}) => {
    return (
        <StyledNameContainer>
            <StyledInsuranceImage src={policyImage || DefaultInsuranceIcon} alt={'Insurance Image'} />
            <StyledInsuranceNameContainer>
                {policyType?.length ? (
                    <TextView fontSize="16px" color={theme.colors.beyondGrey}>
                        {policyType}
                    </TextView>
                ) : null}
                <StyledInsuranceName $color={textColor}>{policyCategory}</StyledInsuranceName>
                <StyledInsuranceCategory $color={textColor}>
                    {policyName}
                    {uin && (
                        <>
                            {' '}
                            |
                            <TextView fontSize={'14px'} margin={'0px 0px 0px 5px'} color={theme.colors.beyondGrey}>
                                UIN: GODHLGP21487V032021
                            </TextView>
                        </>
                    )}
                </StyledInsuranceCategory>
            </StyledInsuranceNameContainer>
        </StyledNameContainer>
    );
};

export default InsuranceIdentity;
