import React from 'react';
import {
    StyledCarouselImage,
    StyledContainer,
    StyledLogo,
    StyledHeading,
    StyledPara,
    StyledParaContainer
} from './styles';
import { LoginCarousel1, LoginCarousel2, LoopLogo } from '../../../assets/img';
import { Carousel } from '../';

const LoginPanel: React.FunctionComponent = () => {
    const [currSlide, setCurrSlide] = React.useState(0);
    return (
        <StyledContainer>
            <StyledLogo src={LoopLogo} alt={'Loop Health Logo'} />
            <div>
                <Carousel setCurrentSlide={setCurrSlide}>
                    <div>
                        <StyledCarouselImage
                            src={LoginCarousel1}
                            alt={'img1'}
                        />
                    </div>
                    <div>
                        <StyledCarouselImage
                            src={LoginCarousel2}
                            alt={'img2'}
                        />
                    </div>
                </Carousel>
            </div>
            <StyledParaContainer>
                <StyledHeading>
                    {currSlide === 0 ?
                        `Enrol today to plan for the unexpected` :
                        ` Faster access to 
                            cashless hospitals `
                    }
                </StyledHeading>
                <StyledPara>
                    {currSlide === 0 ?
                        `As you have been approved for your policy, you must now
                    simply enrol to bring your coverage into effect` :
                        `Enrolling is required to get your insurance e-card
                        which makes claims & hospitalization easier in your 
                        time of need`
                    }
                </StyledPara>
            </StyledParaContainer>
        </StyledContainer>
    );
};

export default LoginPanel;
