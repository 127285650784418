import styled from 'styled-components';

export const StyledContainer = styled.div(
    (p) => `
    max-width: 365px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: ${p.theme.colors.mutedBG};
    font-size: ${p.theme.fontSizes.body};
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    @media (max-width: 768px) {
        max-width: 100%;
        padding: 20px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
    }
`
);
export const BeyondRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 20px 0px;
    word-wrap: break-word;
    width: 100%;
    @media (max-width: 768px) {
        justify-content: space-around;
    }
`;

export const StyledAppStoreImg = styled.img`
    margin-right: 48px;
    cursor: pointer;
    @media (max-width: 768px) {
        margin: 0;
    }
`;
export const StyledPlayStoreImg = styled.img`
    cursor: pointer;
`;
export const StyledBeyondImage = styled.img`
    height: 67px;
    width: 67px;
    margin-right: 15px;
`;
export const StyledWrapper = styled.div`
    margin-bottom: 40px;
`;
export const BeyondTitleText = styled.div(
    (p) => `
    color: ${p.theme.colors.black};
    font-weight: bold;
    font-size: ${p.theme.fontSizes.heading};
    margin-bottom: 10px;
`
);
export const StyledTextWrapper = styled.div`
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`;
export const BeyondSubtitle = styled.div`
    color: ${(p) => p.theme.colors.black};
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
`;
