import React from 'react';
import { StyledDivContainer, StyledTitleText, StyledNoticeWrapper, StyledTopUpContainer } from './styles';
import { TopUpCard } from '..';
import { EmployeeContributionNotice } from '../../atoms';
import { ITopUpMembers } from './types';
import { ITopUpOptionData, IMemberTopUp } from '../../../redux/slices/EnrolmentSlice/types';

const TopUpMembers: React.FunctionComponent<ITopUpMembers> = ({ options, setTopUp, shouldConsiderTopUpAsOPD }) => {
    const [selectedTopups, setSelectedTopups] = React.useState<IMemberTopUp[]>([]);

    const addTopUpPolicy = (
        slabId: string,
        policyId: string,
        premiumAmount: number,
        topupName: string,
        coveredMembers: string
    ) => {
        if (!selectedTopups?.filter((topup) => topup.topUpSlabId === slabId)[0]) {
            const topUpPolicy = options.filter((topUp) => topUp.policyId === policyId)[0];
            const selectedSlab: ITopUpOptionData = topUpPolicy.topUpPolicySlabs?.filter(
                (topUp) => topUp.topUpSlabId === slabId
            )[0];
            setSelectedTopups([
                ...selectedTopups,
                {
                    ...selectedSlab,
                    id: topUpPolicy.policyId,
                    employeePremiumAmount: premiumAmount,
                    topupName,
                    coveredMembers
                }
            ]);
        }
    };

    const deleteTopUpPolicy = (id: string) => {
        const filteredTopups = selectedTopups.filter((topup: any) => topup.id !== id);
        setSelectedTopups([...filteredTopups]);
    };

    React.useEffect(() => {
        setTopUp(selectedTopups);
    }, [selectedTopups]);

    return (
        <>
            <StyledDivContainer>
                <StyledTitleText>Select Additional Top-Up Coverage</StyledTitleText>
            </StyledDivContainer>
            <StyledTopUpContainer>
                {options.map((topUp, index) => (
                    <TopUpCard
                        key={`topUp-${index}`}
                        data={topUp}
                        addTopUpPolicy={addTopUpPolicy}
                        deleteTopUpPolicy={deleteTopUpPolicy}
                        shouldConsiderTopUpAsOPD={shouldConsiderTopUpAsOPD}
                    />
                ))}
            </StyledTopUpContainer>
            <StyledNoticeWrapper>
                <EmployeeContributionNotice />
            </StyledNoticeWrapper>
        </>
    );
};

export default TopUpMembers;
