import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { fetchNomineeConfig } from './thunks';
import { IStepperSlice } from './types';

export const stepperSlice = createSlice({
    name: 'stepper',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchNomineeConfig.pending as any, (state: IStepperSlice) => {
                state.nomineeConfig.error = null;
                state.nomineeConfig.loading = true;
            })
            .addCase(fetchNomineeConfig.fulfilled as any, (state: IStepperSlice, { payload }) => {
                state.nomineeConfig.loading = false;
                state.nomineeConfig.data = payload.nomineeConfig;
                state.nomineeConfig.loading = false;
            })
            .addCase(fetchNomineeConfig.rejected as any, (state: IStepperSlice, { error }) => {
                state.nomineeConfig.loading = false;
                state.nomineeConfig.error = error;
            });
    }
});
export {
    fetchNomineeConfig
};
export default stepperSlice.reducer;

