import React from 'react';
import { ImageView } from '../ImageView';
import { WidgetItem, StyledTextContainer } from './styles';
import { IImageType } from './types';

const TopupIdentityWidget: React.FunctionComponent<IImageType> = ({ src, backgroundColor, width, textComponent }) => {
    return (
        <WidgetItem>
            <ImageView
                src={src}
                backgroundColor={backgroundColor}
                width={width}
                height={width}
                containerMinWidth="56px"
                containerMinHeight="56px"
                radius="30px"
                marginRight="20px"
            />
            <StyledTextContainer>{textComponent}</StyledTextContainer>
        </WidgetItem>
    );
};

export default TopupIdentityWidget;
