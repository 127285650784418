import React from 'react';
import { useSelector } from 'react-redux';
import {
    StyledContainer,
    StyledContent,
    StyledDescription,
    StyledRow,
    StyledTitle,
    CancelButton,
    ConfirmButton,
    StyledErrorText
} from './styles';
import { ReduxState } from '../../../redux';
import { Loader, Modal } from '../../atoms';
import { IDeleteMemberModal } from './types';

const DeleteMemberModal: React.FunctionComponent<IDeleteMemberModal> = ({
    isVisible,
    setIsVisible,
    dependentId,
    confirmDelete,
    selectedRelationship
}) => {
    const loading = useSelector((state: ReduxState) => state.enrolment.crudDependents?.loading);
    const error = useSelector((state: ReduxState) => state.enrolment.crudDependents?.error);
    const handleSubmit = () => {
        if (!loading) {
            confirmDelete(dependentId, selectedRelationship);
        }
    };
    return (
        <Modal isVisible={isVisible} setIsVisible={setIsVisible}>
            <StyledContainer>
                <StyledContent>
                    <StyledTitle>Delete {selectedRelationship}</StyledTitle>
                    <StyledDescription>
                        Are you sure you want to delete this {selectedRelationship}? Please note that this member will
                        be removed from this policy. {'You\'ll'} be able to add another{' '}
                        {selectedRelationship} to this policy instead.
                    </StyledDescription>
                </StyledContent>
                {error && error.message ? <StyledErrorText>{error.message}</StyledErrorText> : ''}
                <StyledRow>
                    <CancelButton onClick={() => setIsVisible(false)}>Cancel</CancelButton>
                    <ConfirmButton onClick={handleSubmit}>
                        {loading ? <Loader inverted={true} /> : 'Delete Member'}
                    </ConfirmButton>
                </StyledRow>
            </StyledContainer>
        </Modal>
    );
};

export default DeleteMemberModal;
