export type IJSON = {
    [key: string]: any;
};
export const InsuranceTypeConstants: IJSON = {
    GMC: 'Group Medical Coverage Insurance',
    GPA: 'Group Personal Accident Insurance',
    COVID: 'Covid-19 Group Health Insurance',
    GTL: 'Group Term Life Insurance',
    PARENTAL: 'Parental Policy',
    SUPER_TOP_UP: 'Family Top-Up'
};
export enum PolicyTypeConstants {
    SUPER_TOP_UP = 'SUPER_TOP_UP',
    GMC = 'GMC',
    GPA = 'GPA',
    COVID = 'COVID',
    GTL = 'GTL',
    PARENTAL = 'PARENTAL'
}
