import styled from 'styled-components';

export const StyledTableRow = styled.div<{ $showBorder: boolean }>`
    display: table-row;
    width: 100%;
    min-height: 75px;
    ${(p) =>
        p.$showBorder ?
            `border-bottom: 1px solid ${p.theme.colors.border};` :
            ''}
`;
export const StyledTableNameCell = styled.div<{ $showBottomPadding: boolean }>(
    (p) => `
    display: table-cell;
    vertical-align:middle;
    font-size: ${p.theme.fontSizes.body};
    color: ${p.theme.colors.black};
    font-weight: 100;
    padding: 15px 0px;
    ${p.$showBottomPadding ? '' : 'padding-bottom: 0px;'}
    border-radius: 8px;
    -moz-border-radius: 8px;
    @media (max-width: 550px) {
        padding: 15px 0px;
    }
    @media (max-height: 550px) and (orientation:landscape) {
        padding: 15px 0px;
    }
`
);
export const StyledTableCell = styled.div<{ $showBottomPadding: boolean }>(
    (p) => `
    display: table-cell;
    text-transform: capitalize;
    vertical-align:middle;
    font-size: ${p.theme.fontSizes.body};
    color: ${p.theme.colors.black};
    font-weight: 500;
    padding: 15px 0px;;
    ${p.$showBottomPadding ? '' : 'padding-bottom: 0px;'}
    border-radius: 8px;
    -moz-border-radius: 8px;
    @media (max-width: 768px){
        display: none;
    }
    @media (max-height: 550px) and (orientation:landscape) {
        display: none;
    }
`
);

export const StyledNameCell = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    @media (max-width: 768px) {
        align-items: flex-start;
    }
    @media (max-height: 550px) and (orientation: landscape) {
        align-items: flex-start;
    }
`;

export const StyledMobileContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
`;
export const StyledNameText = styled.span`
    margin-left: 15px;
    font-weight: 400;
`;
export const StyledMobileRelationship = styled.div(
    (p) => `
    visibility: hidden;
    height: 0;
    width: 0;
    @media (max-width: 768px) {
        visibility: visible;
        color: ${p.theme.colors.mutedBG};
        font-weight: 500;
        font-size: ${p.theme.fontSizes.label};
        margin: 8px 15px;
        height: auto;
        width: auto;
    }
    @media (max-height: 550px) and (orientation: landscape) {
        visibility: visible;
        color: ${p.theme.colors.mutedBG};
        font-weight: 500;
        font-size: ${p.theme.fontSizes.label};
        margin: 8px 15px;
        height: auto;
        width: auto;
    }
`
);
export const StyledMobileRow = styled.div(
    (p) => `
    visibility: hidden;
    height: 0;
    width: 0;
    font-weight: 400;
    @media (max-width: 768px){
        visibility: visible;
        color: ${p.theme.colors.mutedBG};
        font-size: ${p.theme.fontSizes.label};
        margin-left: 15px;
        height: auto;
        width: auto;
    }
    @media (max-height: 550px) and (orientation: landscape) {
        visibility: visible;
        color: ${p.theme.colors.mutedBG};
        font-size: ${p.theme.fontSizes.label};
        margin-left: 15px;
        height: auto;
        width: auto;
    }
`
);
export const Capitalise = styled.span`
    text-transform: capitalize;
`;
