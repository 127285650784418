import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchNomineeConfigAPI } from '../../../adapters';

const STEPPER_ACTION_TYPES = {
    FETCH_IS_NOMINEE_FEATURE_ENABLED: 'stepperslice/fetch-nominee-feature-enabled'
};

export const fetchNomineeConfig = createAsyncThunk(
    STEPPER_ACTION_TYPES.FETCH_IS_NOMINEE_FEATURE_ENABLED,
    async ({ companyId, policyId }: { companyId: string, policyId: string }) => {
        try {
            const response = await fetchNomineeConfigAPI(companyId, policyId);
            return {
                nomineeConfig: response
            };
        } catch (e) {
            throw e;
        }
    }
);
