import styled from 'styled-components';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    padding: 40px 52px;
    background-color: ${(p) => p.theme.colors.white};
    box-shadow: 0px -2px 14px rgba(168, 196, 206, 0.1);
    border-radius: 16px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    @media (max-width: 550px) {
        align-items: flex-start;
        padding: 40px 20px;
        background-color: ${(p) => p.theme.colors.secondaryBG};
    }
`;
export const StyledTitle = styled.div(
    (p) => `
    font-size: ${p.theme.fontSizes.title};
    color: ${p.theme.colors.black};
    font-weight: bold;
    @media (max-width: 550px) {
        font-size: ${p.theme.fontSizes.body};
    }
`
);
export const StyledSubTitle = styled.div(
    (p) => `
    font-size: ${p.theme.fontSizes.body};
    color: ${p.theme.colors.mutedBG};
    @media (max-width: 550px) {
        margin-top: 10px;
        font-size: ${p.theme.fontSizes.label};
    }
`
);
export const StyledRow = styled.div`
    display: flex;
    overflow-x: auto;
    max-width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 50px 0px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    @media (max-width: 768px) {
        margin: 20px 0px;
    }
`;
export const StyledCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 20px 35px;
    min-width: 271px;
    max-width: 100%;
    @media (max-width: 768px) {
        margin: 20px 3px;
        min-width: 261px;
        min-height: 381px;
        border-radius: 8px;
        background-color: ${(p) => p.theme.colors.white};
        padding: 20px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
    }
`;
export const StyledImage = styled.img`
    height: 200px;
    width: 200px;
    min-height: 200px;
    min-width: 200px;
    max-height: 200px;
    max-width: 200px;
`;
export const StyledCardTitle = styled.div(
    (p) => `
    font-size: ${p.theme.fontSizes.heading};
    color: ${p.theme.colors.black};
    font-weight: bold;
`
);
export const StyledCardContent = styled.div(
    (p) => `
    margin-top: 10px;
    font-weight: 300;
    max-width: 275px;
    font-size: ${p.theme.fontSizes.body};
    color: ${p.theme.colors.mutedBG};
`
);
export const StyledButtonRow = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const StyledButtonContainer = styled.div`
    width: 260px;
`;
export const StyledCounter = styled.div(
    (p) => `
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: ${p.theme.colors.primaryBG};
    color: ${p.theme.colors.white};
    font-size: ${p.theme.fontSizes.body};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
`
);
