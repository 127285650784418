import React from 'react';
import styled from 'styled-components';
import { Route, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../redux';
import { MAIN_ROUTES } from '../constants/RouteConstants';
import { Loader } from '../../components/atoms';
import firebase from 'firebase';
import {
    authenticateUser,
    getUserDetails,
    logoutUser
} from '../../components/pages/LoginContainer/redux/slices/UserSlice';

interface IRedirectAuthRoute {
    Component: any;
    path: string;
    exact?: boolean;
}
export const InternalRouteGuard: React.FunctionComponent<IRedirectAuthRoute> = ({ Component, ...rest }) => {
    const render = (props: any) => {
        return <Component {...props} />;
    };
    return <Route {...rest} render={(props: any) => render(props)} />;
};

interface IAuthRoute {
    Component: any;
    path: string;
    exact?: boolean;
}
const StyledContainer = styled.div`
    height: 100vh;
    width: 100vw;
    max-height: 100vh;
    max-width: 100vw;
    overflow-y: auto;
    background-color: ${(p) => p.theme.colors.white};
`;

const StyledLoaderContainer = styled.div`
    position: absolute;
    top: 15%;
    left: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    background-color: ${(p) => p.theme.colors.white};
`;

export const AuthRoute: React.FunctionComponent<IAuthRoute> = ({ Component, ...rest }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const userObject = useSelector((state: ReduxState) => state.user.userData);
    React.useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (!user) {
                dispatch(logoutUser());
                history.push(MAIN_ROUTES.login);
            } else {
                if (!userObject.loading) {
                    if (!userObject.data) {
                        if (user.email) {
                            dispatch(getUserDetails({ key: 'email', value: user.email }));
                            dispatch(authenticateUser());
                        } else if (user.phoneNumber) {
                            dispatch(
                                getUserDetails({
                                    key: 'mobile',
                                    value: user.phoneNumber.slice(3, user.phoneNumber.length)
                                })
                            );
                            dispatch(authenticateUser());
                        } else {
                            dispatch(logoutUser());
                            history.push(MAIN_ROUTES.login);
                        }
                    }
                }
            }
        });
    }, [userObject]);

    return (
        <Route
            {...rest}
            render={(props) =>
                userObject.loading ? (
                    <StyledContainer>
                        <StyledLoaderContainer>
                            <Loader />
                        </StyledLoaderContainer>
                    </StyledContainer>
                ) : (
                    <Component {...props} />
                )
            }
        />
    );
};
