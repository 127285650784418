import { IApplicationStateSlice } from './types';

export const initialState: IApplicationStateSlice = {
    showWelcomeScreen: true,
    showAddModal: false,
    showEditModal: false,
    showDeleteModal: false,
    memberIdToDelete: '',
    selectedRelation: '',
    selectedDependent: '',
    currentStepperStep: 1,
    isSuperTopupSelected: false,
    unSelectTopupFeedback: ''
};
