import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { IUseNomineesData, NomineeData } from '../containers/ManageNomineesComponent/types';
import { fetchNomineesAPI } from '../../adapters';

export const useNomineesData = (
    userId: string,
    policyId: string,
    setIsNomineeLoading: Dispatch<SetStateAction<boolean>>
): IUseNomineesData => {
    const [nomineesData, setNomineesData] = useState<NomineeData[]>([]);

    const refreshNominees = async (): Promise<void> => {
        if (!userId) return;
        const nominees = await fetchNomineesAPI(userId, policyId);
        setNomineesData(nominees);
    };

    useEffect(() => {
        (async () => {
            await refreshNominees();
            setIsNomineeLoading(false);
        })();
    }, []);

    return {
        nomineesData,
        refreshNominees
    };
};
