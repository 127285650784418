import React from 'react';
import { IStepperStep } from './types';
import {
    StepContainer,
    StepperBox,
    StepperContent,
    StepperDot,
    StyledDotRow,
    StyledImage,
    StyledText,
    DotContainer,
    StyledStepperCheck
} from './styles';
import { StepperCheck } from '../../../assets/img';

const StepperStep: React.FunctionComponent<IStepperStep> = ({
    isActive,
    isDone,
    id,
    name,
    description,
    image,
    setStep,
    stepCountRatio // 1/4, 2/4, 3/4, 4/4, 2/4
}) => {
    const isLastStep = () => {
        const [current, total] = stepCountRatio.split('/');
        return Number(current) === Number(total) + 1;
    };
    return (
        <>
            <StepContainer $isActive={isActive}>STEP {stepCountRatio}</StepContainer>
            <StyledDotRow $isActive={isActive} onClick={() => setStep(id)}>
                <DotContainer $isDone={isDone} $isActive={isActive}>
                    <StepperDot $isDone={isDone} $isActive={isActive}>
                        {isDone && <StyledStepperCheck src={StepperCheck} />}
                    </StepperDot>
                </DotContainer>
                <StyledText $isDone={isDone} $isActive={isActive}>
                    {name}
                </StyledText>
            </StyledDotRow>
            {!isLastStep() && (
                <StepperBox $isDone={isDone} $isActive={isActive}>
                    {isActive && (
                        <StepperContent>
                            <StyledImage src={image} />
                            {description}
                        </StepperContent>
                    )}
                </StepperBox>
            )}
        </>
    );
};

export default StepperStep;
