/* eslint-disable max-len */
import React from 'react';
import {
    StyledTableCell,
    StyledTableRow,
    StyledMobileContainer,
    StyledMobileRow,
    StyledNameCell,
    StyledNameText,
    StyledTableNameCell,
    Capitalise,
    StyledIconPadding,
    StyledSpanText,
    StyledIconPaddingTOPUP
} from './styles';
import { NameIcon } from '..';
import { colorLibrary } from '../../../theme';
import { ITopUpTableRow } from './types';
import { defaultDateFormat } from '../../../utils/common';
import { tickIcon } from '../../../assets/img';
import { formatAmount } from '../../../utils/PolicyServices';

const TopUpTableRow: React.FunctionComponent<ITopUpTableRow> = ({
    index = 0,
    firstName,
    lastName,
    relationship,
    gender,
    doB,
    mobile,
    showBorder = false,
    topUpSummaryRow = false,
    gmcAnnualPremium,
    topUpAnnualPremium,
    isTopUpSelected,
    isGMCEnrolled
}) => {
    return !topUpSummaryRow ? (
        <StyledTableRow $showBorder={showBorder}>
            <StyledTableNameCell $showBottomPadding={showBorder}>
                <StyledNameCell>
                    <NameIcon
                        firstName={firstName ?? ''}
                        lastName={lastName ?? ''}
                        color={colorLibrary[index % colorLibrary.length]}
                    />
                    <StyledMobileContainer>
                        <StyledNameText>
                            <Capitalise>{firstName + ' ' + lastName}</Capitalise>
                            <br />
                            <Capitalise>{relationship ? relationship : ''}</Capitalise>
                            &nbsp;
                            <Capitalise>{gender ? gender : ''}</Capitalise>
                        </StyledNameText>
                        <StyledMobileRow>
                            {doB ? defaultDateFormat(doB) : 'N/A'} &#183;{' '}
                            <Capitalise>{gender ? gender : 'N/A'}</Capitalise> &#183; {mobile ? mobile : 'N/A'}
                        </StyledMobileRow>
                    </StyledMobileContainer>
                </StyledNameCell>
            </StyledTableNameCell>
            <StyledTableCell $showBottomPadding={showBorder}>{doB ? defaultDateFormat(doB) : 'N/A'}</StyledTableCell>
            <StyledTableCell $showBottomPadding={showBorder}>{mobile ? mobile : 'N/A'}</StyledTableCell>
            {!isGMCEnrolled && (
                <StyledTableCell $showBottomPadding={showBorder}>
                    <StyledIconPadding>
                        <img src={tickIcon} />
                    </StyledIconPadding>
                </StyledTableCell>
            )}

            {isTopUpSelected && (
                <StyledTableCell $showBottomPadding={showBorder}>
                    <StyledIconPadding>
                        <img src={tickIcon} />
                    </StyledIconPadding>
                </StyledTableCell>
            )}
        </StyledTableRow>
    ) : (
        <StyledTableRow $showBorder={false}>
            <StyledTableCell $showBottomPadding={showBorder}></StyledTableCell>
            <StyledTableCell $showBottomPadding={showBorder}></StyledTableCell>

            {!isGMCEnrolled && (
                <StyledTableCell $showBottomPadding={showBorder}>
                    <StyledIconPadding>
                        <b>&#8377;&nbsp;{gmcAnnualPremium}</b>
                    </StyledIconPadding>
                </StyledTableCell>
            )}

            {isTopUpSelected ? (
                <>
                    <StyledTableCell $showBottomPadding={showBorder}>
                        <StyledSpanText>Amount Payable</StyledSpanText>
                    </StyledTableCell>
                    <StyledTableCell $showBottomPadding={showBorder}>
                        <StyledIconPadding>
                            <StyledIconPaddingTOPUP>
                                <b>
                                    &#8377;&nbsp;
                                    {formatAmount(topUpAnnualPremium)}
                                </b>
                            </StyledIconPaddingTOPUP>
                        </StyledIconPadding>
                    </StyledTableCell>
                </>
            ) : null}
        </StyledTableRow>
    );
};

export default TopUpTableRow;
