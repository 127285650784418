import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles.css';
import { ICarousel } from './types';

const Carousel: React.FunctionComponent<ICarousel> = ({ children, setCurrentSlide }) => {
    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (current: number, next: number) => setCurrentSlide(next)
    };
    return <Slider {...settings}>{children}</Slider>;
};

export default Carousel;
