import React from 'react';
import {
    StyledTableCell,
    StyledTableRow,
    StyledMobileContainer,
    StyledMobileRow,
    StyledMobileRelationship,
    StyledNameCell,
    StyledNameText,
    StyledTableNameCell,
    Capitalise
} from './styles';
import { NameIcon } from '..';
import { colorLibrary } from '../../../theme';
import { ITableRow } from './types';
import { defaultDateFormat } from '../../../utils/common';

const TableRow: React.FunctionComponent<ITableRow> = ({
    index = 0,
    firstName,
    lastName,
    relationship,
    gender,
    doB,
    mobile,
    showBorder = false
}) => {
    return (
        <StyledTableRow $showBorder={showBorder}>
            <StyledTableNameCell $showBottomPadding={showBorder}>
                <StyledNameCell>
                    <NameIcon
                        firstName={firstName}
                        lastName={lastName}
                        color={colorLibrary[index % colorLibrary.length]}
                    />
                    <StyledMobileContainer>
                        <StyledNameText>
                            <Capitalise>{firstName + ' ' + lastName}</Capitalise>
                        </StyledNameText>
                        <StyledMobileRelationship>
                            <Capitalise>{relationship ? relationship : 'N/A'}</Capitalise>
                        </StyledMobileRelationship>
                        <StyledMobileRow>
                            {doB ? defaultDateFormat(doB) : 'N/A'} &#183;{' '}
                            <Capitalise>{gender ? gender : 'N/A'}</Capitalise> &#183; {mobile ? mobile : 'N/A'}
                        </StyledMobileRow>
                    </StyledMobileContainer>
                </StyledNameCell>
            </StyledTableNameCell>
            <StyledTableCell $showBottomPadding={showBorder}>{relationship ? relationship : 'N/A'}</StyledTableCell>
            <StyledTableCell $showBottomPadding={showBorder}>{gender ? gender : 'N/A'}</StyledTableCell>
            <StyledTableCell $showBottomPadding={showBorder}>{doB ? defaultDateFormat(doB) : 'N/A'}</StyledTableCell>
            <StyledTableCell $showBottomPadding={showBorder}>{mobile ? mobile : 'N/A'}</StyledTableCell>
        </StyledTableRow>
    );
};

export default TableRow;
