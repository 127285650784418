import styled from 'styled-components';

export const StyledInput = styled.input<{ isError: boolean }>(
    (p) => `
    max-width: 100%;
    width: 100%;
    background-color: #edeff3;
    box-sizing: border-box;
    -webkit-box-sizing:border-box;
    -moz-box-sizing: border-box;
    border: 1px solid ${p.isError ? p.theme.colors.error : 'transparent'};
    color: ${p.isError ? p.theme.colors.error : p.theme.colors.black};
    font-size: ${p.theme.fontSizes.body};
    border-radius: 8px;
    box-shadow: 0px 4px 10px ${p.theme.colors.activeBG};
    padding: 15px 15px;
    &::placeholder {
        color: ${p.isError ? p.theme.colors.error : p.theme.colors.muted};
    }
`
);

export const StyledSelect = styled.select<{
    $src: string;
    $color: boolean;
    $error: boolean;
}>`
    width: 100%;
    box-shadow: 0px 4px 10px rgba(149, 159, 190, 0.1);
    border-radius: 8px;
    padding: 15px;
    box-sizing: border-box;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: ${(p) => (p.$error ? p.theme.colors.error : p.$color ? p.theme.colors.muted : p.theme.colors.black)};
    border: 0px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #edeff3 url(${(p) => p.$src}) no-repeat scroll 95% center;
    ${(p) => (p.$error ? `border: 1px solid ${p.theme.colors.error}` : '')}
`;

export const StyledLabel = styled.div(
    (p) => `
    margin-bottom: 15px;
    color: ${p.theme.colors.muted};
    font-size: ${p.theme.fontSizes.label};
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.05em;
    display:flex;
`
);
export const StyledInputGroup = styled.div`
    /* margin-right: 15px; */
    margin-bottom: 40px;
    max-width: 50%;
    min-width: 300px;
    @media (max-width: 768px) {
        min-width: 100%;
        margin-right: 0px;
    }
`;
export const StyledSpan = styled.div(
    (p) => `
    color: ${p.theme.colors.error};
    margin-left:2px;
`
);
