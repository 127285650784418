import React from 'react';
import { ThemeProvider } from 'styled-components';
import theme from '.';
import { IWithThemeProvider } from './types';

const WithThemeProvider = ({ children }: IWithThemeProvider): JSX.Element => {
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default WithThemeProvider;
