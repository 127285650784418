/* eslint-disable max-len */
export const REDIRECT_URL = {
    LinkedIn: 'https://www.linkedin.com/company/loop-health/',
    Instagram: 'https://www.instagram.com/loophealthindia/',
    Twitter: 'https://twitter.com/loophealthHQ',
    Facebook: 'https://www.facebook.com/loophealthindia/',
    Youtube: 'https://www.youtube.com/channel/UCPUc_YWsi_oj5oYjt0yjAZg',
    APP_STORE: 'https://apps.apple.com/in/app/loop-health/id1495161232',
    PLAY_STORE: 'https://play.google.com/store/apps/details?id=com.invoqhealth.loophealth.patient',
    TERMS_CONDITIONS:
        'https://storage.googleapis.com/loophealthpatient.appspot.com/Insurance/Magma_SuperTopups_TnCs.pdf',
    TERMS_CONDITIONS_ICICI:
        'https://storage.googleapis.com/loophealthpatient.appspot.com/Insurance/ICICI%20Adhoc%20Document%20T%26C%201.pdf',
    NETWORK_HOSPITALS_ICICI: 'https://www.icicilombard.com/cashless-hospitals',
    NETWORK_HOSPITALS: 'https://www.eastwestassist.com/hospitalReport/website_network_list'
};
