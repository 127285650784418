import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { fetchUserPolicyList, fetchUserDependents, getCompanyDetails } from './thunks';
import { IPolicyListSlice } from './types';

const policyListSlice = createSlice({
    name: 'policyList',
    initialState,
    reducers: {
        resetState(state) {
            state.policyList=initialState.policyList;
            state.companyDetails=initialState.companyDetails;
            state.allDependents=initialState.allDependents;
        },
        updatePolicyList(state: IPolicyListSlice, { payload }: any) {
            const updatePolicyStatus = payload.updatedPolicy;
            const updatedUser = payload.updatedUser;
            const coverageAmount = payload.coverageAmount;
            // Update enrolment status to 'ENROLED'
            if (updatePolicyStatus) {
                const filteredPolicyList =
                    state.policyList.data?.filter((policy) => policy.policyId !== updatePolicyStatus.policyId) || [];
                state.policyList.data = [...filteredPolicyList, updatePolicyStatus];
            } else if (updatedUser) {
                // Update user information
                const list = state.policyList.data;
                const selectedPolicy = (list || [])?.filter((policy) => policy.policyId === updatedUser.policyId)[0];
                const filteredPolicies = (list || [])?.filter((policy) => policy.policyId !== updatedUser.policyId);
                const dependentList = selectedPolicy?.dependents.filter(
                    (dependent) => dependent.userId !== updatedUser.userId
                );
                const dependentIndex = selectedPolicy?.dependents.findIndex(
                    (dependent) => dependent.userId === updatedUser.userId
                );
                // For Add + Edit Dependent
                if (updatedUser.firstName && updatedUser.lastName) {
                    if (dependentIndex >= 0) {
                        dependentList.splice(dependentIndex, 0, updatedUser);
                    } else {
                        dependentList.push(updatedUser);
                    }
                }

                if (coverageAmount?.sumInsured) {
                    const updatedPolicy = {
                        ...selectedPolicy,
                        dependents: dependentList,
                        sumInsured: coverageAmount.sumInsured,
                        annualPremium: coverageAmount.annualPremium,
                        annualPremiumWithoutGST: coverageAmount.annualPremiumWithoutGST,
                        gstOfAnnualPremium: coverageAmount.gstOfAnnualPremium
                    };
                    filteredPolicies.push(updatedPolicy);
                } else {
                    const updatedPolicy = { ...selectedPolicy, dependents: dependentList };
                    filteredPolicies.push(updatedPolicy);
                }
                state.policyList.data = [...filteredPolicies];
            }
        }
    },
    extraReducers: {
        [fetchUserPolicyList.pending as any]: (state: IPolicyListSlice) => {
            state.policyList.loading = true;
            state.policyList.error = null;
            state.policyList.data = null;
        },
        [fetchUserPolicyList.rejected as any]: (state: IPolicyListSlice, { error }) => {
            state.policyList.error = error;
            state.policyList.data = null;
            state.policyList.loading = false;
        },
        [fetchUserPolicyList.fulfilled as any]: (state: IPolicyListSlice, { payload }) => {
            state.policyList.error = null;
            state.policyList.data = payload.policyList;
            state.policyList.loading = false;
        },
        [fetchUserDependents.pending as any]: (state: IPolicyListSlice) => {
            state.allDependents.loading = true;
            state.allDependents.error = null;
            state.allDependents.data = null;
        },
        [fetchUserDependents.rejected as any]: (state: IPolicyListSlice, { error }) => {
            state.allDependents.error = error;
            state.allDependents.data = null;
            state.allDependents.loading = false;
        },
        [fetchUserDependents.fulfilled as any]: (state: IPolicyListSlice, { payload }) => {
            state.allDependents.error = null;
            state.allDependents.data = payload.data;
            state.allDependents.loading = false;
        },

        [getCompanyDetails.pending as any]: (state: IPolicyListSlice) => {
            state.companyDetails.loading = true;
            state.companyDetails.error = null;
            state.companyDetails.data = null;
        },
        [getCompanyDetails.rejected as any]: (state: IPolicyListSlice, { error }) => {
            state.companyDetails.error = error;
            state.companyDetails.data = null;
            state.companyDetails.loading = false;
        },
        [getCompanyDetails.fulfilled as any]: (state: IPolicyListSlice, { payload }) => {
            state.companyDetails.error = null;
            state.companyDetails.data = payload.companyDetails;
            state.companyDetails.loading = false;
        },
        'common/cleanup': (state: IPolicyListSlice) => {
            state.policyList = initialState.policyList;
            state.companyDetails = initialState.companyDetails;
            state.allDependents = initialState.allDependents;
        }
    }
});
export { fetchUserPolicyList };
export default policyListSlice.reducer;
export const { updatePolicyList, resetState } = policyListSlice.actions;
