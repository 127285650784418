import React from 'react';
import {
    StyledContainer,
    StyledDetailsSection,
    StyledOrderSection,
    StyledHeadingText,
    StyledHeadingSection,
    StyledWrapper,
    StyledTopUpPolicyCardWrapper,
    StyledMemberWrapper,
    StyledMemberHeading,
    StyledMemberHeadingSpan,
    StyledNameContainer,
    StyledOrderHeadingWrapper,
    StyledOrderHeading,
    StyledOrderWrapper,
    StyledPremiumWrapper,
    StyledTotalAmountWrapper,
    StyledPremiumLabel,
    StyledDiscountSpan,
    StyledTotalAmountLabel,
    StyledTotalAmountSpan,
    ButtonsContainer,
    StyledSection,
    StyledInput,
    StyledCheckboxSection,
    StyledText
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../../../redux';
import InsuranceIdentity from '../../../../atoms/InsuranceIdentity';
import TopUpPolicyCard from '../../../../atoms/TopUpPolicyCard';
import MemberIdentity from '../../../../atoms/MemberIdentity';
import { Loader } from '../../../../atoms';
import BeyondInsurance from '../../../../atoms/BeyondInsurance';
import { REDIRECT_URL } from '../../../../../utils/constants/RedirectConstants';
import { redirect } from '../../../../../utils/common';
import { Header } from '../../../../containers';
import { InfoIcon, LogoIcon, LoopGreenLogo } from '../../../../../assets/img';
import useRazorpay from '../../../../../utils/Razorpay';
import {
    calculatePremium,
    createPaymentOrder,
    fetchOrderStatus
} from '../../../../../redux/slices/PolicyListSlice/thunks';
import PaymentFailedModal from '../../../../containers/PaymentFailedModal';
import FeedbackModal from '../../../../containers/FeedbackModal';
import Tooltip from '../../../../atoms/Tooltip';
import { TextView } from '../../../../atoms/TextView';
import theme from '../../../../../theme';
import MedicalModal from '../../../../atoms/MedicalModal';
import useSegment from '../../../../../../utils/hooks/useSegment';
import { SEGMENT_ACTIONS } from '../../../../../utils/constants/SegmentActionConstants';
import { SEGMENT_ACTIONS as EnrolmentSEGMENTACTIONS } from '../../../../../../utils/constants/SegmentActionConstants';
import { useHistory } from 'react-router';
import { INTERNAL_ROUTES } from '../../../../../utils/constants/RouteConstants';
import { getSegmentPageName, topUpFeatureEnabled } from '../../../../../../utils/common';
import TopupSummaryCard from '../../../../containers/TopupSummaryCard';
import { useLocation } from 'react-router-dom';
import { resetCalculatedPremium, resetOrderState } from '../../../../../redux/slices/PolicyListSlice';
import { Button } from '../../../../../../components/atoms';
import { redirectTo } from '../../../../../../utils/Redirect';
import { checkParentalPolicyTopUp } from '../../../../../../utils/PolicyServices';
import { enrolUserPolicyFamily } from '../../../../../../redux/slices/EnrolmentSlice/thunks';
import { ISummaryPage } from './types';

const SummaryPage: React.FunctionComponent<ISummaryPage> = ({ selectedBasePolicy }) => {
    interface LocationState {
        makeCalculatePremiumApiCall: boolean;
    }
    const dispatch = useDispatch();
    const location = useLocation();
    const trackClick = useSegment('click');
    const history = useHistory();
    const checkMakeCalculatePremiumApiCall = location.state as LocationState;
    const topupPolicyDetails = useSelector((state: ReduxState) => state.TopUp_policyData.topupPolicyDetails.data);
    const policyData: any = useSelector((state: ReduxState) => state.TopUp_policyData.policyList.data);
    const topUpDetails: any = useSelector((state: ReduxState) => state.TopUp_policyData.memberTopUp);
    const userDetails = useSelector((state: ReduxState) => state.TopUp_user?.userData?.data);
    const paymentDetails = useSelector((state: ReduxState) => state.TopUp_policyData.paymentOrder);
    const orderLoading = useSelector((state: ReduxState) => state.TopUp_policyData.paymentOrder.loading);
    const paymentStatus = useSelector((state: ReduxState) => state.TopUp_policyData.paymentOrderStatus);
    const baseGmcPolicyData = policyData?.baseGmcPolicyData;
    const [paymentFailedIsVisible, setPaymentFailedIsVisible] = React.useState<boolean>(false);
    const [feedbackIsVisible, setFeedbackIsVisible] = React.useState<boolean>(false);
    const [medicalIsVisible, setMedicalIsVisible] = React.useState<boolean>(false);
    const [showSuccessContainer, setShowSuccessContainer] = React.useState<boolean>(false);
    const [checkboxChecked, setShowcheckboxChecked] = React.useState<boolean>(false);
    const currentPageAction = EnrolmentSEGMENTACTIONS.PAGE[getSegmentPageName(location.pathname) as any];
    const userId = useSelector((state: ReduxState) => state.TopUp_user?.userData?.data?.userId);
    const policyId = useSelector((state: ReduxState) => state.TopUp_applicationState.topupPolicyId);
    const redirectToStore = (url: string) => {
        const segmentAction = SEGMENT_ACTIONS.CLICK.CONFIRMATION_REDIRECT_TO_STORE;
        const store = url === REDIRECT_URL.APP_STORE ? 'App' : 'Play';
        trackClick({
            name: segmentAction.name,
            properties: {
                describe: (segmentAction.properties?.describe as (store: string) => string)(store) || '',
                store: (segmentAction.properties?.store as (store: string) => string)(store) || ''
            }
        });
        redirect(url);
    };
    React.useEffect(() => {
        if (!topUpFeatureEnabled() && !topUpDetails.hasOwnProperty('annualPremium')) {
            history.push(INTERNAL_ROUTES.dashboard, { previousUrl: location.pathname });
        }
    }, [topUpDetails]);

    React.useEffect(() => {
        if (checkMakeCalculatePremiumApiCall) {
            const topUpPolicyData = policyData?.topUpPolicyData;
            if (topUpPolicyData && baseGmcPolicyData) {
                const userIds = topUpDetails.userIds;
                if (userIds?.length) {
                    dispatch(resetCalculatedPremium());
                    dispatch(
                        calculatePremium({
                            selfUserId: userDetails.userId,
                            sumInsured: topUpDetails.sumInsured,
                            deductible: topUpDetails.deductible,
                            userIds,
                            policyId: policyId,
                            isParentalPolicy: checkParentalPolicyTopUp(baseGmcPolicyData)
                        })
                    );
                }
            }
        }
    }, [checkMakeCalculatePremiumApiCall]);

    React.useEffect(() => {
        if (topUpDetails.hasOwnProperty('annualPremium')) {
            const dependentsArray = topUpDetails.userIds.filter((item: string) => item !== userId);
            if (paymentStatus.data) {
                if (paymentStatus.data.msg === 'success') {
                    // TODO: to update URL
                    // window.history.pushState(null, '', 'thank-you');
                    setPaymentFailedIsVisible(false);
                    setShowSuccessContainer(true);
                    // TODO: Api to be removed as not required
                    dispatch(
                        enrolUserPolicyFamily({
                            userId: userId,
                            policyId: policyId,
                            slabId: topUpDetails.slabId,
                            enrolmentStatus: 'ENROLED',
                            policyStatus: 'ENROLED',
                            dependentIds: dependentsArray,
                            parentalPolicyId: checkParentalPolicyTopUp(baseGmcPolicyData)
                                ? baseGmcPolicyData.policyId
                                : ''
                        })
                    );
                    if (topUpFeatureEnabled()) {
                        history.push(INTERNAL_ROUTES.confirm, {
                            makeApiCallEnrollUser: true,
                            gmcPolicyStatus: baseGmcPolicyData.enrolmentStatus
                        });
                    } else {
                        setTimeout(() => {
                            setFeedbackIsVisible(true);
                        }, 4000);
                    }
                } else {
                    setPaymentFailedIsVisible(true);
                }
            }
        }
    }, [paymentStatus]);

    const proceedToPay = () => {
        if (
            (topUpFeatureEnabled() && topUpDetails?.annualPremium == 0) ||
            !topUpDetails.hasOwnProperty('annualPremium')
        ) {
            history.push(INTERNAL_ROUTES.confirm, {
                makeApiCallEnrollUser: true,
                gmcPolicyStatus: baseGmcPolicyData.enrolmentStatus
            });
            return;
        }
        const segmentAction = EnrolmentSEGMENTACTIONS.CLICK.PROCEED_TO_BUY;
        trackClick({
            name: segmentAction.name,
            properties: {
                describe: segmentAction.properties?.describe || '',
                login_status: segmentAction.properties?.login_status || '',
                page_url: (currentPageAction.properties?.page_url as (path: string) => string)(location.pathname) || '',
                page_id: (currentPageAction.properties?.page_id as (path: string) => string)(location.pathname) || '',
                page_title: (segmentAction.properties?.page_title as (pageTile: string) => string)('checkout'),
                sum_insured:
                    (segmentAction.properties?.sum_insured as (sumInsured: number) => number)(
                        topUpDetails.sumInsured
                    ) || '',
                enrolling_in: (segmentAction.properties?.enrolling_in as (enrollingIn: string) => string)('Both')
            }
        });
        dispatch(
            createPaymentOrder({
                amount: topUpDetails?.annualPremium,
                premium: topUpDetails?.annualPremium,
                gst: topUpDetails?.annualPremiumGST,
                userId: userDetails.userId,
                sumInsured: topUpDetails.sumInsured,
                deductible: topUpDetails.deductible,
                coveredMembers: topUpDetails.userIds,
                digitPremium: topUpDetails?.digitPremiumWithoutGST,
                loopCharges: topUpDetails?.loopMarginWithoutGST,
                policyId: policyId,
                slabId: topUpDetails.slabId,
                parentalPolicyId: checkParentalPolicyTopUp(baseGmcPolicyData) ? baseGmcPolicyData.policyId : ''
            })
        );
    };

    React.useEffect(() => {
        if (!orderLoading && paymentDetails.data) {
            const segmentAction = SEGMENT_ACTIONS.CLICK.PROCEED_TO_BUY_SUMMARY;
            trackClick({
                name: segmentAction.name,
                properties: {
                    user_id: (segmentAction.properties?.user_id as (userId: string) => string)(userDetails.userId),
                    sum_insured: (segmentAction.properties?.sum_insured as (sumInsured: string) => string)(
                        topUpDetails.sumInsured
                    ),
                    selected_members: (segmentAction.properties?.selected_members as (
                        selectedMembers: string
                    ) => string)(topUpDetails.userIds),
                    deductible: (segmentAction.properties?.deductible as (deductible: string) => string)(
                        topUpDetails.deductible
                    ),
                    annual_premium: (segmentAction.properties?.selected_members as (annualPremium: string) => string)(
                        topUpDetails?.annualPremium
                    )
                }
            });
            handlePayment(paymentDetails.data);
        }
    }, [paymentDetails]);

    const Razorpay = useRazorpay();
    const handlePayment = (params: any) => {
        const options = {
            key: `${process.env.REACT_APP_RAZORPAY_KEY}`,
            amount: params.amount,
            currency: params.currency,
            name: 'Loop Health',
            description: 'Super TopUp',
            image: LogoIcon,
            order_id: params.orderId,
            retry: {
                enabled: false
            },
            handler: (response: any) => {
                dispatch(
                    fetchOrderStatus({
                        orderCreationId: params.orderId,
                        razorpayPaymentId: response.razorpay_payment_id,
                        razorpayOrderId: response.razorpay_order_id,
                        razorpaySignature: response.razorpay_signature
                    })
                );
            },
            prefill: {
                name: userDetails.firstName + ' ' + userDetails.lastName,
                email: userDetails.email,
                contact: userDetails.mobile
            },
            modal: {
                ondismiss: function() {
                    dispatch(resetOrderState());
                }
            },
            notes: {
                address: 'Loop Health'
            },
            theme: {
                color: '#025F4C'
            }
        };

        const rzp1 = new Razorpay(options);
        rzp1.on('payment.failed', () => {
            setPaymentFailedIsVisible(true);
        });

        rzp1.open();
    };
    return (
        <>
            <Header logo={LoopGreenLogo} bgColor={false} />
            <StyledContainer>
                {!topUpFeatureEnabled() ? (
                    <>
                        <StyledHeadingSection>
                            {showSuccessContainer ? (
                                <BeyondInsurance
                                    redirectToAppStore={() => redirectToStore(REDIRECT_URL.APP_STORE)}
                                    redirectToPlayStore={() => redirectToStore(REDIRECT_URL.PLAY_STORE)}
                                    source="summary"
                                />
                            ) : (
                                <StyledHeadingText>Checkout Summary</StyledHeadingText>
                            )}
                        </StyledHeadingSection>
                    </>
                ) : null}

                <StyledWrapper>
                    {!topUpFeatureEnabled() ? (
                        <StyledSection>
                            <StyledOrderHeading>TOP UP SUMMARY</StyledOrderHeading>
                            <StyledDetailsSection>
                                <InsuranceIdentity
                                    policyCategory={'Loop Family Top-up Policy'}
                                    policyName={'ICICI Lombard General Insurance Co. Ltd.  '}
                                    policyImage={topupPolicyDetails?.policyLogo || ''}
                                    textColor={'beyondGrey'}
                                    uin={false}
                                />
                                <StyledTopUpPolicyCardWrapper>
                                    <TopUpPolicyCard
                                        coveredMembers={policyData?.familyStructure || ''}
                                        sumInsured={topUpDetails.sumInsured}
                                        deductible={topUpDetails.deductible}
                                    />
                                </StyledTopUpPolicyCardWrapper>
                                <StyledMemberWrapper>
                                    <StyledMemberHeading>
                                        <StyledMemberHeadingSpan>BOUGHT FOR: </StyledMemberHeadingSpan>
                                    </StyledMemberHeading>
                                    <StyledNameContainer>
                                        {policyData?.baseGmcPolicyData?.dependents?.map(
                                            (
                                                dep: {
                                                    firstName: string;
                                                    lastName: string;
                                                    relationship: string;
                                                    userId: string;
                                                },
                                                index: number
                                            ) => {
                                                return (
                                                    <MemberIdentity
                                                        key={index}
                                                        firstName={dep.firstName}
                                                        lastName={dep.lastName}
                                                        relationship={dep.relationship}
                                                        index={index}
                                                        bgColor={'white'}
                                                    />
                                                );
                                            }
                                        )}
                                    </StyledNameContainer>
                                </StyledMemberWrapper>
                            </StyledDetailsSection>
                        </StyledSection>
                    ) : (
                        <StyledSection>
                            <TopupSummaryCard selectedBasePolicy={selectedBasePolicy} />
                        </StyledSection>
                    )}

                    <StyledOrderSection>
                        <StyledOrderHeadingWrapper>
                            <StyledOrderHeading>PAYMENT SUMMARY</StyledOrderHeading>
                            <StyledOrderWrapper>
                                <StyledTotalAmountWrapper>
                                    <StyledTotalAmountLabel>
                                        Total Annual Premium
                                        <br />
                                        <TextView
                                            fontSize="13px"
                                            margin="10px 0px 0px 0px"
                                            color={theme.colors.beyondGrey}
                                        >
                                            (Inclusive of GST and service charge)
                                        </TextView>
                                    </StyledTotalAmountLabel>

                                    <StyledTotalAmountSpan>
                                        ₹
                                        {topUpDetails?.annualPremium
                                            ?.toString()
                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}
                                    </StyledTotalAmountSpan>
                                </StyledTotalAmountWrapper>
                                {!paymentStatus.data && (
                                    <StyledCheckboxSection>
                                        <StyledInput
                                            type="checkbox"
                                            defaultChecked={false}
                                            value=""
                                            onChange={() => setShowcheckboxChecked(!checkboxChecked)}
                                        />
                                        <TextView
                                            fontSize="14px"
                                            margin="0px 0px 0px 0px"
                                            lineHeight={'20px'}
                                            color={theme.colors.beyondGrey}
                                        >
                                            I hereby declare that I have read policy{' '}
                                            <TextView
                                                fontSize="14px"
                                                margin="0px 0px 0px 0px"
                                                color={theme.colors.loopEmerald}
                                            >
                                                {' '}
                                                <StyledText
                                                    onClick={() => {
                                                        trackClick(SEGMENT_ACTIONS.CLICK.TOPUP_SECTION_HOW);
                                                        {
                                                            topupPolicyDetails &&
                                                                redirectTo(topupPolicyDetails, 'terms');
                                                        }
                                                    }}
                                                >
                                                    Terms and Conditions
                                                </StyledText>{' '}
                                            </TextView>{' '}
                                            carefully.
                                        </TextView>
                                    </StyledCheckboxSection>
                                )}

                                <ButtonsContainer>
                                    {paymentStatus.data ? (
                                        <></>
                                    ) : (
                                        <Button
                                            type="primary"
                                            fullWidth={true}
                                            disabled={!checkboxChecked && true}
                                            onClick={() => (orderLoading ? null : proceedToPay())}
                                        >
                                            {orderLoading ? <Loader inverted /> : 'Proceed to Buy'}
                                        </Button>
                                    )}
                                </ButtonsContainer>
                                <StyledPremiumWrapper>
                                    <StyledPremiumLabel>Amount for tax savings</StyledPremiumLabel>
                                    <StyledDiscountSpan>
                                        ₹
                                        {topUpDetails?.digitPremiumWithoutGST >= 25000
                                            ? `${'25,000'}`
                                            : topUpDetails?.digitPremiumWithoutGST
                                                ?.toString()
                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    </StyledDiscountSpan>
                                    <Tooltip
                                        Icon={InfoIcon}
                                        Text={
                                            // eslint-disable-next-line max-len
                                            'This is the total amount you can save under Income Tax Section 80D after purchasing this policy. Maximum limit is ₹25,000.'
                                        }
                                    />
                                </StyledPremiumWrapper>
                            </StyledOrderWrapper>
                        </StyledOrderHeadingWrapper>
                    </StyledOrderSection>
                </StyledWrapper>
            </StyledContainer>
            <PaymentFailedModal isVisible={paymentFailedIsVisible} setIsVisible={setPaymentFailedIsVisible} />
            <FeedbackModal isVisible={feedbackIsVisible} setIsVisible={setFeedbackIsVisible} />
            <MedicalModal isVisible={medicalIsVisible} setIsVisible={setMedicalIsVisible} />
        </>
    );
};

export default SummaryPage;
