import React from 'react';
import {
    StyledContainer,
    StyledAppStoreImg,
    StyledPlayStoreImg,
    StyledBeyondImage,
    StyledTextWrapper,
    StyledWrapper,
    BeyondRow,
    BeyondSubtitle,
    BeyondTitleText
} from './styles';
import { AppStore, Beyond1, Beyond2, Beyond3, GooglePlay } from '../../../assets/img';
import { IBeyondInsurance } from './types';

const BeyondInsurance: React.FunctionComponent<IBeyondInsurance> = ({ redirectToAppStore, redirectToPlayStore }) => {
    return (
        <StyledContainer>
            <BeyondTitleText>Covering you beyond insurance</BeyondTitleText>
            <StyledWrapper>
                Our goal is continous healthcare all year round. Download the app to make the most of our services
            </StyledWrapper>
            <BeyondRow>
                <StyledBeyondImage src={Beyond1} />
                <StyledTextWrapper>
                    <BeyondSubtitle>Instant claims support</BeyondSubtitle>
                    <div>Claims specialist who support you every step of the way</div>
                </StyledTextWrapper>
            </BeyondRow>
            <BeyondRow>
                <StyledBeyondImage src={Beyond2} />
                <StyledTextWrapper>
                    <BeyondSubtitle>24/7 Medical Advisor</BeyondSubtitle>
                    <div>A personal medical advisor to answer all your questions</div>
                </StyledTextWrapper>
            </BeyondRow>
            <BeyondRow>
                <StyledBeyondImage src={Beyond3} />
                <StyledTextWrapper>
                    <BeyondSubtitle>Experienced Doctors</BeyondSubtitle>
                    <div>Unlimited free consults with doctors across specialities</div>
                </StyledTextWrapper>
            </BeyondRow>
            <BeyondRow>
                <StyledAppStoreImg onClick={redirectToAppStore} src={AppStore} alt="Apple App Store" />
                <StyledPlayStoreImg src={GooglePlay} onClick={redirectToPlayStore} alt="Google Play Store" />
            </BeyondRow>
        </StyledContainer>
    );
};

export default BeyondInsurance;
