import styled from 'styled-components';

export const StyledContainer = styled.div`
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 12px;
`;
export const StyledDivContainer = styled.div`
    display: flex;
    margin: 14px;
    flex-direction: column;
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;
export const StyledTitleText = styled.div(
    (p) => `
    color: ${p.theme.colors.muted};
    font-size: ${p.theme.fontSizes.label};
    text-transform: uppercase;
    font-weight: 600;
`
);
export const StyledText = styled.div(
    (p) => `
    color: ${p.theme.colors.black};
    font-size: ${p.theme.fontSizes.body};
    margin-top: 5px;
    font-weight: bold;
    @media (max-width: 768px) {
        margin-left: 0px;
    }
`
);

export const StyledAlertContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0px 10px;
`;

export const StyledAlertDescContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
