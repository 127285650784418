import { ITheme } from './types';

const theme: ITheme = {
    colors: {
        black: '#2D3748',
        white: '#ffffff',
        border: '#D4E3EB',
        muted: '#A0AEC0',
        error: '#CB3C3A',
        primary: '#1BB4A4',
        secondary: '#F7F8FA',
        primaryBG: '#18A294',
        activeBG: '#F1F35',
        secondaryBG: 'rgba(212, 227, 235, 0.2)',
        errorBG: '#FAECEB',
        mutedBG: '#F2F7F6',
        primaryTransparent: 'rgba(27, 180, 164, 0.2)',
        mutedTransparent: '#E6EFED',
        coverYellow: '#FDD506',
        darkTheme: '#0D7963',
        teamTurquoise: '#36D6C3',
        consultViolet: '#A586EF',
        loopEmerald: '#025F4C',
        coverUpGreen: '#BCDD33',
        borderLine: 'rgba(255, 255, 255, 0.5)',
        planRed: '#FF8080',
        lightGreen: '#F2F7F6',
        lightGrey: '#F6F6F6',
        midGrey: '#858484',
        beyondGrey: '#595959',
        darkGreen: '#054F40',
        purple: '#a586ef',
        platinum: '#D9E7E4'
    },
    fontSizes: {
        landing: '30px',
        title: '24px',
        heading: '18px',
        body: '14px',
        sectionHeading: '10px',
        label: '12px',
        landingTitle: '46px',
        largeFont: '45px',
        innerheading: '22px',
        mediumFont: '16px',
        subHeading: '16px'
    },
    typography: {
        s1SubHeader: {
            'font-family': 'Work Sans',
            'font-size': '24px',
            'line-height': '32px',
            'font-weight': 400
        },
        b1Body: {
            'font-family': 'Work Sans',
            'font-size': '18px',
            'line-height': '28px',
            'font-weight': 400
        }
    }
};

export const colorLibrary = ['#FDD506', '#a586ef', '#FF8080', '#BCDD33', '#36D6C3'];

export default theme;
