import styled from 'styled-components';
import theme from '../../../theme';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 60px 0px;
    width: 100%;
    align-items: center;
    letter-spacing: -0.02em;
    background-color: ${theme.colors.loopEmerald};
    @media only screen and (max-width: 768px) {
        padding: 30px 20px;
        flex-direction: column;
    }
`;

export const StyledContainerWrapper = styled.div`
    display: flex;
    width: 100%;
    margin: 20px 0px;
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const ButtonContainer = styled.div`
    /* width: 250px;
    height: 56px; */
`;

export const VerticalLine = styled.div`
    width: 1px;
    height: 64px;
    background-color: ${theme.colors.borderLine};
    margin-top: 38px;
    margin-bottom: 38px;
    margin-left: 28px;
    margin-right: 28px;
    @media only screen and (max-width: 768px) {
        display: none;
    }
`;
