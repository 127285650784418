import React from 'react';
import {
    StyledContainer,
    StyledSuccessWrapper,
    StyledSuccessTextWrapper,
    StyledSuccessImgWrapper,
    StyledSuccessTextBTNWrapper,
    StyledSuccessTextBTN,
    StyledSuccessImg,
    StyledBTNWrapper,
    FlexWrapper
} from './styles';
// import { AppStore, Beyond1, Beyond2, Beyond3, GooglePlay } from '../../../assets/img';
import { IBeyondInsurance } from './types';
import { SuccessIcon, AboutIcon, PlayStoreIcon, IosIcon } from '../../../assets/img';
import { TextView } from '../TextView';
import theme from '../../../theme';
import { ReduxState } from '../../../redux';
import { useSelector } from 'react-redux';
import { capitalizeFirstLetter, getSumInsured } from '../../../utils/common';
const BeyondInsurance: React.FunctionComponent<IBeyondInsurance> = ({
    redirectToAppStore,
    redirectToPlayStore,
    source,
    sumInsured
}) => {
    const userName = useSelector((state: ReduxState) => state.TopUp_user?.userData?.data);
    return (
        <StyledContainer>
            {source === 'summary' ? (
                <FlexWrapper>
                    <StyledSuccessWrapper>
                        <img src={SuccessIcon} width='130' height='67' />
                    </StyledSuccessWrapper>
                    <StyledSuccessTextWrapper>
                        <TextView fontSize='18px' margin='10px 0px 40px 10px' color={theme.colors.white}>
                            Congratulations! You have successfully increased your health coverage :)
                        </TextView>
                        <StyledSuccessTextBTNWrapper>
                            <TextView fontSize='14px' margin='0px 0px 35px 10px' color={theme.colors.white}>
                                One last step, Download the Loop App to view your policy cards!
                            </TextView>
                            <StyledBTNWrapper>
                                <StyledSuccessTextBTN onClick={redirectToPlayStore}>
                                    <img src={PlayStoreIcon} />
                                    Download for Android
                                </StyledSuccessTextBTN>
                                <StyledSuccessTextBTN onClick={redirectToAppStore}>
                                    <img src={IosIcon} />
                                    Download for iOS
                                </StyledSuccessTextBTN>
                            </StyledBTNWrapper>
                        </StyledSuccessTextBTNWrapper>
                    </StyledSuccessTextWrapper>
                </FlexWrapper>
            ) : (
                <FlexWrapper>
                    <StyledSuccessTextWrapper>
                        <TextView fontSize='18px' margin='0px 0px 20px 0px' color={theme.colors.white}>
                            Hi {capitalizeFirstLetter(userName.firstName)}!
                        </TextView>
                        <TextView fontSize='22px' margin='0px 0px 40px 0px' color={theme.colors.white}>
                            You have already opted for additional health coverage
                            <TextView fontSize='22px' margin='0px 0px 0px 0px' color={theme.colors.coverYellow}>
                                {' worth ₹'}
                                {getSumInsured(sumInsured || '')}
                            </TextView>
                        </TextView>

                        <StyledSuccessTextBTNWrapper>
                            <TextView fontSize='15px' margin='0px 0px 20px 0px' color={theme.colors.white}>
                                One last step, Download the Loop App to view your policy cards!
                            </TextView>
                            <StyledBTNWrapper>
                                <StyledSuccessTextBTN onClick={redirectToPlayStore}>
                                    <img src={PlayStoreIcon} />
                                    Download for Android
                                </StyledSuccessTextBTN>
                                <StyledSuccessTextBTN onClick={redirectToAppStore}>
                                    <img src={IosIcon} />
                                    Download for iOS
                                </StyledSuccessTextBTN>
                            </StyledBTNWrapper>
                        </StyledSuccessTextBTNWrapper>
                    </StyledSuccessTextWrapper>
                </FlexWrapper>
            )}
            <StyledSuccessImgWrapper>
                <StyledSuccessImg src={AboutIcon} />
            </StyledSuccessImgWrapper>
        </StyledContainer>
    );
};

export default BeyondInsurance;
