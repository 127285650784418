import styled from 'styled-components';
import { topUpFeatureEnabled } from '../../../../utils/common';

export const StyledNameContainer = styled.div`
    display: flex;
    margin-left: 10px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const StyledInsuranceImage = styled.img`
    overflow: hidden;
    height: ${!topUpFeatureEnabled() ? '90px' : '62px'};
    width: ${!topUpFeatureEnabled() ? '90px' : '62px'};
    max-height: ${!topUpFeatureEnabled() ? '90px' : '62px'};
    max-width: ${!topUpFeatureEnabled() ? '90px' : '62px'};
    min-height: ${!topUpFeatureEnabled() ? '90px' : '62px'};
    min-width: ${!topUpFeatureEnabled() ? '90px' : '62px'};
`;
export const StyledInsuranceNameContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 16px;
`;
export const StyledInsuranceName = styled.div<{
    $color: string;
}>`
    max-width: 400px;
    color: ${(p) => (p.$color === 'white' ? p.theme.colors.white : p.theme.colors.beyondGrey)};
    font-size: ${!topUpFeatureEnabled() ? (p) => p.theme.fontSizes.heading : (p) => p.theme.fontSizes.label};
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.02em;
`;

export const StyledInsuranceCategory = styled.div<{
    $color: string;
}>`
    color: ${(p) => (p.$color === 'white' ? p.theme.colors.white : p.theme.colors.beyondGrey)};
    font-size: ${!topUpFeatureEnabled() ? (p) => p.theme.fontSizes.body : (p) => p.theme.fontSizes.body};
    margin-top: ${!topUpFeatureEnabled() ? '8px' : '2px'};
    font-weight: 600;
    font-style: normal;
    font-weight: 400;
`;
