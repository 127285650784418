export const MAIN_ROUTES = {
    login: '/login',
    topUpLanding: '/'
};

export const INTERNAL_ROUTES = {
    welcome: '/welcome',
    dashboard: '/dashboard',
    summary: '/summary',
    confirm: '/app/confirm'
};
