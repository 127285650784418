import styled from 'styled-components';

export const InfoTagImage = styled.img`
    margin-right: 8px;
    width: 24px;
`;

export const CloseImage = styled.img`
    margin-left: auto;
    margin-right: 20px;
    cursor: pointer;
`;

export const TooltipMainContainer = styled.div`
    /* position: relative; */
    display: flex;
    align-items: center;
`;

export const StyledText = styled.span`
    display: flex;
`;
