import React from 'react';
import { StyledTitle, StyledTitleSpan } from './styles';
import { useLocation } from 'react-router';
import { ITopUpUnAvailable } from './types';
import TopUpUnAvailableInfo from '../../atoms/TopUpUnAvailableInfo';
import { shapeRight } from '../../../assets/img';
import useSegment from '../../../../utils/hooks/useSegment';
import { SEGMENT_ACTIONS } from '../../../utils/constants/SegmentActionConstants';
import { getSegmentPageName } from '../../../utils/common';
const TopUpUnAvailable: React.FunctionComponent<ITopUpUnAvailable> = ({
    isPolicyAvailable,
    isPolicyActive,
    isTopUpAvailable,
    userAge
}) => {
    const location = useLocation();
    const trackPage = useSegment('page');
    const currentPageAction = SEGMENT_ACTIONS.PAGE[getSegmentPageName(location.pathname) as any];
    React.useEffect(() => {
        if (!isTopUpAvailable) {
            trackPage({
                name: 'Enrolment_date_expired',
                properties: {
                    page_url:
                        (currentPageAction.properties?.page_url as (path: string) => string)(location.pathname) || '',
                    page_id:
                        (currentPageAction.properties?.page_id as (path: string) => string)(location.pathname) || ''
                }
            });
        }
        if (!isPolicyActive) {
            trackPage({
                name: 'GMC_Policy_Expired',
                properties: {
                    page_url:
                        (currentPageAction.properties?.page_url as (path: string) => string)(location.pathname) || '',
                    page_id:
                        (currentPageAction.properties?.page_id as (path: string) => string)(location.pathname) || ''
                }
            });
        }
    }, [isTopUpAvailable, isPolicyActive]);
    return (
        <>
            {isPolicyAvailable ? (
                isPolicyActive ? (
                    userAge ? (
                        !isTopUpAvailable && (
                            <TopUpUnAvailableInfo
                                title={
                                    <StyledTitle>
                                        Oops! You’ve <StyledTitleSpan> crossed the threshold </StyledTitleSpan>to enrol
                                        yourself for this top up
                                    </StyledTitle>
                                }
                                // eslint-disable-next-line max-len
                                description='Currently, we provide top ups only if you have an active GMC policy with Loop. Please contact your HR to renew your GMC policy in order to buy this top up'
                                imageSrc={shapeRight}
                            />
                        )
                    ) : (
                        <TopUpUnAvailableInfo
                            title={
                                <StyledTitle>
                                    This is Super Top-up is currently available only for members age
                                    <StyledTitleSpan> {' 80 years or younger'} </StyledTitleSpan>
                                </StyledTitle>
                            }
                            // eslint-disable-next-line max-len
                            description='However, we’re on it! You will be notified when we start offering coverage to older members. '
                            imageSrc={shapeRight}
                        />
                    )
                ) : (
                    <TopUpUnAvailableInfo
                        title={
                            <StyledTitle>
                                Oops! Looks like your GMC policy is up for <StyledTitleSpan> renewal </StyledTitleSpan>
                            </StyledTitle>
                        }
                        // eslint-disable-next-line max-len
                        description='Currently, we provide top ups only if you have an active GMC policy with Loop. Please contact your HR to renew your GMC policy in order to buy this top up'
                        imageSrc={shapeRight}
                    />
                )
            ) : (
                <TopUpUnAvailableInfo
                    title={
                        <StyledTitle>
                            Oops! Looks like <StyledTitleSpan>you don’t have a GMC </StyledTitleSpan>
                            policy with Loop{' '}
                        </StyledTitle>
                    }
                    // eslint-disable-next-line max-len
                    description='Currently, we provide top ups only if you have an active GMC policy with Loop. Please contact your HR to renew your GMC policy in order to buy this top up'
                    imageSrc={shapeRight}
                />
            )}
        </>
    );
};

export default TopUpUnAvailable;
