import styled from 'styled-components';

export const StyledContainer = styled.div`
    box-shadow: 0px -2px 14px rgba(168, 196, 206, 0.1);
    border-radius: 16px;
    background-color: ${(p) => p.theme.colors.white};
    /* overflow-x: auto; */
    max-width: 100%;
    @media (max-width: 768px) {
        box-shadow: 0px;
        border-radius: 0;
        min-width: 100%;
    }
`;
export const StyledContent = styled.div`
    width: 100%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    /* overflow-x: auto; */
    max-width: 100%;
`;
