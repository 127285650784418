import styled from 'styled-components';

export const StyledLabelBackground = styled.span<{ isActive: boolean }>(
    (p) => `
    background-color: ${
    p.isActive ? p.theme.colors.primary : p.theme.colors.error};
    color: ${p.theme.colors.white};
    font-size: ${p.theme.fontSizes.label};
    border: 1px solid ${
    p.isActive ? p.theme.colors.primary : p.theme.colors.error};
    padding: 5px 15px;
    border-radius: 46px;
    font-weight: 600;
    min-width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
`
);
