import styled from 'styled-components';

export const StyledContainer = styled.div(
    (p) => `
    max-width: 45%;
    min-width: 434px;
    width: 434px;
    height: 150px;
    padding: 24px 16px;
    margin: 15px;
    border-radius: 12px;
    position: relative;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: ${p.theme.colors.secondary};
    border: 2px solid ${p.theme.colors.primary};
    @media (max-width: 768px) {
        max-width: 100%;
        min-width: 0px;
        height: 210px;
        margin: 0px 15px 15px 15px;
    }
    @media screen and (orientation:portrait) {
        max-width: 100%;
        height: 210px;
}
`
);
export const StyledLabelText = styled.div(
    (p) => `
    font-size: ${p.theme.fontSizes.label};
    color: ${p.theme.colors.mutedBG};
    font-weight: 500;
    margin-bottom: 5px;
`
);
export const StyledContentText = styled.div(
    (p) => `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: ${p.theme.fontSizes.body};
    color: ${p.theme.colors.black};
    max-width: 130px;
    text-transform: capitalize;
`
);
export const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 100px;
    max-width: 100px;
`;
export const StyledContentContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 30px;
    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        margin-top: 15px;
        box-sizing: border-box;
    }
    @media screen and (orientation: portrait) {
        flex-wrap: wrap;
        margin-top: 15px;
        padding-left: 20px;
    }
`;
export const StyledNameContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;
export const MarginContainer = styled.div`
    margin-right: 10px;
`;
export const AbsoluteContainer = styled.div`
    position: absolute;
    top: 15px;
    right: 8px;
    margin: 10px 15px;
`;
export const StyledDeleteImage = styled.img`
    height: 20px;
    width: 17px;
    cursor: pointer;
    margin-left: 12px;
`;
export const StyledEditImage = styled.img`
    height: 20px;
    width: 14px;
    cursor: pointer;
`;
export const StyledAlignContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    @media (max-width: 768px) {
        padding: 10px 20px;
        box-sizing: border-box;
    }
    @media screen and (orientation: portrait) {
        width: 100%;
        justify-content: space-between;
        padding: 10px 20px;
        box-sizing: border-box;
    }
`;
