import styled from 'styled-components';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
`;
export const Spacing = styled.div`
    min-width: 16px;
    @media (max-width: 1204px) {
        min-width: 100%;
        min-height: 16px;
    }
`;
