import styled from 'styled-components';

export const StyledContainer = styled.div<{ $showMobile: boolean }>(
    (p) => `
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    @media (max-width: 768px) {
            display: flex;
            width:100%;
    }
    ${
    p.$showMobile
        ? `display: none
        `
        : `@media (max-width: 768px) {
            display: none;
        }`
}
`
);

export const ButtonContainer = styled.div`
    width: 261px;
    margin: 25px;
    margin-bottom: 0;
    font-family: 'Work Sans';
    @media (max-width: 768px) {
        width: 100%;
        margin: 20px 20px;
        margin-bottom: 15px;
    }
`;
