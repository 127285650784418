import styled from 'styled-components';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    background-color: ${(p) => p.theme.colors.white};
    border-radius: 16px;
`;
export const StyledRowContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;
export const IdentityWrapper = styled.div`
    margin: 25px;
    margin-bottom: 0;
`;
