import React from 'react';
import { AddMemberCard, MemberCard } from '..';
import { IDependent } from '../../../redux/slices/PolicyListSlice/types';
import { colorLibrary } from '../../../theme';
import {
    StyledDependentText,
    EnrollMembersContainer,
    StyledParentTypeContainer,
    StyledDependentSubText
} from './styles';
import { IEnrolDependents, IRelationship } from './types';
import { RadioButton } from '../../atoms';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../redux';
import { EmployeeSummaryNotice } from '../../atoms';
import { defaultDateFormat } from '../../../utils/common';
import {
    getDependentCountUtil,
    getRelationAgeUtil,
    getRelationshipUtil,
    oneOfParentsOrInLawsOptions,
    parentOptions
} from './utils';

const EnrolDependents: React.FunctionComponent<IEnrolDependents> = ({
    title,
    currentMapping,
    targetMapping,
    filteredDependents,
    category,
    onAddMemberClick,
    onMemberEditClick,
    onMemberDeleteClick,
    noOfCoveredMembers,
    relationship,
    selectedRelationship
}) => {
    const inferredParentId = parentOptions.map(({ id })=>id).includes(filteredDependents[0]?.relationship) ?
        filteredDependents[0]?.relationship :
        parentOptions[0].id;
    const [selectedParentId, setSelectedParentId] = React.useState(inferredParentId);
    const preValidations = useSelector((state: ReduxState) => state.enrolment.policyDetails);

    return (
        <>
            {category !== 'Parents-in-law' ? (
                <StyledDependentText>
                    {noOfCoveredMembers !== 1 ? title : null}
                    <StyledDependentSubText>
                        {getRelationAgeUtil(relationship, preValidations, title)}
                    </StyledDependentSubText>
                </StyledDependentText>
            ) : null}
            {oneOfParentsOrInLawsOptions.includes(category) && (
                <StyledParentTypeContainer>
                    {parentOptions.map((options, index) => {
                        return (
                            <RadioButton
                                key={`ParentType-${index}`}
                                name={options.name}
                                id={options.id}
                                selectedId={selectedParentId}
                                onClick={setSelectedParentId}
                                disabled={filteredDependents.length ? true : false}
                            />
                        );
                    })}
                    {filteredDependents.length ? (
                        <EmployeeSummaryNotice>
                            Please delete the selected&nbsp;
                            {parentOptions.filter((val) => val.id === selectedParentId)[0].value} in order to selected a
                            different option.
                        </EmployeeSummaryNotice>
                    ) : null}
                </StyledParentTypeContainer>
            )}
            <EnrollMembersContainer>
                {filteredDependents &&
                    filteredDependents.map((dep: IDependent, index: number) => {
                        return (
                            <MemberCard
                                key={dep.userId}
                                firstName={dep.firstName ? dep.firstName : ''}
                                lastName={dep.lastName ? dep.lastName : ''}
                                relationship={dep.relationship ? dep.relationship : 'N/A'}
                                doB={dep.doB ? defaultDateFormat(dep.doB) : 'N/A'}
                                gender={dep.gender ? dep.gender : 'N/A'}
                                mobile={dep.mobile ? dep.mobile : 'N/A'}
                                onMemberDelete={() => onMemberDeleteClick(dep.userId)}
                                onMemberEdit={() => onMemberEditClick(dep)}
                                color={colorLibrary[index % colorLibrary.length]}
                            />
                        );
                    })}
                {Array.from(
                    Array(Math.max(
                        getDependentCountUtil(
                            'target',
                            getRelationshipUtil(category),
                            currentMapping,
                            targetMapping,
                            selectedParentId
                        )?.number -
                            getDependentCountUtil(
                                'current',
                                getRelationshipUtil(category),
                                currentMapping,
                                targetMapping,
                                selectedParentId
                            )?.number,
                        0
                    )),
                    (_element, index) => {
                        return (
                            <AddMemberCard
                                key={index}
                                onClick={(relationship) => {
                                    selectedRelationship(relationship as IRelationship);
                                    onAddMemberClick();
                                }}
                                relationship={
                                    !oneOfParentsOrInLawsOptions.includes(category) ? getRelationshipUtil(category) :
                                        selectedParentId
                                }
                            />
                        );
                    }
                )}
            </EnrollMembersContainer>
        </>
    );
};

export default EnrolDependents;
