import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { IApplicationStateSlice } from './types';

const applicationState = createSlice({
    name: 'applicationState',
    initialState,
    reducers: {
        resetState(state: IApplicationStateSlice) {
            state.showWelcomeScreen = initialState.showWelcomeScreen;
        },
        setShowWelcomeScreen(state: IApplicationStateSlice, { payload }: any) {
            state.showWelcomeScreen = payload.showWelcomeScreen;
        },
        setShowAddModal(state: IApplicationStateSlice, { payload }: any) {
            state.showAddModal = payload.showAddModal;
        },
        setShowEditModal(state: IApplicationStateSlice, { payload }: any) {
            state.showEditModal = payload.showEditModal;
        },
        setShowDeleteModal(state: IApplicationStateSlice, { payload }: any) {
            state.showDeleteModal = payload.showDeleteModal;
        },
        setMemberIdToDelete(state: IApplicationStateSlice, { payload }: any) {
            state.memberIdToDelete = payload.memberIdToDelete;
        },
        setDependent(state: IApplicationStateSlice, { payload }: any) {
            state.selectedDependent = payload.selectedDependent;
        },
        setRelation(state: IApplicationStateSlice, { payload }: any) {
            state.selectedRelation = payload.selectedRelation;
        },
        setCurrentStepperStep(state: IApplicationStateSlice, { payload }: any) {
            state.currentStepperStep = payload.currentStepperStep;
        },
        setIsSuperTopUpSelected(state: IApplicationStateSlice, { payload }: any) {
            state.isSuperTopupSelected = payload.isSuperTopupSelected;
        },
        setUnSelectTopupFeedback(state: IApplicationStateSlice, { payload }: any) {
            state.unSelectTopupFeedback = payload.unSelectTopupFeedback;
        }
    },
    extraReducers: {
        'common/cleanup': (state) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            state = initialState;
        }
    }
});
export default applicationState.reducer;
export const {
    setShowWelcomeScreen,
    setShowAddModal,
    setShowDeleteModal,
    setShowEditModal,
    setMemberIdToDelete,
    setDependent,
    setRelation,
    setCurrentStepperStep,
    resetState,
    setIsSuperTopUpSelected,
    setUnSelectTopupFeedback
} = applicationState.actions;
