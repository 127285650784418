import React from 'react';
import {
    StyledContainer,
    StyledDetailsSection,
    StyledMemberWrapper,
    StyledNameContainer,
    StyledInsuranceContainer,
    StyledDetailsWrapper,
    StyledSIContainer,
    SIDetailsWrapper,
    StyledDivider,
    StyledDividerSpan,
    StyledMembersSection,
    StyledEnrolledMemberButton,
    StyledEnrolledMemberButtonText,
    StyledDelIcon,
    StyledSection
} from './styles';

import InsuranceIdentity from '../../atoms/InsuranceIdentity';
import { ReduxState } from '../../../redux';
import { useDispatch, useSelector } from 'react-redux';
import MemberIdentity from '../../atoms/MemberIdentity';

import { TextView } from '../../atoms/TextView';
import theme from '../../../theme';
import { getSumInsured } from '../../../utils/common';
import { fetchUserPolicyList, resetCalculatedPremium } from '../../../redux/slices/PolicyListSlice';
import { Loader } from '../../atoms';
import { DeleteIcon } from '../../../assets/img';
import CancelTopUpModal from '../../../../components/containers/CancelTopUpModal';
import { getAgeFromDOB, getSegmentPageName } from '../../../../utils/common';
import { InsuranceTypeConstants } from '../../../../utils/constants';

import useSegment from '../../../../utils/hooks/useSegment';
import { SEGMENT_ACTIONS } from '../../../../utils/constants/SegmentActionConstants';
import { enrolUserPolicyFamily } from '../../../../redux/slices/EnrolmentSlice/thunks';
import { checkParentalPolicy, checkParentalPolicyTopUp } from '../../../../utils/PolicyServices';
import { fetchParentalPolicyData } from '../../../redux/slices/PolicyListSlice/thunks';
import { ISummaryCard } from './types';

const TopupSummaryCard: React.FunctionComponent<ISummaryCard> = ({ selectedBasePolicy }) => {
    const dispatch = useDispatch();
    const trackClick = useSegment('click');
    const userId = useSelector((state: ReduxState) => state.TopUp_user?.userData?.data.userId);
    const topUpDetails: any = useSelector((state: ReduxState) => state.TopUp_policyData.memberTopUp);
    const policyDetails: any = useSelector((state: ReduxState) => state.TopUp_policyData.policyList.data);
    const isLoading = useSelector((state: ReduxState) => state.TopUp_policyData.policyList.loading);
    const EnrollUserState = useSelector((state: ReduxState) => state.TopUp_policyData.enrolUser);
    const basePolicyData = policyDetails?.baseGmcPolicyData;
    const topupPolicyDetails = policyDetails?.topUpPolicyData;
    const currentPageAction = SEGMENT_ACTIONS.PAGE[getSegmentPageName(location.pathname) as any];
    const policyId = useSelector((state: ReduxState) => state.TopUp_applicationState.topupPolicyId);
    const [showCancelModalIsVisible, setShowCancelModalIsVisible] = React.useState<boolean>(false);
    const makeApiCall = () => {
        if (selectedBasePolicy && checkParentalPolicy(selectedBasePolicy)) {
            const parentalPolicyId = selectedBasePolicy.policyId;
            dispatch(fetchParentalPolicyData({ userId, parentalPolicyId }));
        } else {
            dispatch(fetchUserPolicyList({ userId }));
        }
    };
    React.useEffect(() => {
        makeApiCall();
    }, [selectedBasePolicy]);
    React.useEffect(() => {
        if (EnrollUserState && !EnrollUserState.loading && (EnrollUserState.data || EnrollUserState.error)) {
            makeApiCall();
        }
    }, [EnrollUserState]);
    const handleRemoveTopUpBtn = () => {
        const segmentAction = SEGMENT_ACTIONS.CLICK.REMOVE_TOPUP;
        trackClick({
            name: segmentAction.name,
            properties: {
                describe: segmentAction.properties?.describe || '',
                page_url: (currentPageAction.properties?.page_url as (path: string) => string)(location.pathname) || '',
                page_id: (currentPageAction.properties?.page_id as (path: string) => string)(location.pathname) || '',
                sum_insured: (segmentAction.properties?.sum_insured as (sumInsured: string) => string)(
                    topUpDetails?.sumInsured || 0
                ),
                page_title: segmentAction.properties?.page_title,
                login_status: segmentAction.properties?.login_status
            }
        });
        setShowCancelModalIsVisible(true);
    };
    const [isCollapsed, setIsCollapsed] = React.useState(true);
    const [isCollapsedTopUp, setIsCollapsedTopUp] = React.useState(false);
    const removeTopUp = () => {
        const dependentsArray = topUpDetails.userIds.filter((item: string) => item !== userId);
        dispatch(
            enrolUserPolicyFamily({
                userId: userId,
                policyId: policyId,
                slabId: '',
                enrolmentStatus: 'NOT_SELECTED',
                policyStatus: 'PENDING',
                dependentIds: dependentsArray,
                parentalPolicyId: checkParentalPolicyTopUp(basePolicyData) ? basePolicyData.policyId : ''
            })
        );
        dispatch(resetCalculatedPremium());
        makeApiCall();
        setShowCancelModalIsVisible(false);
    };
    return isLoading ? (
        <Loader />
    ) : (
        <StyledContainer>
            {basePolicyData && (
                <StyledDetailsSection>
                    <StyledInsuranceContainer>
                        <InsuranceIdentity
                            policyType={InsuranceTypeConstants.GMC}
                            policyCategory={basePolicyData?.policyName}
                            policyName={basePolicyData?.tpaName}
                            policyImage={basePolicyData?.policyImage || ''}
                            textColor={'beyondGrey'}
                        />

                        <StyledDetailsWrapper>
                            <StyledDivider>
                                <StyledDividerSpan />
                            </StyledDivider>
                            <StyledSIContainer>
                                <TextView
                                    fontSize="14px"
                                    margin="0px 0px 5px 0px"
                                    color={theme.colors.beyondGrey}
                                    fontWeight="500"
                                >
                                    Sum Insured
                                </TextView>

                                <SIDetailsWrapper>
                                    <TextView
                                        fontSize="16px"
                                        margin="0px 0px 0px 0px"
                                        color={theme.colors.loopEmerald}
                                        fontWeight="500"
                                    >
                                        &#8377;&nbsp;{getSumInsured(basePolicyData?.sumInsured)}
                                    </TextView>
                                </SIDetailsWrapper>
                            </StyledSIContainer>
                            <StyledDivider>
                                <StyledDividerSpan />
                            </StyledDivider>
                            <StyledSIContainer>
                                <TextView
                                    fontSize="14px"
                                    margin="0px 0px 5px 0px"
                                    color={theme.colors.beyondGrey}
                                    fontWeight="500"
                                >
                                    Amount Payable
                                </TextView>

                                <SIDetailsWrapper>
                                    <TextView
                                        fontSize="16px"
                                        margin="0px 0px 0px 0px"
                                        color={theme.colors.loopEmerald}
                                        fontWeight="500"
                                    >
                                        &#8377;&nbsp;
                                        {basePolicyData?.annualPremium
                                            ?.toString()
                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    </TextView>
                                </SIDetailsWrapper>
                            </StyledSIContainer>
                        </StyledDetailsWrapper>
                    </StyledInsuranceContainer>
                    <StyledMembersSection>
                        <TextView
                            fontSize="14px"
                            margin="0px 0px 5px 0px"
                            color={theme.colors.beyondGrey}
                            fontWeight="500"
                        >
                            <StyledEnrolledMemberButton>
                                <StyledSection>
                                    BOUGHT FOR: {basePolicyData?.dependents?.length} members&nbsp;
                                    <StyledEnrolledMemberButtonText onClick={() => setIsCollapsed(!isCollapsed)}>
                                        {!isCollapsed ? ' (SHOW ↓) ' : '(HIDE ↑)'}
                                        <>&nbsp;&nbsp;</>
                                    </StyledEnrolledMemberButtonText>
                                </StyledSection>
                            </StyledEnrolledMemberButton>
                        </TextView>
                        {isCollapsed && (
                            <StyledMemberWrapper>
                                <StyledNameContainer>
                                    {basePolicyData?.dependents?.map(
                                        (
                                            dep: {
                                                firstName: string;
                                                lastName: string;
                                                relationship: string;
                                                doB: number;
                                            },
                                            index: number
                                        ) => {
                                            return (
                                                <MemberIdentity
                                                    key={index}
                                                    firstName={dep.firstName}
                                                    lastName={dep.lastName}
                                                    relationship={dep.relationship}
                                                    index={index}
                                                    age={getAgeFromDOB(dep.doB)}
                                                />
                                            );
                                        }
                                    )}
                                </StyledNameContainer>
                            </StyledMemberWrapper>
                        )}
                    </StyledMembersSection>
                </StyledDetailsSection>
            )}
            {topupPolicyDetails && topupPolicyDetails?.enrolmentStatus === 'PENDING' && (
                <StyledDetailsSection>
                    <StyledInsuranceContainer>
                        <InsuranceIdentity
                            policyType={InsuranceTypeConstants.SUPER_TOP_UP}
                            policyCategory={topupPolicyDetails?.policyName}
                            policyName={topupPolicyDetails?.tpaName}
                            policyImage={topupPolicyDetails?.policyImage || ''}
                            textColor={'beyondGrey'}
                        />

                        <StyledDetailsWrapper>
                            <StyledDivider>
                                <StyledDividerSpan />
                            </StyledDivider>
                            <StyledSIContainer>
                                <TextView
                                    fontSize="14px"
                                    margin="0px 0px 5px 0px"
                                    color={theme.colors.beyondGrey}
                                    fontWeight="500"
                                >
                                    Sum Insured
                                </TextView>

                                <SIDetailsWrapper>
                                    <TextView
                                        fontSize="16px"
                                        margin="0px 0px 0px 0px"
                                        color={theme.colors.loopEmerald}
                                        fontWeight="500"
                                    >
                                        &#8377;&nbsp;{getSumInsured(topupPolicyDetails?.sumInsured)}
                                    </TextView>
                                </SIDetailsWrapper>
                            </StyledSIContainer>
                            <StyledDivider>
                                <StyledDividerSpan />
                            </StyledDivider>
                            <StyledSIContainer>
                                <TextView
                                    fontSize="14px"
                                    margin="0px 0px 5px 0px"
                                    color={theme.colors.beyondGrey}
                                    fontWeight="500"
                                >
                                    Amount Payable
                                </TextView>

                                <SIDetailsWrapper>
                                    <TextView
                                        fontSize="16px"
                                        margin="0px 0px 0px 0px"
                                        color={theme.colors.loopEmerald}
                                        fontWeight="500"
                                    >
                                        &#8377;&nbsp;
                                        {topUpDetails?.annualPremium?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    </TextView>
                                </SIDetailsWrapper>
                            </StyledSIContainer>
                        </StyledDetailsWrapper>
                    </StyledInsuranceContainer>
                    <StyledMembersSection>
                        <TextView
                            fontSize="14px"
                            margin="0px 0px 5px 0px"
                            color={theme.colors.beyondGrey}
                            fontWeight="500"
                        >
                            <StyledEnrolledMemberButton>
                                <StyledSection>
                                    BOUGHT FOR: {basePolicyData?.dependents?.length} members&nbsp;
                                    <StyledEnrolledMemberButtonText
                                        onClick={() => setIsCollapsedTopUp(!isCollapsedTopUp)}
                                    >
                                        {!isCollapsedTopUp ? ' (SHOW ↓) ' : '(HIDE ↑)'}
                                        <>&nbsp;&nbsp;</>
                                    </StyledEnrolledMemberButtonText>
                                </StyledSection>
                                <StyledDelIcon src={DeleteIcon} onClick={() => handleRemoveTopUpBtn()} />
                                <CancelTopUpModal
                                    isVisible={showCancelModalIsVisible}
                                    setIsVisible={setShowCancelModalIsVisible}
                                    showSummary={removeTopUp}
                                    selectedSI={topupPolicyDetails?.sumInsured}
                                    pageTitle={'checkout'}
                                />
                            </StyledEnrolledMemberButton>
                        </TextView>
                        {isCollapsedTopUp && (
                            <StyledMemberWrapper>
                                <StyledNameContainer>
                                    {basePolicyData?.dependents?.map(
                                        (
                                            dep: {
                                                firstName: string;
                                                lastName: string;
                                                relationship: string;
                                                doB: number;
                                            },
                                            index: number
                                        ) => {
                                            return (
                                                <MemberIdentity
                                                    key={index}
                                                    firstName={dep.firstName}
                                                    lastName={dep.lastName}
                                                    relationship={dep.relationship}
                                                    index={index}
                                                    age={getAgeFromDOB(dep?.doB)}
                                                />
                                            );
                                        }
                                    )}
                                </StyledNameContainer>
                            </StyledMemberWrapper>
                        )}
                    </StyledMembersSection>
                </StyledDetailsSection>
            )}
        </StyledContainer>
    );
};

export default TopupSummaryCard;
