import styled from 'styled-components';

export const EnrollMembersContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
    flex-wrap: wrap;
    max-width: 100%;
    @media (max-width: 768px) {
        width: 100%;
        justify-content: flex-start;
    }
`;

export const StyledDependentText = styled.div(
    (p) => `
    color: ${p.theme.colors.black};
    font-size: ${p.theme.fontSizes.body};
    line-height: 18px;
    font-weight: bold;
    margin-left: 15px;
    margin-top: 30px;
    display:flex;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
       margin: 25px 20px 15px 20px;
    }
`
);

export const StyledDependentSubText = styled.div(
    (p) => `
    color: ${p.theme.colors.mutedBG};
    font-size: ${p.theme.fontSizes.body};
    line-height: 17px;
    font-weight: 500;
    margin-left: 8px;
    @media (max-width: 768px) {
        margin: 0px;
        line-height: 20px;
    }
`
);

export const StyledParentTypeContainer = styled.div`
    display: flex;
    overflow-y: visible;
    overflow-x: auto;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-width: 100%;
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;
