import React from 'react';
import { StyledInput } from './styles';
import { IInput } from './types';
import { tickIcon } from '../../../../../../assets/img';
const Input: React.FunctionComponent<IInput> = ({
    maxLen,
    onChange,
    value,
    errorText,
    type,
    setBorder = false,
    showIcon,
    ...rest
}) => {
    return (
        <StyledInput
            {...rest}
            onChange={(e) => {
                if (!(maxLen && e.currentTarget.value.length > maxLen)) {
                    onChange(e);
                }
            }}
            onKeyDown={(e) => {
                if (e.key === '-' && type === 'number') {
                    e.preventDefault();
                    e.stopPropagation();
                    return;
                }
            }}
            value={value}
            isError={!!errorText.length}
            maxLength={maxLen ? maxLen : 524288}
            type={type ? type : 'text'}
            src={showIcon ? tickIcon : ''}
            showBorder={setBorder}
            autoFocus
        />
    );
};

export default Input;
