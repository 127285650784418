const isFeatureEnabled = (feature: Feature): boolean => {
    const env = process.env as Record<string, string>;
    if (Object.keys(env).includes(feature.toString())) {
        return JSON.parse(env[feature.toString()]) as boolean;
    }
    return false;
};
enum Feature {
    POLICY_START_DATE_RESOLVER = 'REACT_APP_POLICY_START_DATE_RESOLVER',
    ALLOW_DB_CALLS = 'REACT_APP_ALLOW_DB_CALLS',
    IAM_BE_API = 'REACT_APP_IAM_BE_API',
    DISABLE_CHECKOUT = 'REACT_APP_FEATURE_DISABLE_CHECKOUT'
}

export const isPolicyStartDateResolverEnabled = isFeatureEnabled(Feature.POLICY_START_DATE_RESOLVER);
export const isAllowDBCallsEnabled = isFeatureEnabled(Feature.ALLOW_DB_CALLS);
export const isIamAPIEnabled = isFeatureEnabled(Feature.IAM_BE_API);
export const isCheckoutDisabled = (policyId: string): boolean => {
    const env = process.env as Record<string, string>;
    if (Object.keys(env).includes(Feature.DISABLE_CHECKOUT)) {
        const accountsWithCheckoutDisabled = (JSON.parse(env[Feature.DISABLE_CHECKOUT]) as Array<string>) || [];
        if (accountsWithCheckoutDisabled.includes(policyId)) {
            return true;
        }
    }
    return false;
};
