import React from 'react';
import { DefaultInsuranceIcon } from '../../../assets/img';
import { StyledTableCell, StyledTableRow, StyledTableCellContent, StyledLogo, StyledPText } from './styles';
import { ITopUpTableHeader } from './types';
const TopUpTableHeader: React.FunctionComponent<ITopUpTableHeader> = ({
    isTopUpAvailable = false,
    topUpPolicyLogo,
    gmcLogo,
    gmcPolicyName,
    topUpPolicyName,
    membersCount,
    isGMCEnrolled
}) => {
    return (
        <StyledTableRow>
            <>
                <StyledTableCell>
                    Enrolled Members <br />({membersCount}/{membersCount} eligible members enrolled)
                </StyledTableCell>
                <StyledTableCell>Date of Birth</StyledTableCell>
                <StyledTableCell>Mobile</StyledTableCell>
                {!isGMCEnrolled && (
                    <StyledTableCell>
                        <StyledTableCellContent>
                            <StyledLogo src={gmcLogo ?? DefaultInsuranceIcon} />
                            <p>{gmcPolicyName ?? 'Group Medical Cover'}</p>
                        </StyledTableCellContent>
                    </StyledTableCell>
                )}
                {isTopUpAvailable && (
                    <StyledTableCell>
                        <StyledTableCellContent>
                            <StyledLogo src={topUpPolicyLogo ?? DefaultInsuranceIcon} />
                            <p>{topUpPolicyName ?? 'Super TopUp Policy'}</p>
                            <StyledPText>Family Top-up Policy</StyledPText>
                        </StyledTableCellContent>
                    </StyledTableCell>
                )}
            </>
        </StyledTableRow>
    );
};

export default TopUpTableHeader;
