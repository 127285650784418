import { Dispatch, SetStateAction } from 'react';
import { addNomineeAPI, deleteNomineeAPI, editNomineeAPI } from '../../../adapters';
import { parseResponse } from '../../../utils/common';
import { IToastContext } from '../../../utils/hooks/useToast';
import { NomineeData } from './types';

export enum MemberClickAction {
    EDIT,
    DELETE
}

const onMemberEdit = async (
    selectedNominee: NomineeData | undefined,
    nominee: NomineeData,
    userId: string,
    policyId: string,
    toast: IToastContext | null,
    toggleManageNomineeModalVisible: Dispatch<SetStateAction<boolean>>,
    setSelectedNominee: Dispatch<SetStateAction<NomineeData | undefined>>,
    refreshNominees: () => Promise<void>
): Promise<void> => {
    try {
        if (!selectedNominee || !selectedNominee.id) {
            return;
        }
        const [error] = await parseResponse(
            editNomineeAPI({
                id: selectedNominee.id,
                dob: nominee.dob,
                gender: nominee.gender,
                name: nominee.firstName + ' ' + nominee.lastName,
                percentage: nominee.percentage,
                relationship: nominee.relationship,
                policyId: policyId,
                userId
            })
        );
        if (error) {
            // showApiFailureToast(toast, error.toString());
            return;
        }
        toggleManageNomineeModalVisible(false);
        await refreshNominees();
        setSelectedNominee(undefined);
        toast?.displayToast(
            'success',
            'Nominee edited successfully',
            `${nominee.firstName} was successfully modified to the policy`
        );
    } catch (e) {
        toast?.displayToast('error', 'Something went wrong', (e as Error).message);
    }
};

export const onMemberAddUtil = async (
    selectedNominee: NomineeData | undefined,
    nominee: NomineeData,
    userId: string,
    policyId: string,
    toast: IToastContext | null,
    toggleManageNomineeModalVisible: Dispatch<SetStateAction<boolean>>,
    setSelectedNominee: Dispatch<SetStateAction<NomineeData | undefined>>,
    refreshNominees: () => Promise<void>
): Promise<void> => {
    try {
        if (selectedNominee) {
            await onMemberEdit(selectedNominee,
                nominee,
                userId,
                policyId,
                toast,
                toggleManageNomineeModalVisible,
                setSelectedNominee,
                refreshNominees
            );
            return;
        }
        await addNomineeAPI({
            dob: nominee.dob,
            gender: nominee.gender,
            name: nominee.firstName + ' ' + nominee.lastName,
            percentage: nominee.percentage,
            relationship: nominee.relationship,
            policyId,
            userId
        });
        await refreshNominees();
        toast?.displayToast(
            'success',
            'Nominee added successfully',
            `${nominee.firstName} was successfully added to the policy`
        );
    } catch (e) {
        toast?.displayToast('error', 'Something went wrong', (e as Error).message);
    }
};

export const onMemberDeleteUtil = async (
    selectedNominee: NomineeData | undefined,
    toast: IToastContext | null,
    toggleDeleteMemberModalVisible: Dispatch<SetStateAction<boolean>>,
    setSelectedNominee: Dispatch<SetStateAction<NomineeData | undefined>>,
    refreshNominees: () => Promise<void>
): Promise<void> => {
    try {
        if (!selectedNominee || !selectedNominee.id) {
            return;
        }
        const [error] = await parseResponse(deleteNomineeAPI(selectedNominee.id));
        if (error) {
            // showApiFailureToast(toast, error.toString());
            return;
        }
        toggleDeleteMemberModalVisible(false);
        await refreshNominees();
        setSelectedNominee(undefined);
        toast?.displayToast(
            'success',
            'Nominee deleted successfully',
            `${selectedNominee.firstName} was successfully deleted from the policy`
        );
    } catch (e) {
        toast?.displayToast('error', 'error', 'error');
    }
};

export const getPercentageConsumed = (nomineesData: NomineeData[]): number => {
    return nomineesData
        .map((v) => v.percentage)
        .reduce((accumulated, currentValue) => {
            return currentValue + accumulated;
        }, 0);
};
