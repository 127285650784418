import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyledErrorText, StyledTopMargin, StyledOAuthContainer } from './styles';
import { FirebaseCaptcha } from '../../../../../../adapters/provider';
import useFormInput from '../../../../../../utils/hooks/useFormInput';
import { Button, Input, Loader } from '../../atoms';
import { ILoginForm } from './types';
import { IOAuthType } from '../../../redux/types';
import { loginWithOAuth, clearUserError } from '../../../redux/slices/UserSlice';
import { LoginOAuth } from '..';

const LoginForm: React.FunctionComponent<ILoginForm> = ({
    showMobile,
    onSubmit,
    submitButtonId = '',
    loading = false,
    error = ''
}) => {
    const input = useFormInput('');
    const dispatch = useDispatch();
    const userData = useSelector((state: any) => state.user.userData);
    const oAuthLogin = (type: IOAuthType) => {
        dispatch(loginWithOAuth({ loginMethod: type }));
    };

    React.useEffect(() => {
        if (submitButtonId === 'otp-button') {
            window.recaptchaVerifier = new FirebaseCaptcha.RecaptchaVerifier('otp-button', {
                size: 'invisible'
            });
        }
    }, [FirebaseCaptcha.RecaptchaVerifier, submitButtonId]);
    const validateEmail = (emailTxt: string) => {
        dispatch(clearUserError());
        if (!emailTxt) {
            return null;
        }
        const regEx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        return regEx.test(emailTxt);
    };

    return (
        <>
            {showMobile ? (
                <Input
                    placeholder={'Mobile Number'}
                    {...input}
                    name={'phone'}
                    maxLen={showMobile ? 10 : undefined}
                    type={'number'}
                />
            ) : (
                <StyledOAuthContainer>
                    <StyledErrorText>{userData.error && userData.error.message}</StyledErrorText>
                    <LoginOAuth signInWithOAuth={oAuthLogin} />
                </StyledOAuthContainer>
            )}

            {!!error.length && <StyledErrorText>{error}</StyledErrorText>}
            <StyledTopMargin>
                <Button
                    id={submitButtonId}
                    type="primary"
                    disabled={showMobile ? !(input.value.length === 10) : !validateEmail(input.value)}
                    onClick={() => !loading && onSubmit(input.value)}
                    fullWidth={true}
                >
                    {loading ? <Loader inverted /> : showMobile ? 'Get OTP' : 'Verify'}
                </Button>
            </StyledTopMargin>
        </>
    );
};

export default LoginForm;
