import styled from 'styled-components';

export const StyledContainer = styled.div<{ $showBG: boolean; $errorBG: boolean; $color: boolean }>(
    (p) => `
    background-color: ${
    p.$showBG ? (p.$errorBG ? p.theme.colors.errorBG : p.theme.colors.secondaryBG) : p.theme.colors.loopEmerald
};
    color: ${p.$color ? p.theme.colors.white : p.theme.colors.mutedBG};
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-radius: 6px;
    font-size: ${p.theme.fontSizes.label};
    font-weight: 500;
    margin:${p.$color ? '0px 8px' : '20px 0px'};
    border-radius: 12px;

`
);
export const StyledImage = styled.img`
    margin-right: 10px;
`;
