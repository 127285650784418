import styled from 'styled-components';

export const StyledContainer = styled.div`
    padding: 50px 138px;
    flex-grow: 1;
    width: 100%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    overflow-y: scroll;
    @media (max-width: 1024px) {
        padding: 45px;
    }
    @media (max-width: 550px) {
        padding: 25px 0px;
    }
`;
export const StyledInsuranceContainer = styled.div`
    margin: 25px 0px;
`;
export const StyledEnrolmentText = styled.div<{ $isDone: boolean }>(
    (p) => `
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: ${p.theme.fontSizes.landing};
    color: ${p.theme.colors.black};
    font-weight: bold;
    ${p.$isDone ? 'margin-top: 70px;' : ''}
    @media (max-width: 768px) {
        justify-content: space-between;
        padding: 0px 20px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
    }
`
);
export const StyledSpan = styled.span`
    @media (max-width: 768px) {
        max-width 196px;
        font-size: ${(p) => p.theme.fontSizes.title};
        line-height: 32px;
    }
`;
export const StyledText = styled.div(
    (p) => `
    margin-top: 8px;
    font-size: ${p.theme.fontSizes.body};
    color: ${p.theme.colors.mutedBG};
    @media (max-width: 768px) {
        padding: 10px 20px;
        line-height: 20px;
    }
`
);

export const StyledNoPolicyText = styled.div((p)=>`
    text-align:center;
    font-weight:bold;
    margin-top: 8px;
    font-size: ${p.theme.fontSizes.mediumFont};
    color: ${p.theme.colors.mutedBG};
    @media (max-width: 768px) {
        padding: 10px 20px;
        line-height: 20px;
    }
`);
