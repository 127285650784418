import { ISuperTopUpDetails } from '../../redux/slices/EnrolmentSlice/types';
import { SEGMENT_ACTIONS } from '../constants/SegmentActionConstants';
type IPageAction = {
    name: string;
    properties?: Record<string, unknown> | undefined;
};
type IUserProperties = ({
    name,
    properties
}: {
    name: string;
    properties?: Record<string, unknown> | undefined;
}) => void;

export const getTrackClickConfig = (
    currentSelectedStep: number,
    currentPageAction: IPageAction,
    superTopUpAvailable: boolean,
    topUpAmount: { sumInsured: string },
    pathName: string
): IPageAction => {
    if (superTopUpAvailable && currentSelectedStep === 1) {
        const segmentAction = SEGMENT_ACTIONS.CLICK.PROCEED_TO_SELECT_SUPER_TOPUP;
        return {
            name: segmentAction.name,
            properties: {
                describe: segmentAction.properties?.describe || '',
                page_title: segmentAction.properties?.page_title,
                login_status: segmentAction.properties?.login_status,
                step: (segmentAction.properties?.step as (step: number) => number)(currentSelectedStep) || '',
                page_url: (currentPageAction.properties?.page_url as (path: string) => string)(pathName) || '',
                page_id: (currentPageAction.properties?.page_id as (path: string) => string)(pathName) || ''
            }
        };
    } else if (superTopUpAvailable && currentSelectedStep === 2) {
        const segmentAction = SEGMENT_ACTIONS.CLICK.ADD_TOPUP;
        return {
            name: segmentAction.name,
            properties: {
                describe: segmentAction.properties?.describe || '',
                user_feedback: (segmentAction.properties?.user_feedback as (feedback: string) => string)(
                    'SELECTED_TOP_UP'
                ),
                page_title: segmentAction.properties?.page_title || '',
                login_status: segmentAction.properties?.login_status || '',
                sum_insured: (segmentAction.properties?.sum_insured as (sumInsured: string) => string)(
                    topUpAmount?.sumInsured || ''
                ),
                step: (segmentAction.properties?.step as (step: number) => number)(currentSelectedStep) || '',
                page_url: (currentPageAction.properties?.page_url as (path: string) => string)(location.pathname) || '',
                page_id: (currentPageAction.properties?.page_id as (path: string) => string)(location.pathname) || ''
            }
        };
    } else {
        const segmentAction = SEGMENT_ACTIONS.CLICK.PROCEED_SUMMARY;
        return {
            name: segmentAction.name,
            properties: {
                describe: segmentAction.properties?.describe || '',
                page_title: segmentAction.properties?.page_title,
                login_status: segmentAction.properties?.login_status,
                step: (segmentAction.properties?.step as (step: number) => number)(currentSelectedStep) || '',
                page_url: (currentPageAction.properties?.page_url as (path: string) => string)(location.pathname) || '',
                page_id: (currentPageAction.properties?.page_id as (path: string) => string)(location.pathname) || '',
                topup_available: segmentAction.properties?.topup_available
            }
        };
    }
};

export const sendUserPropertiesToSegment = (
    trackUserProperties: IUserProperties,
    userProperties: Record<string, unknown> | undefined,
    checkSuperTopUpAvailable: ISuperTopUpDetails
): void => {
    if (!checkSuperTopUpAvailable.loading) {
        if (checkSuperTopUpAvailable.data?.status) {
            trackUserProperties({
                name: SEGMENT_ACTIONS.IDENTIFY.USER_LOGIN.name,
                properties: {
                    ...userProperties,
                    is_top_up_eligible: checkSuperTopUpAvailable.data?.status === 'NOT_AVAILABLE' ? 'No' : 'Yes'
                }
            });
        }
    }
};
