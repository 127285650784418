import styled from 'styled-components';
import theme from '../../../theme';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.white};
    width: 60%;
    padding: 30px;
    border-radius: 16px;
    @media only screen and (max-width: 768px) {
        /* padding: 30px 20px; */
    }
`;

export const StyledHeadingContainer = styled.div`
    display: flex;
    margin: 20px 0px;
`;
export const StyledHeadingImg = styled.img`
    width: 40px;
`;
export const StyledSectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    @media only screen and (max-width: 768px) {
        padding: 30px 20px;
        flex-direction: column;
    }
`;

export const StyledSectionHeading = styled.h1`
    font-size: ${theme.fontSizes.title};
    margin: 0px;
    @media only screen and (max-width: 768px) {
        font-size: ${theme.fontSizes.body};
        width: 100%;
    }
`;

export const StyledSectionSpan = styled.span`
    font-size: ${theme.fontSizes.body};
    margin-top: 18px;
    padding: 20px 0px;
    color: #667985;
    border-top: 1px solid #d4e3eb;
    border-bottom: 1px solid #d4e3eb;
    @media only screen and (max-width: 768px) {
        margin: 20px 0px;
        width: 100%;
    }
`;

export const StyledContainerSection = styled.div`
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 768px) {
        margin: 20px 0px;
        width: 100%;
    }
`;

export const StyledContainerLeftSection = styled.div`
    @media only screen and (max-width: 768px) {
        margin: 20px 0px;
        width: 100%;
    }
`;

export const StyledPolicySection = styled.div`
    display: flex;
    @media only screen and (max-width: 768px) {
        margin: 20px 0px;
        width: 100%;
    }
`;

export const StyledPolicyInfoSection = styled.div`
    display: flex;
    margin-left: 10px;
    align-items: center;
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;

export const StyledContainerRightSection = styled.div`
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: 768px) {
        margin: 20px 0px;
        width: 100%;
    }
`;
export const StyledContainerUL = styled.ul`
    display: flex;
    flex-direction: column;
    margin: 15px 0px 5px 0px;
    align-items: flex-start;
    padding: 0 12px;
`;

export const StyledContainerLI = styled.li(
    (p) => `
    color: #667985;
    text-align:center;
    font-size: ${p.theme.fontSizes.body};
`
);
export const StyledSectionLeft = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 16px 16px 0px 0px;
    background-color: ${theme.colors.lightGrey};
    padding: 20px 32px;
    width: 95%;
    @media only screen and (max-width: 768px) {
        padding: 30px 20px;
        width: 100%;
    }
`;

export const StyledHeaderSection = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 10px;
`;

export const StyledSectionRightSpan = styled.span`
    margin-bottom: 5px;
    color: ${theme.colors.mutedBG};
`;
export const StyledButtonContainer = styled.div`
    display: flex;
    cursor: pointer;
    background-color: #40839b;
    width: 107%;
    border-radius: 0px 0px 16px 16px;
    justify-content: center;
`;

export const StyledButton = styled.button(
    (p) => `
    padding: 19px 10px;
    cursor: pointer;
    background-color: #40839b;
    color: ${p.theme.colors.white};
    font-weight: 700;
    border-radius: 64px;
    font-size: ${p.theme.fontSizes.body};
    border: 0px;
    cursor:pointer;
`
);
