import React from 'react';
import { StyledSelect, StyledLabel, StyledInputGroup, StyledSpan } from './styles';
import { Input } from '../../atoms';
import { ChevronDownIcon } from '../../../assets/img';
import { IDynamicField } from './types';
import useFormInput from '../../../utils/hooks/useFormInput';

const DynamicField: React.FunctionComponent<IDynamicField> = ({
    field,
    index,
    setDynamicField,
    dynamicField,
    dynamicFormError
}) => {
    const fieldType = useFormInput('');
    const [selectType, setSelectType] = React.useState('');
    const [fieldError, setFieldError] = React.useState(false);
    React.useEffect(() => {
        if (dynamicFormError[index] && dynamicFormError[index][(field as any).name]) {
            setFieldError(true);
        }
    }, [dynamicFormError]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const pattern = new RegExp(field.regex);
        if (!pattern?.test(e.target.value)) {
            setFieldError(true);
        } else {
            setFieldError(false);
        }
        setDynamicField({
            ...dynamicField,
            [field.name || '']: e.target.value
        });
    };
    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setDynamicField({
            ...dynamicField,
            [field.name || '']: e.target.value
        });
        setSelectType(e.target.value);
        if (field.isMandatory) {
            if (e.target.value) {
                setFieldError(false);
            } else {
                setFieldError(true);
            }
        }
    };

    return (
        <>
            {field.type === 'string' ? (
                <StyledInputGroup>
                    <StyledLabel>
                        {field.label} {field.isMandatory && <StyledSpan>*</StyledSpan>}
                    </StyledLabel>
                    <Input
                        name="text"
                        placeholder="Enter"
                        {...fieldType}
                        onChange={(e) => {
                            handleChange(e);
                            fieldType.onChange(e);
                        }}
                        errorText={fieldError ? fieldError.toString() : ''}
                    />
                </StyledInputGroup>
            ) : (
                <StyledInputGroup>
                    <StyledLabel>
                        {field.label} {field.isMandatory && <StyledSpan>*</StyledSpan>}{' '}
                    </StyledLabel>
                    <StyledSelect
                        $error={fieldError}
                        $color={false}
                        $src={ChevronDownIcon}
                        name={field.name}
                        value={selectType}
                        onChange={(e) => {
                            handleSelectChange(e);
                        }}
                    >
                        <option value="">Select</option>
                        {field.options?.map((option, index) => {
                            return (
                                <option key={index} value={option.value}>
                                    {option.name}
                                </option>
                            );
                        })}
                    </StyledSelect>
                </StyledInputGroup>
            )}
        </>
    );
};

export default DynamicField;
