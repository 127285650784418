import styled from 'styled-components';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    @media (max-width: 768px) {
        overflow-y: auto;
        margin: 30px 0px;
    }
`;

export const StyledBtnWrapper = styled.div`
    display: flex;
    width: 48%;
`;

export const StyledContainerWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0px 20px 20px 20px;
`;
export const StyledFeatureWrapper = styled.div`
    width: 96%;
    /* margin: 0px 20px 20px 20px; */
    padding: 10px;
    background-color: ${(p) => p.theme.colors.lightGreen};
    display: flex;
    border-radius: 16px;
    justify-content: space-between;
`;

export const StyledBtnContainerWrapper = styled.div`
    margin-bottom: 20px;
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

export const StyledContainerUL = styled.ul`
    display: flex;
    align-items: center;
    padding: 5px;
`;

export const StyledRowAlignment = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

export const StyledChatSpan = styled.span`
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-color: ${(p) => p.theme.colors.loopEmerald};
`;
