/* eslint-disable max-len */
import React from 'react';
import {
    StyledCardHeaderErrorText,
    StyledInsuranceCardHeaderContainer,
    StyledInsuranceCardHeaderContent,
    StyledInsuranceCardHeaderHeading
} from './styles';
import { IEnrolmentDueDateDetail } from './types';

const EnrolmentDueDateDetail: React.FunctionComponent<IEnrolmentDueDateDetail> = ({
    displayForEnrolment = false,
    enrolmentDueDate,
    daysLeft,
    showEnrolmentDate
}) => {
    return showEnrolmentDate ? (
        <StyledInsuranceCardHeaderContainer>
            <StyledInsuranceCardHeaderHeading>ENROLMENT DUE DATE</StyledInsuranceCardHeaderHeading>
            <StyledInsuranceCardHeaderContent $displayForEnrolment={displayForEnrolment}>
                {enrolmentDueDate}
                {daysLeft.length !== 0 && (
                    <StyledCardHeaderErrorText $displayForEnrolment={displayForEnrolment}>
                        {daysLeft}
                    </StyledCardHeaderErrorText>
                )}
            </StyledInsuranceCardHeaderContent>
        </StyledInsuranceCardHeaderContainer>
    ) : null;
};

export default EnrolmentDueDateDetail;
