import React from 'react';
import {
    StyledContainer,
    StyledContent,
    StyledContentContainer,
    StyledContentText,
    StyledDeleteImage,
    StyledEditImage,
    StyledLabelText,
    StyledNameContainer,
    AbsoluteContainer,
    MarginContainer,
    StyledAlignContainer
} from './styles';
import { NameIcon } from '../../atoms';
import { EditIcon, DeleteIcon } from '../../../assets/img';
import { IMemberCard } from './types';

const MemberCard: React.FunctionComponent<IMemberCard> = ({
    firstName,
    lastName,
    relationship,
    doB,
    gender,
    mobile,
    onMemberDelete,
    onMemberEdit,
    color
}) => {
    return (
        <StyledContainer data-automationid={`member-card-${relationship}`}>
            <AbsoluteContainer>
                <StyledEditImage onClick={onMemberEdit} src={EditIcon} />
                {relationship?.toLowerCase() !== 'self' && (
                    <StyledDeleteImage onClick={onMemberDelete} src={DeleteIcon} />
                )}
            </AbsoluteContainer>
            <StyledNameContainer>
                <MarginContainer>
                    <NameIcon firstName={firstName} lastName={lastName} color={color} />
                </MarginContainer>
                <StyledContent>
                    <StyledLabelText>Name</StyledLabelText>
                    <StyledContentText>
                        <b>{firstName + ' ' + lastName}</b>
                    </StyledContentText>
                </StyledContent>
            </StyledNameContainer>
            <StyledContentContainer>
                <StyledAlignContainer>
                    <StyledContent>
                        <StyledLabelText>Relationship</StyledLabelText>
                        <StyledContentText>{relationship}</StyledContentText>
                    </StyledContent>
                    <StyledContent>
                        <StyledLabelText>DOB</StyledLabelText>
                        <StyledContentText>{doB}</StyledContentText>
                    </StyledContent>
                </StyledAlignContainer>
                <StyledAlignContainer>
                    <StyledContent>
                        <StyledLabelText>Gender</StyledLabelText>
                        <StyledContentText>{gender}</StyledContentText>
                    </StyledContent>
                    <StyledContent>
                        <StyledLabelText>Mobile</StyledLabelText>
                        <StyledContentText>{mobile}</StyledContentText>
                    </StyledContent>
                </StyledAlignContainer>
            </StyledContentContainer>
        </StyledContainer>
    );
};

export default MemberCard;
