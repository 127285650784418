import React from 'react';
import { CoveredMemberContainer, MemberNameContainer, StyledRelationText, StyledNameText } from './styles';
import { IMemberIdentity } from './types';
import { NameIcon } from '..';
import theme, { colorLibrary } from '../../../theme';
import { capitalizeFirstLetter } from '../../../utils/common';
import { TextView } from '../TextView';
const MemberIdentity: React.FunctionComponent<IMemberIdentity> = ({
    firstName,
    lastName,
    relationship,
    index,
    bgColor,
    age
}) => {
    return (
        <CoveredMemberContainer $color={bgColor || ''}>
            <NameIcon name={firstName + ' ' + lastName} color={colorLibrary[index % colorLibrary.length]} />
            <MemberNameContainer>
                <StyledNameText>
                    {capitalizeFirstLetter(firstName || '') + ' ' + capitalizeFirstLetter(lastName || '')}
                </StyledNameText>
                <span>
                    <StyledRelationText>{capitalizeFirstLetter(relationship || '')}</StyledRelationText>
                    <TextView fontSize="12px" color={theme.colors.beyondGrey}>
                        {', '}{age ?? ''} {' years'}
                    </TextView>
                </span>
            </MemberNameContainer>
        </CoveredMemberContainer>
    );
};

export default MemberIdentity;
