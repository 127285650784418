import styled from 'styled-components';

export const FooterContainer = styled.div`
    width: 100%;
    position: fixed;
    bottom: 0;
`;
export const ErrorContainer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 100px;
`;
export const ErrorText = styled.div(
    (p) => `
    color: ${p.theme.colors.error};
    font-size: ${p.theme.fontSizes.label};
    font-weight: 600;
    height: 20px;
    line-height: 20px;
`
);
export const StyledContainer = styled.div`
    max-width: 100vw;
    background-color: ${(p) => p.theme.colors.white};
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    padding: 11px 70px;
    flex-wrap: wrap;
    box-shadow: 0px -2px 14px rgba(168, 196, 206, 0.2);
    @media (max-width: 768px) {
        padding: 25px;
        position: relative;
    }
`;
export const PremiumBreakdownContainer = styled.div(
    (p) => `
    background-color: ${p.theme.colors.secondary};
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    padding: 10px 15px;
    // min-width: 475px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 768px) {
        min-width: 0;
        width: 100%;
        max-width: 100%;
    }
`
);
export const StyledPartPremiumContainer = styled.div(
    (p) => `
    color: ${p.theme.colors.mutedBG};
    font-size: ${p.theme.fontSizes.label};
    font-weight: 600;
`
);
export const StyledPartAmountText = styled.div(
    (p) => `
    font-weight: bold;
    color: ${p.theme.colors.black};
    font-size: ${p.theme.fontSizes.body};
    line-height: 18px;
    margin-top: 5px;
`
);
export const StyledAnnualPremiumContainer = styled.div(
    (p) => `
    color: ${p.theme.colors.mutedBG};
    font-size: ${p.theme.fontSizes.body};
    font-weight: bold;
    line-height: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`
);
export const StyledAnnualAmountText = styled.div(
    (p) => `
    font-weight: bold;
    font-size: ${p.theme.fontSizes.title};
    color: ${p.theme.colors.black};
    line-height: 18px;
`
);
export const AnnualPremiumText = styled.div`
    margin-right: 20px;
    padding: 12px;
    @media (max-width: 768px) {
        margin-right: 10px;
    }
`;
export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 310px;
    @media (max-width: 768px) {
        width: 100%;
        margin-top: 25px;
    }
`;
export const StyledBtnWrapper = styled.div`
    min-width: 150px;
    @media (max-width: 768px) {
        display: none;
    }
`;
