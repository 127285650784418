export const MAIN_ROUTES = {
    login: '/login',
    app: '/app'
};

export const INTERNAL_ROUTES = {
    welcome: MAIN_ROUTES.app + '/welcome',
    home: MAIN_ROUTES.app + '/',
    dashboard: MAIN_ROUTES.app + '/dashboard',
    enrolment: MAIN_ROUTES.app + '/enrolment',
    topup: MAIN_ROUTES.app + '/familytopups',
    summary: MAIN_ROUTES.app + '/summary',
    checkout: MAIN_ROUTES.app + '/checkout',
    confirm: MAIN_ROUTES.app + '/confirm'
};
