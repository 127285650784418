import React from 'react';
import { IDynamicForms } from './types';
import { DynamicField } from '../../atoms';
import { StyleFormWrapper } from './styles';

const DynamicForms: React.FunctionComponent<IDynamicForms> = ({
    customFormData,
    setDynamicField,
    dynamicField,
    dynamicFormError,
    setDynamicFormError
}) => (
    <StyleFormWrapper>
        {(customFormData || []).map((field, index) => {
            return (
                <DynamicField
                    key={index}
                    field={field}
                    index={index}
                    setDynamicField={setDynamicField}
                    dynamicField={dynamicField}
                    dynamicFormError={dynamicFormError}
                    setDynamicFormError={setDynamicFormError}
                />
            );
        })}
    </StyleFormWrapper>
);

export default DynamicForms;
