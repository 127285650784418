/* eslint-disable camelcase */
import { saveTopUpFeedback } from '../../../topup-src/adapters';
import { ISegmentAct, SEGMENT_ACTIONS } from '../../../utils/constants/SegmentActionConstants';

export const handleSelectTopUpUtil = (
    trackClick: ({ name, properties }: {
        name: string;
        properties?: Record<string, unknown> | undefined;
    }) => void,
    currentPageAction: ISegmentAct,
    selectedSI: number | undefined,
    pageTitle: string | undefined
): void => {
    const segmentAction = SEGMENT_ACTIONS.CLICK.SELECT_TOPUP_IN_MODAL;
    const { describe, login_status, page_title, sum_insured } = segmentAction.properties || {};
    const { page_url, page_id } = currentPageAction.properties || {};

    trackClick({
        name: segmentAction.name,
        properties: {
            describe: describe || '',
            login_status: login_status,
            page_title: (page_title as (pageTitle: string) => string)(pageTitle || ''),
            sum_insured: (sum_insured as (sumInsured: number) => number)(selectedSI || 0),
            page_url: (page_url as (path: string) => string)(location.pathname) || '',
            page_id: (page_id as (path: string) => string)(location.pathname) || ''
        }
    });
};

export const saveTopUpFeedbackUtil = (
    feedback: string,
    userId: string
): void => {
    saveTopUpFeedback({
        feedback: feedback,
        userId: userId,
        type: 'topup'
    });
};

export const handleUnSelectTopUpUtil = (
    trackClick: ({ name, properties }: {
        name: string;
        properties?: Record<string, unknown> | undefined;
    }) => void,
    currentPageAction: ISegmentAct,
    selectedSI: number | undefined,
    pageTitle: string | undefined,
    feedback: string
): void => {
    const segmentAction = SEGMENT_ACTIONS.CLICK.UNSELECT_TOPUP_IN_MODAL;
    const { user_feedback, login_status, page_title, sum_insured } = segmentAction.properties || {};
    const { page_url, page_id } = currentPageAction.properties || {};

    trackClick({
        name: segmentAction.name,
        properties: {
            user_feedback: (user_feedback as (feedback: string) => string)(feedback),
            login_status: login_status,
            page_title: (page_title as (pageTitle: string) => string)(pageTitle || ''),
            sum_insured: (sum_insured as (sumInsured: number) => number)(selectedSI || 0),
            page_url: (page_url as (path: string) => string)(location.pathname) || '',
            page_id: (page_id as (path: string) => string)(location.pathname) || ''
        }
    });
};

export const handleChatCTAClickUtil = (
    trackClick: ({ name, properties }: {
        name: string;
        properties?: Record<string, unknown> | undefined;
    }) => void,
    currentPageAction: ISegmentAct
): void => {
    const segmentAction = SEGMENT_ACTIONS.CLICK.CHAT_WITH_US;
    const { login_status, page_title } = segmentAction.properties || {};
    const { page_url, page_id } = currentPageAction.properties || {};

    trackClick({
        name: segmentAction.name,
        properties: {
            page_title: page_title,
            login_status: login_status,
            page_url: (page_url as (path: string) => string)(location.pathname) || '',
            page_id: (page_id as (path: string) => string)(location.pathname) || ''
        }
    });
};
