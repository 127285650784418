import useFormInput from '../../utils/hooks/useFormInput';
import {
    IUseMobileNumberValidationFunctionReturnType,
    IUseMobileNumberValidationType
} from '../containers/ManageMemberModal/types';

export const useMobileNumberValidation = (
    formMobileNumber: string | undefined
): IUseMobileNumberValidationType => {
    const mobileNumber = useFormInput(formMobileNumber ? formMobileNumber : '');

    const validateMobileNumber = (): IUseMobileNumberValidationFunctionReturnType => {
        if (mobileNumber.value.length) {
            return {
                mobileNumberError: mobileNumber.errorText,
                mobileNumberValidation: mobileNumber.finalValidationCheck()
            };
        }
        return { mobileNumberError: '', mobileNumberValidation: true };
    };

    return {
        mobileNumber,
        validateMobileNumber
    };
};
