import React from 'react';
import { StyledContainer, StyledLabel } from './styles';
import { ICountryCodeCard } from './types';
const CountryCodeCard: React.FunctionComponent<ICountryCodeCard> = ({ label, width, margin }) => {
    return (
        <StyledContainer $width={width} $margin={margin}>
            <StyledLabel>{label}</StyledLabel>
        </StyledContainer>
    );
};

export default CountryCodeCard;
