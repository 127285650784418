import styled from 'styled-components';

export const StyledTopMargin = styled.div`
    width: 100%;
    margin-top: 35px;
`;

export const StyledOAuthContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    margin-top: 50px;
    @media (max-width: 768px) {
        max-width: 100%;
    }
`;

export const StyledErrorText = styled.span`
    color: ${(p) => p.theme.colors.error};
    margin: 15px 5px;
    font-size: ${(p) => p.theme.fontSizes.label};
`;
