import React, { ChangeEvent } from 'react';
import { Input, Loader, Modal } from '../../atoms';
import { IGenderType, IManageMemberModal } from './types';
import { ChevronDownIcon } from '../../../assets/img';
import { useDispatch, useSelector } from 'react-redux';
import { resetCrudErrors } from '../../../redux/slices/EnrolmentSlice';
import { ReduxState } from '../../../redux';
import { DynamicField } from '../DynamicForms/types';
import { ISelectedRelation } from '../../../redux/slices/ApplicationStateSlice/types';
import {
    StyledContainer,
    SaveButton,
    StyledErrorText,
    StyledInputGroup,
    StyledLabel,
    StyledRow,
    StyledRowButtons,
    StyledSelect,
    StyledSelectOption,
    StyledHeading,
    StyledSpan,
    StyledCountryCode,
    StyledMobileWrapper,
    StyledErrorMsg,
    StyledDividerContainer
} from './styles';
import DynamicForms from '../DynamicForms';
import { Divider } from '../../atoms';
import { checkRelationship, getAllowedDependents } from './ManageMemberService';
import {
    getDependentUtil,
    getNewDependentUtil,
    getPersistedFormDataIfExistUtil,
    handleDependentsUtil,
    handleDynamicFormErrorsUtil,
    handleExistingDependentPresentUtil,
    parseError,
    performDynamicErrorCheckUtil,
    performFieldValidationUtil
} from './utils';
import { addDependent, editMember, enrolSelectedUserToPolicy } from '../../../redux/slices/EnrolmentSlice/thunks';
import { IPreValidation } from '../../../redux/slices/EnrolmentSlice/types';
import { useNameValidation } from '../../hooks/useNameValidation';
import { useMobileNumberValidation } from '../../hooks/useMobileNumberValidation';
import { useDoBValidation } from '../../hooks/useDoBValidation';
import { IDependent } from '../../../redux/slices/PolicyListSlice/types';
import DatePicker from 'react-date-picker';

const ManageMemberModal: React.FunctionComponent<IManageMemberModal> = ({
    isVisible,
    setIsVisible,
    isEditMode = false,
    existingDependents,
    dependentId = '',
    selectedRelationship,
    selectedDependent,
    ...formValues
}) => {
    const dispatch = useDispatch();
    const userId = useSelector((state: ReduxState) => state.user?.userData?.data?.userId || '');
    const selfEmployeeId = useSelector((state: ReduxState) => state.user?.userData?.data?.employeeId || '');
    const enrolmentDueDate = useSelector(
        (state: ReduxState) => state.enrolment.currentlySelectedPolicy?.enrolmentDueDate
    );
    const selfEmail = useSelector((state: ReduxState) => state.user?.userData?.data?.email || '');
    const loading = useSelector((state: ReduxState) => state.enrolment.crudDependents?.loading);
    const policyId = useSelector((state: ReduxState) => state.enrolment.currentlySelectedPolicy?.policyId);
    const companyId = useSelector((state: ReduxState) => state.user?.userData?.data?.employer);
    const dependentList = useSelector((state: ReduxState) => state.policyList?.allDependents);
    const customFormVal = useSelector((state: ReduxState) => state.policyList?.companyDetails);
    const preValidations = useSelector((state: ReduxState) => state.enrolment.policyDetails);
    const error = useSelector((state: ReduxState) => state.enrolment.crudDependents.error);
    const { name } = useNameValidation(formValues.lastName);
    const {
        mobileNumber,
        validateMobileNumber
    } = useMobileNumberValidation(formValues.mobileNumber);
    const { doB, setdoB, validateDoB } = useDoBValidation(formValues.doB);
    const [formError, setFormError] = React.useState('');
    const [dynamicFormError, setDynamicFormError] = React.useState<Record<string, boolean>[]>([]);
    const [doBError, setdoBError] = React.useState(false);
    const [gender, setGender] = React.useState<IGenderType>(formValues.gender ? formValues.gender : '');
    const [genderError, setGenderError] = React.useState(false);
    const [existingDependent, setExistingDependent] = React.useState('');
    const [dynamicField, setDynamicField] = React.useState<DynamicField>({});
    const [displayRelationOptions, setDisplayRelationOptions] = React.useState(true);
    const [displayFormFields, setDisplayFormFields] = React.useState(true);
    const [formErrors, setFormErrors] = React.useState<Record<string, string>[]>();
    const [relationshipError, setRelationshipError] = React.useState(false);
    const [relationshipValues, setRelationshipValues] = React.useState([]);

    const [relationship, setRelationship] = React.useState<ISelectedRelation>('');

    const allowedDependents = getAllowedDependents(dependentList, existingDependents);

    React.useEffect((): void => {
        const relationshipArray: string[] = [];
        if (selectedDependent === 'Parents (including in-laws)') {
            relationshipArray.push('Parent', 'Parent-in-law');
            setRelationshipValues(relationshipArray as any);
        } else {
            relationshipArray.push(selectedRelationship);
            setRelationshipValues(relationshipArray as any);
            setRelationship(selectedRelationship);
        }
    }, [selectedDependent, selectedRelationship]);

    React.useEffect((): void => {
        if (error?.message) {
            try {
                setFormError(parseError(error?.message));
            } catch (e) {
                setFormErrors([{ field: 'Error', message: error?.message || '' }]);
            }
        }
    }, [error?.message]);

    React.useEffect((): void => {
        if (selectedRelationship) {
            const hasDependent = handleDependentsUtil(selectedRelationship, allowedDependents);
            if (typeof hasDependent === 'undefined') {
                setDisplayRelationOptions(false);
                setDisplayFormFields(true);
            } else {
                setDisplayRelationOptions(true);
            }
        }
    }, [selectedRelationship, allowedDependents]);

    // below loads the data into forms when it is in edit mode
    React.useEffect((): (() => void) | undefined =>
        getPersistedFormDataIfExistUtil(
            isEditMode,
            formValues,
            name.firstName,
            name.lastName,
            mobileNumber,
            setdoB,
            setGender
        )
    , []);

    const handleRelationSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setExistingDependent(e.target.value.trim() as any);
        (e.target.value) ? setDisplayFormFields(false) : setDisplayFormFields(true);
    };

    const handleRelationshipSelectChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        setRelationship(e.target.value.trim() as any);
        setRelationshipError(false);
    };

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        setGender(e.target.value.trim() as any);
        setGenderError(false);
    };

    const handleDOBChange = (date: Date) => {
        setdoB(date);
        const fieldValidation: IPreValidation | undefined =
            performFieldValidationUtil(preValidations, relationship, selectedRelationship);
        const dobErrorOnSubmit = validateDoB(fieldValidation, date);
        setdoBError(!!dobErrorOnSubmit);
        setFormError(dobErrorOnSubmit);
    };

    const updateDependent = (isEditMode: boolean) => {
        if (relationship === '') return;
        return isEditMode ?
            editMember({
                dependent: getDependentUtil(doB, gender, mobileNumber, relationship, name, dependentId),
                selfId: userId,
                policyId: policyId || '',
                companyId,
                selfEmail,
                enrolmentDueDate: enrolmentDueDate ?
                    new Date(enrolmentDueDate * 1000) :
                    new Date()
            }) :
            addDependent({
                dependent: getNewDependentUtil(doB, gender, mobileNumber, relationship, name),
                selfEmployeeId,
                policyId: policyId || '',
                dynamicFields: dynamicField || { '': '' },
                companyId,
                selfId: userId
            });
    };

    const validateForm = () => {
        if (doBError) return;

        dispatch(resetCrudErrors());
        const dynamicFieldValidations: Record<string, boolean>[] =
            handleDynamicFormErrorsUtil(customFormVal, dynamicField);
        setDynamicFormError(dynamicFieldValidations);

        if (existingDependent) {
            const selectedDependent: IDependent | undefined =
                handleExistingDependentPresentUtil(dependentList, existingDependent);
            if (selectedDependent) {
                dispatch(
                    enrolSelectedUserToPolicy({
                        selfId: userId,
                        userId: selectedDependent.userId,
                        policyId: policyId || '',
                        dependentDetails: selectedDependent,
                        companyId
                    })
                );
            }
        } else {
            const { firstNameValidation, lastNameValidation } = name.validateName();

            const { mobileNumberError, mobileNumberValidation } = validateMobileNumber();
            if (mobileNumber.value.length) {
                setFormError(mobileNumberError);
            }

            const dynamicErrorCheck = performDynamicErrorCheckUtil(dynamicFieldValidations);

            if (
                relationship &&
                gender.length &&
                doB &&
                firstNameValidation &&
                lastNameValidation &&
                mobileNumberValidation &&
                !dynamicErrorCheck &&
                !doBError
            ) {
                setFormError('');
                setGenderError(false);
                setRelationshipError(false);
                setdoBError(false);
                dispatch(updateDependent(isEditMode));
                return;
            } else {
                setGenderError(!gender.length);
                setRelationshipError(!relationship.length);
            }
        }
    };

    return (
        <Modal
            title={isEditMode ? 'Edit ' + selectedRelationship : 'Add ' + selectedRelationship}
            subTitle={
                isEditMode ?
                    'The edited information for this member will be reflected in all policies.' :
                    displayRelationOptions ?
                        `Select a previously added ${selectedRelationship} or add another ${selectedRelationship}` :
                        ''
            }
            isVisible={isVisible}
            setIsVisible={setIsVisible}
        >
            <StyledContainer>
                {displayRelationOptions && (
                    <>
                        <StyledRow>
                            <StyledSelectOption>
                                <StyledHeading>Select a previously added member</StyledHeading>
                                <StyledSelect
                                    $error={false}
                                    $color={existingDependent === ''}
                                    $src={ChevronDownIcon}
                                    name="existingDependent"
                                    value={existingDependent}
                                    onChange={handleRelationSelectChange}
                                >
                                    <option value="">Select</option>
                                    {allowedDependents?.map((dependent, index) => {
                                        if (
                                            checkRelationship(selectedRelationship, dependent)
                                        ) {
                                            return (
                                                <option key={index} value={dependent.userId}>
                                                    {dependent.firstName + ' ' + dependent.lastName}
                                                </option>
                                            );
                                        }
                                    })}
                                </StyledSelect>
                            </StyledSelectOption>
                        </StyledRow>
                        {displayFormFields ? (
                            <>
                                <StyledDividerContainer>
                                    <Divider>OR</Divider>
                                </StyledDividerContainer>
                                <StyledRow>
                                    <StyledHeading>Add a new member</StyledHeading>
                                </StyledRow>
                            </>
                        ) : null}
                    </>
                )}
                {displayFormFields ? (
                    <>
                        <StyledRow>
                            <StyledInputGroup data-automationid="first-name-field">
                                <StyledLabel>
                                    First Name <StyledSpan>*</StyledSpan>
                                </StyledLabel>
                                <Input name="characters" placeholder="Enter" {...name.firstName} />
                            </StyledInputGroup>
                            <StyledInputGroup data-automationid="last-name-field">
                                <StyledLabel>
                                    Last Name <StyledSpan>*</StyledSpan>
                                </StyledLabel>
                                <Input name="characters" placeholder="Enter" {...name.lastName} />
                            </StyledInputGroup>
                        </StyledRow>
                        <StyledRow>
                            <StyledInputGroup data-automationid="relationship-field">
                                <StyledLabel>
                                    Relationship <StyledSpan>*</StyledSpan>
                                </StyledLabel>
                                <StyledSelect
                                    $error={relationshipError}
                                    $color={false}
                                    $src={ChevronDownIcon}
                                    name="relationship"
                                    value={relationship}
                                    disabled={relationshipValues?.length > 1 ? false : true}
                                    onChange={handleRelationshipSelectChange}
                                >
                                    <option value="">Select</option>
                                    {relationshipValues.map((dependent, index) => {
                                        return (
                                            <option key={index} value={dependent}>
                                                {dependent}
                                            </option>
                                        );
                                    })}
                                </StyledSelect>
                            </StyledInputGroup>

                            <StyledInputGroup data-automationid="dob-field">
                                <StyledLabel>
                                    Date of Birth <StyledSpan>*</StyledSpan>
                                </StyledLabel>
                                <DatePicker
                                    onChange={(date: Date): void => handleDOBChange(date)}
                                    className={`form-control date-input leadpage-input ${
                                        doBError ? 'date-picker-error' : ''
                                    }`}
                                    name="dateOfBirth"
                                    value={doB}
                                    // format={'dd-MM-y'}
                                    maxDate={new Date()}
                                    minDate={new Date('1900-01-01')}
                                    clearIcon={null}
                                />
                            </StyledInputGroup>
                        </StyledRow>
                        <StyledRow>
                            <StyledInputGroup data-automationid="gender-field">
                                <StyledLabel>
                                    Gender <StyledSpan>*</StyledSpan>
                                </StyledLabel>
                                <StyledSelect
                                    $error={genderError}
                                    $color={gender === ''}
                                    $src={ChevronDownIcon}
                                    name="gender"
                                    value={gender}
                                    onChange={handleSelectChange}
                                >
                                    <option value="">Select</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                </StyledSelect>
                            </StyledInputGroup>
                            <StyledInputGroup data-automationid="mobile-number-field">
                                <StyledLabel>
                                    Mobile Number
                                </StyledLabel>
                                <StyledMobileWrapper>
                                    <StyledCountryCode>+91</StyledCountryCode>
                                    <Input
                                        name="phone"
                                        type='tel'
                                        maxLen={10}
                                        placeholder="Enter"
                                        {...mobileNumber}
                                    />
                                </StyledMobileWrapper>
                            </StyledInputGroup>
                        </StyledRow>
                        <DynamicForms
                            customFormData={customFormVal?.data?.dynamicFormFields || []}
                            setDynamicField={(field: DynamicField) => setDynamicField(field)}
                            dynamicField={dynamicField}
                            dynamicFormError={dynamicFormError}
                            setDynamicFormError={(field: any) => setDynamicFormError(field)}
                        />
                    </>
                ) : null}
            </StyledContainer>
            <StyledRowButtons>
                <StyledErrorText>{formError}</StyledErrorText>
                <StyledErrorMsg>
                    {(formErrors?.length &&
                        formErrors?.map((error, index) => {
                            return (
                                <p key={index}>
                                    {(error as any).field} - {(error as any)?.message}
                                </p>
                            );
                        })) ||
                        null}
                </StyledErrorMsg>
                <SaveButton
                    data-automationid={`${isEditMode?'edit':'add'}-${selectedRelationship}-button`}
                    onClick={() => (loading ? null : validateForm())}>
                    {loading ? (
                        <Loader inverted={true} />
                    ) : isEditMode ? (
                        `Edit ${selectedRelationship}`
                    ) : (
                        `Add ${selectedRelationship}`
                    )}
                </SaveButton>
            </StyledRowButtons>
        </Modal>
    );
};

export default ManageMemberModal;
