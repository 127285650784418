import React from 'react';
import { IToolTipView } from './types';
import { TooltipMainContainer, InfoTagImage, StyledText, CloseImage } from './styles';
import { CloseIcon, ToastSuccess } from '../../../topup-src/assets/img';
import { useDispatch } from 'react-redux';
import { setShowCallbackToolTip } from '../../../topup-src/redux/slices/ApplicationStateSlice';
const Tooltip: React.FunctionComponent<IToolTipView> = ({ Text }) => {
    const dispatch = useDispatch();
    const hideTooltip = () => {
        dispatch(setShowCallbackToolTip({ showCallbackToolTip: false }));
    };
    return (
        <TooltipMainContainer>
            <InfoTagImage src={ToastSuccess} />
            <StyledText>{Text}</StyledText>
            <CloseImage src={CloseIcon} onClick={() => hideTooltip()} />
        </TooltipMainContainer>
    );
};
export default Tooltip;
