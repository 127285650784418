import React from 'react';
import { StyledContainer, StyledRowContainer, IdentityWrapper } from './styles';
import { InsuranceIdentity } from '../../atoms';
import InsuranceCardDetail from '../../atoms/InsuranceCardDetail';
import { IInsuranceCardHeader } from './types';
import { EnrolmentValidityDetail } from '../../atoms';

const InsuranceCardHeader: React.FunctionComponent<IInsuranceCardHeader> = ({
    policyImage = null,
    displayForEnrolment = false,
    isEnrolPending,
    insuranceName,
    insuranceCategory,
    superTopupStatus,
    policyEndDate,
    annualPremium,
    enrolmentStatus,
    onEnrolNowClick = () => null,
    policyId,
    ...rest
}) => {
    return (
        <StyledContainer>
            <StyledRowContainer $isEnroled={enrolmentStatus?.toLowerCase() === 'enroled'}>
                <IdentityWrapper>
                    <InsuranceIdentity
                        policyImage={policyImage}
                        policyName={insuranceName}
                        policyCategory={insuranceCategory}
                        familyStructure={rest.dependentsInsured}
                    />
                </IdentityWrapper>
                <EnrolmentValidityDetail
                    displayForEnrolment={displayForEnrolment}
                    isEnrolPending={isEnrolPending}
                    insuranceCategory={insuranceCategory}
                    superTopupStatus={superTopupStatus}
                    onEnrolNowClick={onEnrolNowClick}
                    enrolmentStatus={enrolmentStatus}
                    {...rest}
                    showMobile={false}
                />
                {displayForEnrolment ? (
                    <>
                        <IdentityWrapper>
                            {/* <InsuranceIdentity
                                policyImage={policyImage}
                                policyName={insuranceName}
                                policyCategory={insuranceCategory}
                                familyStructure={rest.dependentsInsured}
                            /> */}
                        </IdentityWrapper>
                        <InsuranceCardDetail
                            {...rest}
                            policyId={policyId}
                            displayForEnrolment={displayForEnrolment}
                            insuranceCategory={insuranceCategory}
                            superTopupStatus={superTopupStatus}
                            policyEndDate={policyEndDate}
                            annualPremium={annualPremium}
                            enrolmentStatus={enrolmentStatus}
                        />
                        <EnrolmentValidityDetail
                            displayForEnrolment={displayForEnrolment}
                            isEnrolPending={isEnrolPending}
                            insuranceCategory={insuranceCategory}
                            superTopupStatus={superTopupStatus}
                            onEnrolNowClick={onEnrolNowClick}
                            enrolmentStatus={enrolmentStatus}
                            {...rest}
                            showMobile={true}
                        />
                    </>
                ) : null}
            </StyledRowContainer>

            {!displayForEnrolment ? (
                <>
                    <InsuranceCardDetail
                        {...rest}
                        policyId={policyId}
                        displayForEnrolment={displayForEnrolment}
                        insuranceCategory={insuranceCategory}
                        superTopupStatus={superTopupStatus}
                        policyEndDate={policyEndDate}
                        annualPremium={annualPremium}
                        enrolmentStatus={enrolmentStatus}
                    />
                    <EnrolmentValidityDetail
                        displayForEnrolment={displayForEnrolment}
                        isEnrolPending={isEnrolPending}
                        insuranceCategory={insuranceCategory}
                        superTopupStatus={superTopupStatus}
                        onEnrolNowClick={onEnrolNowClick}
                        enrolmentStatus={enrolmentStatus}
                        {...rest}
                        showMobile={true}
                    />
                </>
            ) : null}
        </StyledContainer>
    );
};

export default InsuranceCardHeader;
