import styled from 'styled-components';

export const StyledContainer = styled.div(
    (p) => `
    padding: 15px;
    background-color: ${p.theme.colors.secondary};
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 360px;
    min-width: 360px;
    @media (max-width: 768px) {
        max-width: 100%;
        min-width: 100%;
        width: 100%;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
    }
`
);
export const InsuranceDetailsWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: space-between;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
`;
export const StyledHeading = styled.div(
    (p) => `
    font-size: ${p.theme.fontSizes.body};
    font-weight: bold;
    color: ${p.theme.colors.black};
    margin-bottom: 5px;
    line-height: 18px;
`
);
export const StyledSubheading = styled.div(
    (p) => `
    font-size: ${p.theme.fontSizes.label}; 
    color: ${p.theme.colors.mutedBG};
    font-weight: 600;
    margin-bottom: 10px;
`
);
export const StyledDaysLeftText = styled.div(
    (p) => `
    color: ${p.theme.colors.error};
    font-size: ${p.theme.fontSizes.label};
    letter-spacing: 0.05em;
    font-weight: 600;
`
);
export const StyledInsuranceImage = styled.img`
    overflow: hidden;
    height: 50px;
    width: 50px;
    max-height: 50px;
    max-width: 50px;
    min-height: 50px;
    min-width: 50px;
    margin-right: 15px;
`;
