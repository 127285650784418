import React from 'react';
import { StyledContainer, StyledHeading, StyledImage, StyledOtpContainer, StyledTitle } from './styles';
import OtpInput from 'react-otp-input';
import { ILoginVerify } from './types';
import { EditIcon } from '../../../assets/img';
import { Loader } from '../../atoms';

const LoginVerify: React.FunctionComponent<ILoginVerify> = ({
    showMobileForm,
    email = '',
    phoneNumber = '',
    getOtp,
    goBack
}) => {
    const [otp, setOtp] = React.useState('');
    return (
        <StyledContainer>
            {!showMobileForm && (
                <>
                    <StyledTitle>Verify email</StyledTitle>
                    <StyledHeading>
                        We’ve shared a verfication link on &nbsp;<b>{email}</b> <br />
                        Please click on the verification link provided in the email
                    </StyledHeading>
                </>
            )}
            {showMobileForm && phoneNumber ? (
                <>
                    <StyledTitle>Verify number</StyledTitle>
                    <StyledHeading>
                        Please enter the OTP shared on &nbsp;
                        <b>{phoneNumber}</b>
                        <StyledImage style={{ cursor: 'pointer' }} onClick={goBack} src={EditIcon} alt="Edit Icon" />
                    </StyledHeading>
                    <StyledOtpContainer>
                        <OtpInput
                            value={otp}
                            onChange={(otpV: string) => {
                                setOtp(otpV);
                                getOtp && getOtp(otpV);
                            }}
                            numInputs={6}
                            inputStyle="otp-input"
                            containerStyle="otp-container"
                            isInputSecure={true}
                        />
                    </StyledOtpContainer>
                </>
            ) : (
                <StyledOtpContainer>
                    <Loader />
                </StyledOtpContainer>
            )}
        </StyledContainer>
    );
};

export default LoginVerify;
