import styled from 'styled-components';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    @media (max-width: 768px) {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
    }
    @media (max-height: 550px) and (orientation: landscape) {
        margin-top: 20px;
    }
`;
export const StyledInsuranceDetailContainer = styled.div`
    min-width: 100px;
    margin: 30px 20px 0px 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-right: 20px;
    &:last-child {
        border-right: 0px;
    }
    @media (max-width: 550px) {
        margin: 10px 5px 30px 25px;
        &:nth-child(2) {
            border-right: 0px;
        }
    }
`;
export const StyledInsuranceDetailHeading = styled.div(
    (p) => `
    letter-spacing: 0.05em;
    font-weight: 600;
    text-transform: uppercase;
    color: ${p.theme.colors.muted};
    font-size: ${p.theme.fontSizes.label};
    text-align: left;
    @media (max-width: 550px) {
        font-weight: 500;
    }
`
);
export const StyledInsuranceDetailContent = styled.div(
    (p) => `
    position: relative;
    margin-top: 7px;
    font-weight: 600;
    color: ${p.theme.colors.black};
    font-size: ${p.theme.fontSizes.body};
`
);

export const StyledErrorText = styled.span(
    (p) => `
    color: ${p.theme.colors.error};
    font-weight: 600;
    font-size: ${p.theme.fontSizes.label};
    width: 100%;
    margin-left: 5px;
`
);
