import React, { useEffect, useState } from 'react';
import { StyledContainer, StyledFormError, StyledFormTip } from './styles';
import { Input, Loader, Modal } from '../../atoms';
import {
    SaveButton,
    StyledInputGroup,
    StyledLabel,
    StyledMobileWrapper,
    StyledRow,
    StyledRowButtons,
    StyledSelect,
    StyledSpan
} from '../ManageMemberModal/styles';
import { ChevronDownIcon } from '../../../assets/img';
import DatePicker from 'react-date-picker';
import { IAddNomineeModalProps, NomineeFormInput } from './types';
import { capitalizeFirstLetter } from '../../../utils/common';

const relationshipValues = ['Parent', 'Child', 'Spouse', 'Sibling', 'Parent-in-law'];

const AddNomineeModal: React.FunctionComponent<IAddNomineeModalProps> = ({
    isVisible,
    setIsVisible,
    onSubmit,
    consumedPercentage,
    selectedNominee
}) => {
    const isEditMode = !!selectedNominee;
    const [availablePercentage, setAvailablePercentage] = React.useState(100 - consumedPercentage);
    const initialFormState = (): NomineeFormInput => {
        return {
            firstName: { data: selectedNominee?.firstName || '', error: '' },
            lastName: { data: selectedNominee?.lastName || '', error: '' },
            gender: { data: selectedNominee?.gender || '', error: '' },
            relationship: { data: selectedNominee?.relationship || '', error: '' },
            percentage: { data: selectedNominee?.percentage || 0, error: '' },
            dob: { data: selectedNominee?.dob || new Date(), error: '' }
        };
    };
    const [loading, toggleLoading] = useState(false);
    const [nomineeData, setNomineeData] = useState<NomineeFormInput>(initialFormState());

    useEffect(() => {
        let available = 100 - consumedPercentage;
        if (selectedNominee) {
            available += selectedNominee.percentage;
        }
        setAvailablePercentage(available);
    }, [consumedPercentage, selectedNominee]);

    useEffect(() => {
        setNomineeData(initialFormState());
    }, [selectedNominee]);

    const handleSubmit = () => {
        const mandatoryFields = Object.keys(nomineeData);
        let hasErrors = false;
        let errors = nomineeData;
        toggleLoading(true);
        mandatoryFields.map((field) => {
            if (
                !nomineeData[field].data ||
                (typeof nomineeData[field].data === 'string' && !nomineeData[field].data.toString().trim().length)
            ) {
                hasErrors = true;
                errors = {
                    ...errors,
                    [field]: { ...nomineeData[field], error: `${capitalizeFirstLetter(field)} cannot be empty.` }
                };
                return;
            }
            if (field === 'percentage') {
                if (nomineeData[field].data > availablePercentage) {
                    hasErrors = true;
                    errors = {
                        ...errors,
                        [field]: {
                            ...nomineeData[field],
                            error: `You cannot assign more than ${availablePercentage}% for this employee.`
                        }
                    };
                }
                return;
            }
            errors = {
                ...errors,
                [field]: { ...nomineeData[field], error: `` }
            };
        });
        if (hasErrors) {
            setNomineeData(errors);
            toggleLoading(false);
            return;
        }
        onSubmit({
            firstName: nomineeData.firstName.data.toString().trim(),
            lastName: nomineeData.lastName.data.toString().trim(),
            gender: nomineeData.gender.data,
            relationship: nomineeData.relationship.data,
            percentage: nomineeData.percentage.data,
            dob: nomineeData.dob.data
        });
        toggleLoading(false);
        setIsModalVisible(false);
        setNomineeData(initialFormState());
    };
    const setIsModalVisible = (isVisible: boolean) => {
        if (!isVisible) {
            setNomineeData(initialFormState());
        }
        setIsVisible(isVisible);
    };
    const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const firstName = e.target.value;
        setNomineeData({ ...nomineeData, firstName: { data: firstName, error: '' } });
    };
    const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const lastName = e.target.value;
        setNomineeData({ ...nomineeData, lastName: { data: lastName, error: '' } });
    };
    const handleDOBChange = (date: Date) => {
        setNomineeData({ ...nomineeData, dob: { data: date, error: '' } });
    };
    const handleRelationshipSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setNomineeData({ ...nomineeData, relationship: { data: e.target.value, error: '' } });
    };
    const handleGenderChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setNomineeData({ ...nomineeData, gender: { data: e.target.value, error: '' } });
    };
    const handlePercentageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNomineeData({
            ...nomineeData,
            percentage: { data: parseInt(e.target.value), error: '' }
        });
    };
    return (
        <Modal title={`${isEditMode ? 'Edit' : 'Add'} Nominee`} isVisible={isVisible} setIsVisible={setIsModalVisible}>
            <StyledContainer>
                <>
                    <StyledRow>
                        <StyledInputGroup>
                            <StyledLabel>
                                First Name <StyledSpan>*</StyledSpan>
                            </StyledLabel>
                            <Input
                                name="characters"
                                placeholder="Enter"
                                value={nomineeData.firstName.data}
                                errorText={nomineeData.firstName.error}
                                onChange={handleFirstNameChange}
                            />
                            <StyledFormError>{nomineeData.firstName.error}</StyledFormError>
                        </StyledInputGroup>
                        <StyledInputGroup>
                            <StyledLabel>
                                Last Name <StyledSpan>*</StyledSpan>
                            </StyledLabel>
                            <Input
                                name="characters"
                                placeholder="Enter"
                                value={nomineeData.lastName.data}
                                errorText={nomineeData.lastName.error}
                                onChange={handleLastNameChange}
                            />
                            <StyledFormError>{nomineeData.lastName.error}</StyledFormError>
                        </StyledInputGroup>
                    </StyledRow>
                    <StyledRow>
                        <StyledInputGroup>
                            <StyledLabel>
                                Gender <StyledSpan>*</StyledSpan>
                            </StyledLabel>
                            <StyledSelect
                                $error={false}
                                $color={false}
                                $src={ChevronDownIcon}
                                name="gender"
                                value={nomineeData.gender.data}
                                onChange={(e) => handleGenderChange(e)}
                            >
                                <option value="">Select</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                            </StyledSelect>
                            <StyledFormError>{nomineeData.gender.error}</StyledFormError>
                        </StyledInputGroup>
                        <StyledInputGroup>
                            <StyledLabel>
                                Date of Birth <StyledSpan>*</StyledSpan>
                            </StyledLabel>
                            <DatePicker
                                onChange={(date: any) => handleDOBChange(date)}
                                className={`form-control date-input leadpage-input ${
                                    nomineeData.dob.error ? 'date-picker-error' : ''
                                }`}
                                name="dateOfBirth"
                                value={nomineeData.dob.data}
                                // format={'dd-MM-y'}
                                maxDate={new Date()}
                                minDate={new Date('1900-01-01')}
                                clearIcon={null}
                            />
                            <StyledFormError>{nomineeData.dob.error}</StyledFormError>
                        </StyledInputGroup>
                    </StyledRow>
                    <StyledRow>
                        <StyledInputGroup>
                            <StyledLabel>
                                Relationship <StyledSpan>*</StyledSpan>
                            </StyledLabel>
                            <StyledSelect
                                $error={false}
                                $color={false}
                                $src={ChevronDownIcon}
                                name="relationship"
                                value={nomineeData.relationship.data}
                                disabled={false}
                                onChange={(e) => handleRelationshipSelectChange(e)}
                            >
                                <option value="">Select</option>
                                {relationshipValues.map((dependent, index) => {
                                    return (
                                        <option key={index} value={dependent}>
                                            {dependent}
                                        </option>
                                    );
                                })}
                            </StyledSelect>
                            <StyledFormError>{nomineeData.relationship.error}</StyledFormError>
                        </StyledInputGroup>
                        <StyledInputGroup>
                            <StyledLabel>
                                Percentage of Sum Insured <StyledSpan>*</StyledSpan>
                            </StyledLabel>
                            <StyledMobileWrapper>
                                <Input
                                    name="percentage"
                                    type="number"
                                    placeholder="Enter"
                                    value={nomineeData.percentage.data}
                                    errorText={nomineeData.percentage.error}
                                    onChange={handlePercentageChange}
                                />
                            </StyledMobileWrapper>
                            {(nomineeData.percentage.error.length && (
                                <StyledFormError>{nomineeData.percentage.error}</StyledFormError>
                            )) || (
                                <StyledFormTip>
                                    You can assign {availablePercentage}% of sum insured to this nominee
                                </StyledFormTip>
                            )}
                        </StyledInputGroup>
                    </StyledRow>
                </>
            </StyledContainer>
            <StyledRowButtons>
                {/* <StyledErrorText>{formError}</StyledErrorText>*/}
                <SaveButton onClick={() => (loading ? null : handleSubmit())}>
                    {loading ? <Loader inverted={true} /> : `${isEditMode ? 'Edit' : 'Add'} Nominee`}
                </SaveButton>
            </StyledRowButtons>
        </Modal>
    );
};

export default AddNomineeModal;
