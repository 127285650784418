import styled from 'styled-components';

export const StyledContainer = styled.div`
    height: 100%;
    flex-grow: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    padding: 0px 30px;
    padding-top: 35px;
    flex-direction: row;
    box-sizing: border-box;
    flex-wrap: wrap;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    @media (max-width: 768px) {
        max-width: 100%;
        padding: 20px;
        flex-direction: column;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
    }
`;
export const StyledWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-right: 40px;
    @media (max-width: 768px) {
        margin: 0;
        max-width: 100%;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
    }
`;
export const ConfirmationCard = styled.div(
    (p) => `
    min-width: 800px;
    max-width: 800px;
    background-color: ${p.theme.colors.white};
    border-radius: 16px;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 30px;
    @media (max-width: 768px) {
        min-width: 100%;
        max-width: 100%;
        width: 100%;
        padding: 25px 20px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        border-radius: 8px;
    }
`
);
export const TopupCard = styled.div(
    (p) => `
    min-width: 860px;
    max-width: 860px;
    background-color: ${p.theme.colors.white};
    border-radius: 16px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 30px;
    @media (max-width: 768px) {
        min-width: 100%;
        max-width: 100%;
        width: 100%;
        padding: 25px 20px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        border-radius: 8px;
    }
`
);
export const ConfirmationContent = styled.div`
    width: 100%;
    display: flex;
    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;
export const StyledImage = styled.img`
    height: 68px;
    width: 158px;
`;
export const ConfirmationTextContainer = styled.div(
    (p) => `
    max-width: 550px;
    color: ${p.theme.colors.mutedBG};
    font-size: ${p.theme.fontSizes.body};
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    @media (max-width: 768px) {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 20px;
    }
`
);
export const ConfirmationTextTitle = styled.div(
    (p) => `
    color: ${p.theme.colors.black};
    font-weight: bold;
    font-size: ${p.theme.fontSizes.heading};
    margin-bottom: 5px;
`
);
export const StyledPendingText = styled.div(
    (p) => `
    color: ${p.theme.colors.error};
    font-weight: bold;
    font-size: ${p.theme.fontSizes.heading};
    margin-bottom: 6px;
    display: flex;
    align-items: center;
`
);
export const PendingInsuranceWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    margin-top: 30px;
    @media (max-width: 768px) {
        margin-top: 0px;
    }
`;
export const StyledCardWrapper = styled.div`
    margin-bottom: 20px;
    cursor: pointer;
    @media (max-width: 768px) {
        width: 100%;
    }
`;
export const StyledPara = styled.div`
    @media (max-width: 768px) {
        text-align: center;
    }
`;
export const StyledPendingLabel = styled.span(
    (p) => `
    margin-left: 15px;
    padding: 4px 8px;
    border-radius: 6px;
    color: ${p.theme.colors.error};
    background-color: ${p.theme.colors.errorBG};
    line-height: 18px;
    font-weight: bold;
    font-size: ${p.theme.fontSizes.body};
`
);
export const PolicyDetailsCardHeader = styled.div(
    (p) => `
    margin-top: 20px;
    color: ${p.theme.colors.muted};
    text-transform: uppercase;
    font-size: ${p.theme.fontSizes.label};
    letter-spacing: 0.05em;
`
);
