import { useState } from 'react';
import { IPreValidation } from '../../redux/slices/EnrolmentSlice/types';
import { IUseDoBValidationType } from '../containers/ManageMemberModal/types';

export const useDoBValidation = (
    formDoB: Date | undefined
): IUseDoBValidationType => {
    const [doB, setdoB] = useState<Date>(formDoB ? formDoB : new Date());

    const validateDoB = (fieldValidation: IPreValidation | undefined, date?: Date): string => {
        if (typeof fieldValidation !== 'undefined') {
            const dobValidation = Object.values(fieldValidation);
            if (dobValidation[0]?.dob) {
                const to = dobValidation[0].dob?.max;
                const from = dobValidation[0].dob?.min;
                const today = new Date();
                const age = today.getFullYear() - (date ? date.getFullYear() : doB.getFullYear());
                return (age < to && age >= from) ? '' : `Allowed DOB between ${from} years to  ${to} years`;
            }
        }
        return '';
    };

    return {
        doB,
        setdoB,
        validateDoB
    };
};
