import styled from 'styled-components';

export const StyledPrimaryButton = styled.button<{
    fullWidth: boolean;
    disabled: boolean;
}>(
    (p) => `
    font-weight: 600;
    height: 56px;
    font-size: ${p.theme.fontSizes.body};
    color: ${p.theme.colors.loopEmerald};
    background-color: ${p.theme.colors.coverUpGreen};
    cursor: pointer;
    border-width: 0;
    border-radius: 12px;
    font-family: 'Work Sans';
    border: 0px;
    ${p.fullWidth ? 'width: 100%;' : 'width: 220px;'}
    ${p.disabled ? `color:${p.theme.colors.muted}` : `color:${p.theme.colors.loopEmerald}`};
    ${p.disabled ? `background-color:${p.theme.colors.platinum}` : `background-color:${p.theme.colors.coverUpGreen}`};
`
);

export const StyledSecondaryButton = styled.button<{
    fullWidth: boolean;
    disabled: boolean;
    isTopUpBtn: boolean;
}>(
    (p) => `
    background-color: ${p.theme.colors.white};
    color: ${p.theme.colors.loopEmerald};
    font-size: ${p.theme.fontSizes.body};
    border: 1px solid ${p.theme.colors.loopEmerald};
    ${p.fullWidth ? 'width: 100%;' : 'width: 220px;'}
    font-weight: 500;
    cursor: pointer;
    border-radius: 12px;
    padding: 14px 13px;
    ${p.isTopUpBtn ? `min-height:42px` : `min-height:56px`};
    display: flex;
    justify-content: center;
    font-family: 'Work Sans';
    align-items: center;
    margin-right: 15px;
    @media (max-width: 768px) {
        margin-right: 0px;
        width: 70%;
    }    
`
);
export const StyledOAuthButton = styled.button<{
    fullWidth: boolean;
    disabled: boolean;
    isTopUpBtn: boolean;
}>(
    (p) => `
    ${
    p.isTopUpBtn
        ? `font-weight: 600;
    height: 50px;
    font-size: ${p.theme.fontSizes.body};
    color: ${p.theme.colors.primaryBG};
    cursor: pointer;
    border-width: 0;
    border-radius: 6px;
    border: 0px;
    display: flex;
    font-family: 'Work Sans';
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: ${p.theme.colors.white};
    ${p.fullWidth ? 'width: 100%;' : 'width: 220px;'}
    `
        : `font-size: ${p.theme.fontSizes.heading};
    padding: 15px 24px;
    width: 278px;
    max-width: 100%;
    font-weight: 500;
    font-family: 'Work Sans';
    ${p.fullWidth ? 'width: 100%;' : 'width: 220px;'}
    ${p.disabled ? 'opacity: 0.1;' : ''}
    background-color: ${p.theme.colors.white};
    border: 0;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;`
}
`
);

export const StyledOAuthIcon = styled.img<{
    isTopUpBtn: boolean;
}>(
    (p) => `
    ${
    p.isTopUpBtn
        ? `height: 20px;
        width: auto;
    overflow: visible;
    max-height: 100%;
    margin-right: 15px;
    `
        : `height: 24px;
    width: auto;
    font-family: 'Work Sans';
    overflow: visible;
    max-height: 100%;
    margin-right: 15px;`
}
    
`
);

export const StyledOAuthText = styled.span<{
    isTopUpBtn: boolean;
}>(
    (p) => `
    ${p.isTopUpBtn ? `opacity: 1;` : `opacity: 0.6;`}
    
`
);

export const StyledIconButton = styled.button<{
    fullWidth: boolean;
    disabled: boolean;
}>(
    (p) => `
    padding: 10px 9px;
    height: 45px;
    width: 42px;
    max-height: 45px;
    max-width: 42px;
    font-family: 'Work Sans';
    background-color: ${p.theme.colors.white};
    border-radius: 8px;
    border: 1px solid ${p.theme.colors.border};
    box-shadow: 0px 4px 10px rgba(149, 159, 190, 0.2);
    ${p.fullWidth ? 'width: 100%;' : ''}
    ${p.disabled ? 'opacity: 0.1;' : ''}
`
);

export const StyledPills = styled.button<{
    fullWidth: boolean;
    isDisabled: boolean;
    isActive: boolean;
}>(
    (p) => `
    padding: 8px 17px;
    background-color: ${p.isActive ? p.theme.colors.primaryBG : p.theme.colors.white};
    color: ${p.isActive ? p.theme.colors.white : p.theme.colors.black};
    font-weight: 500;
    border-radius: 18px;
    font-size: ${p.theme.fontSizes.label};
    border: 0px;
    margin-right: 12px;
    cursor: pointer;
    ${p.fullWidth ? 'width: 100%;' : ''}
    ${p.disabled ? 'opacity: 0.1;' : ''}
`
);
