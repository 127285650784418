import styled from 'styled-components';

export const StyledContainer = styled.div`
    height: 100%;
    padding: 60px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    background-color: ${(p) => p.theme.colors.secondary};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`;
export const StyledLogo = styled.img`
    max-width: 167px;
    height: auto;
    margin-bottom: 65px;
`;
export const StyledCarouselImage = styled.img`
    max-width: 340px;
    max-height: 340px;
    width: auto;
    height: 35vh;
    margin: 0 auto;
`;
export const StyledParaContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    margin-top: 65px;
`;
export const StyledHeading = styled.h3(
    (p) => `
    color: ${p.theme.colors.black};
    font-size: ${p.theme.fontSizes.landing};
    margin: 0;
`
);
export const StyledPara = styled.p(
    (p) => `
    color: ${p.theme.colors.muted};
    font-size: ${p.theme.fontSizes.heading};
`
);
