import styled from 'styled-components';

export const StyledInput = styled.input<{ isError: boolean; src: string; showBorder: boolean }>(
    (p) => `
    max-width: 100%;
    width: 100%;
    background-color: #F7F7F7;
    box-sizing: border-box;
    -webkit-box-sizing:border-box;
    -moz-box-sizing: border-box;
    border: 1px solid ${p.isError ? p.theme.colors.error : p.showBorder ? p.theme.colors.loopEmerald : 'transparent'};
    color: ${p.isError ? p.theme.colors.error : p.theme.colors.beyondGrey};
    font-size: ${p.theme.fontSizes.body};
    border-radius: 8px;
    box-shadow: 0px 4px 10px ${p.theme.colors.activeBG};
    padding: 20px 20px;
    background: #F7F7F7 url(${p.src}) no-repeat scroll 95% center;
    &::placeholder {
        color: ${p.isError ? p.theme.colors.error : p.theme.colors.muted};
    }
    font-family:'Work Sans';
`
);
