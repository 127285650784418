import React from 'react';
import {
    StyledPrimaryButton,
    StyledIconButton,
    StyledOAuthButton,
    StyledOAuthIcon,
    StyledOAuthText,
    StyledSecondaryButton,
    StyledPills
} from './styles';
import { IButton } from './types';

const Button: React.FunctionComponent<IButton> = ({
    type,
    onClick,
    fullWidth = false,
    id = '',
    children,
    innerRef = null,
    iconSrc = '',
    disabled = false,
    isActive = false,
    isTopUpBtn = false
}) => {
    const getButtonType = () => {
        switch (type) {
        case 'primary':
            return (
                <StyledPrimaryButton
                    disabled={disabled}
                    ref={innerRef}
                    onClick={!disabled ? onClick : () => null}
                    id={id}
                    fullWidth={fullWidth}
                >
                    {children}
                </StyledPrimaryButton>
            );
        case 'secondary':
            return (
                <StyledSecondaryButton
                    disabled={disabled}
                    id={id}
                    ref={innerRef}
                    onClick={!disabled ? onClick : () => null}
                    fullWidth={fullWidth}
                    isTopUpBtn={isTopUpBtn}
                >
                    {children}
                </StyledSecondaryButton>
            );
        case 'icon':
            return (
                <StyledIconButton
                    disabled={disabled}
                    onClick={!disabled ? onClick : () => null}
                    ref={innerRef}
                    id={id}
                    fullWidth={fullWidth}
                >
                    {children}
                </StyledIconButton>
            );
        case 'OAuth':
            return (
                <StyledOAuthButton
                    disabled={disabled}
                    onClick={!disabled ? onClick : () => null}
                    ref={innerRef}
                    id={id}
                    fullWidth={fullWidth}
                    isTopUpBtn={isTopUpBtn}
                >
                    <StyledOAuthIcon src={iconSrc} isTopUpBtn={isTopUpBtn} />
                    <StyledOAuthText isTopUpBtn={isTopUpBtn}>{children}</StyledOAuthText>
                </StyledOAuthButton>
            );
        case 'pills':
            return (
                <StyledPills
                    isDisabled={disabled}
                    ref={innerRef}
                    onClick={!disabled ? onClick : () => null}
                    id={id}
                    fullWidth={fullWidth}
                    isActive={isActive}
                >
                    {children}
                </StyledPills>
            );
        default:
            return (
                <StyledPrimaryButton
                    disabled={disabled}
                    onClick={!disabled ? onClick : () => null}
                    ref={innerRef}
                    id={id}
                    fullWidth={fullWidth}
                >
                    {children}
                </StyledPrimaryButton>
            );
        }
    };
    return getButtonType();
};

export default Button;
