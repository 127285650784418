import { Dispatch, SetStateAction } from 'react';
import {
    IFormValueType,
    IGenderType,
    IRelationshipType,
    ISelectedRelationshipType,
    IUseNameValidationNameType
} from './types';
import { ISelectedRelation } from '../../../redux/slices/ApplicationStateSlice/types';
import { checkHasDependent } from './ManageMemberService';
import { IAllDependents, ICompanyForms, IDependent, IDependentBase } from '../../../redux/slices/PolicyListSlice/types';
import { IFormInput } from '../../../utils/hooks/types';
import { IPolicyDetails, IPreValidation } from '../../../redux/slices/EnrolmentSlice/types';
import { DynamicField } from '../DynamicForms/types';
import { FirebaseTimestamp } from '../../../adapters/provider';

export const handleDependentsUtil = (
    selectedRelationship: ISelectedRelationshipType,
    allowedDependents: IDependent[] | undefined
): IDependent | undefined => {
    if (selectedRelationship.toLowerCase() == 'parent/parent-in-law') {
        selectedRelationship = 'Parent';
    }
    return checkHasDependent(allowedDependents, selectedRelationship);
};

export const getPersistedFormDataIfExistUtil = (
    isEditMode: boolean,
    formValues: IFormValueType,
    firstName: IFormInput,
    lastName: IFormInput,
    mobileNumber: IFormInput,
    setdoB: Dispatch<SetStateAction<Date>>,
    setGender: Dispatch<SetStateAction<IGenderType>>
): (() => void) | undefined => {
    if (isEditMode) {
        if (
            formValues.firstName ||
            formValues.lastName ||
            formValues.mobileNumber ||
            formValues.doB ||
            formValues.gender
        ) {
            firstName.setValue(formValues.firstName || '');
            lastName.setValue(formValues.lastName || '');
            mobileNumber.setValue(formValues.mobileNumber || '');
            formValues.doB ? setdoB(new Date(formValues.doB)) : setdoB(new Date());
            setGender(formValues.gender || '');
        }
        return () => {
            firstName.setValue('');
            lastName.setValue('');
            mobileNumber.setValue('');
            setdoB(new Date());
            setGender('');
        };
    } else {
        firstName.setValue('');
        lastName.setValue('');
        mobileNumber.setValue('');
        setdoB(new Date());
        setGender('');
    }
};

export const handleDynamicFormErrorsUtil = (
    customFormVal: ICompanyForms,
    dynamicField: DynamicField
): Record<string, boolean>[] => {
    const dynamicFieldValidations: Record<string, boolean>[] = [];
    if (customFormVal && customFormVal.data) {
        customFormVal.data.dynamicFormFields.map((field) => {
            const errField: Record<string, boolean> = {};
            if (field.isMandatory) {
                dynamicField.hasOwnProperty(field.name)
                    ? (errField[field.name] = false)
                    : (errField[field.name] = true);
                const pattern = new RegExp(field.regex);
                const checkRegex = (dynamicField as any)[field.name];
                if (typeof checkRegex !== undefined) {
                    if (!pattern?.test(checkRegex)) {
                        errField[field.name] = true;
                    } else {
                        errField[field.name] = false;
                    }
                }
            } else {
                errField[field.name] = false;
            }
            dynamicFieldValidations.push(errField);
        });
    }

    return dynamicFieldValidations;
};

export const handleExistingDependentPresentUtil = (
    dependentList: IAllDependents,
    existingDependent: string
): IDependent | undefined => {
    return dependentList.data?.find((user) => (user.userId as string) === existingDependent);
};

export const performDynamicErrorCheckUtil = (dynamicFieldValidations: Record<string, boolean>[]): boolean =>
    dynamicFieldValidations.some((item) => Object.values(item).some((value) => value === true));

export const performFieldValidationUtil = (
    preValidations: IPolicyDetails,
    relationship: ISelectedRelation,
    selectedRelationship: ISelectedRelationshipType
): IPreValidation | undefined =>
    preValidations.data?.find((relation) =>
        relation.hasOwnProperty(relationship.toLowerCase() ?? selectedRelationship.toLowerCase())
    );

export const getNewDependentUtil = (
    doB: Date,
    gender: IGenderType,
    mobileNumber: IFormInput,
    relationship: IRelationshipType,
    name: IUseNameValidationNameType
): IDependentBase => ({
    doB: FirebaseTimestamp.fromDate(doB).seconds,
    gender: gender,
    mobile: mobileNumber.value,
    relationship: relationship,
    firstName: `${name.firstName.value.trim()}`,
    lastName: `${name.lastName.value.trim()}`
});

export const getDependentUtil = (
    doB: Date,
    gender: IGenderType,
    mobileNumber: IFormInput,
    relationship: IRelationshipType,
    name: IUseNameValidationNameType,
    dependentId: string
): IDependent => ({
    doB: FirebaseTimestamp.fromDate(doB).seconds,
    gender: gender,
    mobile: mobileNumber.value,
    relationship: `${relationship}`,
    userId: dependentId,
    firstName: `${name.firstName.value.trim()}`,
    lastName: `${name.lastName.value.trim()}`
});

export const parseError = (error: string | Record<string, unknown> | undefined | null): string => {
    if (error === undefined || error === null) {
        return 'No error message';
    } else if (typeof error === 'string') {
        return error;
    } else if (typeof error === 'object') {
        const errorValues = Object.values(error);
        if (errorValues.length) {
            return String(errorValues[0]);
        }
    }
    return 'No error message';
};


