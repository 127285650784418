import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SEGMENT_ACTIONS } from '../../../../../../utils/constants/SegmentActionConstants';
import useSegment from '../../../../../../utils/hooks/useSegment';
import { loginWithMobile, loginWithOAuth, clearUserError } from '../../../redux/slices/UserSlice';
import { IOAuthType } from '../../../redux/types';
import { LoginForm, LoginOAuth } from '../../containers';
import {
    StyledContent,
    StyledDivider,
    StyledDividerText,
    StyledErrorText,
    StyledHeading,
    StyledMobileFormContainer,
    StyledOAuthContainer,
    StyledTitle
} from './styles';
import { ILoginPage } from './types';
const LoginPage: React.FunctionComponent<ILoginPage> = ({ title, subTitle }) => {
    const dispatch = useDispatch();
    const trackTask = useSegment('task');
    const userData = useSelector((state: any) => state.user.userData);
    const userMobile = useSelector((state: any) => state.user.userMobile);
    const loginWithMobileNo = (mobileNumber: string) => {
        dispatch(clearUserError());
        dispatch(loginWithMobile(mobileNumber));
    };
    const oAuthLogin = (type: IOAuthType) => {
        dispatch(clearUserError());
        dispatch(loginWithOAuth({ loginMethod: type }));
    };
    React.useEffect(() => {
        if (!userData.loading && !userData.error && userData.data) {
            const segmentAction = SEGMENT_ACTIONS.TASK.LOGIN_SUCCESS;
            trackTask({
                name: segmentAction.name,
                properties: {
                    login_method:
                        (segmentAction.properties?.login_method as (loginMethod: string) => string)(
                            userData.loginMethodType
                        )
                }
            });
        } else if (!userData.loading && userData.error) {
            const segmentAction = SEGMENT_ACTIONS.TASK.LOGIN_FAIL;
            trackTask({
                name: segmentAction?.name ?? '',
                properties: {
                    login_method: (segmentAction.properties?.login_method as (loginMethod: string) => string)(
                        userData.loginMethodType
                    )
                }
            });
        }
    }, [userData]);
    return (
        <StyledContent>
            <StyledTitle>{title}</StyledTitle>
            <StyledHeading>{subTitle}</StyledHeading>
            <StyledOAuthContainer>
                <StyledErrorText>{userData.error && userData.error.message}</StyledErrorText>
                <LoginOAuth signInWithOAuth={oAuthLogin} />
            </StyledOAuthContainer>
            <StyledDivider>
                <StyledDividerText>Or</StyledDividerText>
            </StyledDivider>
            <StyledMobileFormContainer>
                <LoginForm
                    loading={userMobile.loading}
                    showMobile={true}
                    onSubmit={loginWithMobileNo}
                    submitButtonId={'otp-button'}
                />
            </StyledMobileFormContainer>
        </StyledContent>
    );
};

export default LoginPage;
