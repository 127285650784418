import styled from 'styled-components';

export const StyledContainer = styled.div<{ $showBG: boolean }>(
    (p) => `
    background-color: ${p.$showBG ? p.theme.colors.secondaryBG : p.theme.colors.white};
    color: ${p.theme.colors.mutedBG};
    border: ${p.$showBG ? `1px solid ${p.theme.colors.error}` : 'transparent'};
    padding: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-radius: 16px;
    max-width: 400px;
    font-size: ${p.theme.fontSizes.label};
    font-weight: 500;
    @media (max-width: 768px) {
        padding: 20px 10px;
    }
`
);
export const StyledImage = styled.img`
    margin-right: 10px;
`;
