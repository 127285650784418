import { RecommendedConfig } from './types';

/* eslint-disable max-len */
export const topUpDefaultConfig: RecommendedConfig = {
    template1: [],
    template2: [],
    template3: [],
    defaultTemplate: 1,
    recommendedSI: {
        ESCP: [
            {
                AgeRange: [61, 80],
                FamilyMembers: [5, 6],
                sumInsured: 2000000,
                lowMsg:
                    'Don’t let lower sum insured worry you regarding your parents health. Select a minimum 15 Lacs health cover for your family!',
                highMsg: ' is a good amount to cover your entire family'
            },
            {
                AgeRange: [40, 60],
                FamilyMembers: [5, 6],
                sumInsured: 1500000,
                lowMsg:
                    'Our health advisors suggest that a family of 6 should have a minimum 15L health cover for a year. Increase your health cover now!',
                highMsg: ' is a good amount to cover your entire family'
            },
            {
                AgeRange: [61, 80],
                FamilyMembers: [1, 4],
                sumInsured: 1500000,
                lowMsg: 'Protect your family with better coverage. Consider a higher sum insured now!',
                highMsg: ' is a good amount to cover your entire family'
            },
            {
                AgeRange: [40, 60],
                FamilyMembers: [1, 4],
                sumInsured: 1000000,
                lowMsg: 'Protect your family with better coverage. Consider a higher sum insured now!',
                highMsg: ' is a good amount to cover your entire family'
            }
        ],
        ESC: [
            {
                AgeRange: [25, 35],
                FamilyMembers: [3, 4],
                sumInsured: 1500000,
                lowMsg:
                    'Protect your growing family with adequate coverage. Choose a higher cover amount for better protection against medical emergencies.',
                highMsg: ' is a good amount to cover your entire family'
            },
            {
                AgeRange: [36, 100],
                FamilyMembers: [3, 4],
                sumInsured: 1500000,
                lowMsg:
                    'Worrying about rising medical bills? Consider a higher sum insured now, and give your family the health cover they need!',
                highMsg: ' is a good amount to cover your entire family'
            },
            {
                AgeRange: [25, 35],
                FamilyMembers: [1, 2],
                sumInsured: 1500000,
                lowMsg: 'Make sure you cover your loved ones with adequate insurance. Increase your sum insured now!',
                highMsg: ' is a good amount to cover your entire family'
            },
            {
                AgeRange: [36, 100],
                FamilyMembers: [1, 2],
                sumInsured: 1000000,
                lowMsg:
                    'Growing family needs healthcare attention! Make sure you cover your loved ones with adequate insurance. Increase your sum insured now!',
                highMsg: ' is a good amount to cover your entire family'
            }
        ],
        E: [
            {
                AgeRange: [1, 50],
                FamilyMembers: [1, 2],
                sumInsured: 500000,
                lowMsg: 'Increase your sum insured to 5L to make sure you are adequately covered for an entire year!',
                highMsg: ' is a good amount to cover yourself for an entire year!'
            }
        ]
    }
};
