import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../redux';
import {
    StyledContainer,
    StyledTooltipContainer,
    StyledAmount,
    StyledSeeBreakdown,
    StyledSummaryContainer,
    StyledWrapper,
    AnnualPremiumContainer,
    AnnualPremiumText,
    ButtonContainer,
    StyledCoverageContainer,
    StyledCoverageWrapper,
    StyledPremiumMargin,
    StyledLoaderWrapper
} from './styles';
import { PremiumBreakdown } from '..';
import { ChevronDownIcon } from '../../../assets/img';
import { Button, Loader } from '../../atoms';
import { IEnrolmentNavigator } from './types';
import useSegment from '../../../utils/hooks/useSegment';
import { SEGMENT_ACTIONS } from '../../../utils/constants/SegmentActionConstants';
import { checkIfTopUpAvailable, getSegmentPageName } from '../../../utils/common';
import CancelTopUpModal from '../CancelTopUpModal';
import { useHistory, useLocation } from 'react-router-dom';
import { setIsSuperTopUpSelected } from '../../../redux/slices/ApplicationStateSlice';
import Tooltip from '../../atoms/Tooltip';
import { InfoIcon } from '../../../topup-src/assets/img';
import NomineeValidationModal from '../ManageNomineesComponent/NomineeValidationModal';
import { checkTopUpAvailable, getEnrolledPolicyCompany } from '../../../utils/PolicyServices';
import {
    getSelfContributionUtil,
    getStepperMetaDataUtil,
    getTotalCoverageUtil,
    handleNavigationUtil,
    handleRemoveTopUpBtnUtil,
    handleShowBreakdownUtil
} from './utils';

const EnrolmentNavigator: React.FunctionComponent<IEnrolmentNavigator> = ({
    isEnrolMemberValid,
    memberTopUp,
    showSummary,
    loading,
    nomineeConsumedPercentage
}) => {
    const history = useHistory();
    const location = useLocation();
    const trackClick = useSegment('click');
    const currentlySelectedPolicy = useSelector((state: ReduxState) => state.enrolment.currentlySelectedPolicy);
    const isNomineeEnabledForCompany = useSelector(
        (state: ReduxState) => state.stepperData.nomineeConfig.data?.isEnabled
    );
    const topUpOptions = useSelector((state: ReduxState) => state.enrolment.topUpOptions);
    const topUpAmount: any = useSelector((state: ReduxState) => state.TopUp_policyData.memberTopUp);
    const isLoadingTopUpAmount = useSelector((state: ReduxState) => state.TopUp_policyData.memberTopUp?.loading);
    const isLoadingGMC = useSelector((state: ReduxState) => state.TopUp_policyData.policyList?.loading);
    const [errorMsg, setErrorMsg] = React.useState('');
    const [nomineeModalErrorMsg, setNomineeModalErrorMsg] = React.useState({ title: '', description: '' });
    const [isNomineeValidationModalVisible, toggleNomineeValidationModalVisible] = React.useState(false);
    const policyList = useSelector((state: ReduxState) => state.policyList.policyList.data) || [];
    const [showBreakdown, setShowBreakdown] = React.useState(false);
    const [showCancelModalIsVisible, setShowCancelModalIsVisible] = React.useState<boolean>(false);
    const checkSuperTopUpAvailable = checkTopUpAvailable();
    const dispatch = useDispatch();
    const superTopUpAvailable = checkIfTopUpAvailable(checkSuperTopUpAvailable, currentlySelectedPolicy);
    const currentSelectedStep = useSelector((state: ReduxState) => state.applicationState.currentStepperStep);
    const showCallbackToolTip = useSelector((state: ReduxState) => state.TopUp_applicationState.showCallbackToolTip);
    const compTopUp = !topUpOptions.loading ? (topUpOptions.data?.length ? true : false) : true;
    const currentPageAction = SEGMENT_ACTIONS.PAGE[getSegmentPageName(location.pathname) as any];
    const getEnrolledGMCPolicies = getEnrolledPolicyCompany(policyList);
    const stepperMetaData = getStepperMetaDataUtil(superTopUpAvailable, currentlySelectedPolicy, compTopUp);
    const selfContribution = getSelfContributionUtil(currentlySelectedPolicy, memberTopUp);
    const totalCoverage = getTotalCoverageUtil(memberTopUp, currentlySelectedPolicy, superTopUpAvailable, topUpAmount);

    const handleNavigation = () =>
        handleNavigationUtil(
            currentSelectedStep,
            currentPageAction,
            superTopUpAvailable,
            topUpAmount,
            loading,
            isEnrolMemberValid,
            isNomineeEnabledForCompany,
            nomineeConsumedPercentage,
            setErrorMsg,
            setNomineeModalErrorMsg,
            toggleNomineeValidationModalVisible,
            dispatch,
            trackClick,
            showSummary,
            stepperMetaData,
            history
        );

    const handleRemoveTopUpBtn = () =>
        handleRemoveTopUpBtnUtil(trackClick, currentPageAction, topUpAmount, setShowCancelModalIsVisible);

    const handleShowBreakdown = (show: boolean) =>
        handleShowBreakdownUtil(show, trackClick, currentPageAction, topUpAmount, setShowBreakdown);

    const getText = () => stepperMetaData[currentSelectedStep - 1]?.btnCTA ?? '';

    React.useEffect(() => {
        if (!superTopUpAvailable) {
            dispatch(setIsSuperTopUpSelected({ isSuperTopupSelected: false }));
        }
    }, [superTopUpAvailable]);

    return (
        <div id='EnrolNav'>
            {isNomineeEnabledForCompany && (
                <NomineeValidationModal
                    title={nomineeModalErrorMsg.title}
                    description={nomineeModalErrorMsg.description}
                    isVisible={isNomineeValidationModalVisible}
                    setIsVisible={toggleNomineeValidationModalVisible}
                />
            )}
            {currentSelectedStep === 2 && superTopUpAvailable && showCallbackToolTip && (
                <StyledTooltipContainer>
                    <Tooltip
                        Icon={InfoIcon}
                        Text={`You will be contacted on your specified number by our executive within 24 hours.`}
                    />
                </StyledTooltipContainer>
            )}

            <StyledContainer>
                {currentSelectedStep === 2 &&
                showBreakdown &&
                (memberTopUp ||
                    (currentlySelectedPolicy?.annualPremium && currentlySelectedPolicy?.annualPremium > 0)) &&
                selfContribution === 0 ? (
                        <StyledPremiumMargin>
                            <PremiumBreakdown
                                onClose={() => handleShowBreakdown(false)}
                                topupList={memberTopUp}
                                selectedPolicyPremiums={{
                                    annualPremium: currentlySelectedPolicy?.annualPremium || 0,
                                    annualPremiumWithoutGST: currentlySelectedPolicy?.annualPremiumWithoutGST || 0,
                                    gstOfAnnualPremium: currentlySelectedPolicy?.gstOfAnnualPremium || 0
                                }}
                            />
                        </StyledPremiumMargin>
                    ) : null}
                {currentSelectedStep === 2 && showBreakdown && superTopUpAvailable && topUpAmount?.sumInsured ? (
                    <StyledPremiumMargin>
                        <PremiumBreakdown
                            onClose={() => handleShowBreakdown(false)}
                            topupList={memberTopUp}
                            selectedPolicyPremiums={{
                                annualPremium: topUpAmount?.annualPremium || 0,
                                annualPremiumWithoutGST: topUpAmount?.annualPremiumWithoutGST || 0,
                                gstOfAnnualPremium: topUpAmount?.annualPremiumGST || 0
                            }}
                            showContributionNotice={false}
                            showSuperTopUpList={true}
                        />
                    </StyledPremiumMargin>
                ) : null}
                <StyledSummaryContainer>
                    {currentSelectedStep != 1 && currentSelectedStep != 2 && (
                        <AnnualPremiumContainer>
                            <StyledCoverageContainer>
                                <StyledWrapper>
                                    <AnnualPremiumText>Your Annual Contribution: </AnnualPremiumText>
                                    <StyledAmount>
                                        {isLoadingTopUpAmount || isLoadingGMC ? (
                                            <StyledLoaderWrapper>
                                                <Loader />
                                            </StyledLoaderWrapper>
                                        ) : (
                                            <>
                                                &#8377;&nbsp;
                                                {(superTopUpAvailable
                                                    ? topUpAmount?.annualPremium || 0
                                                    : selfContribution
                                                )
                                                    ?.toString()
                                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}
                                            </>
                                        )}
                                    </StyledAmount>
                                </StyledWrapper>
                                <StyledCoverageWrapper $showMobile={false}>
                                    <AnnualPremiumText>Total Coverage:</AnnualPremiumText>
                                    <StyledAmount>
                                        {isLoadingTopUpAmount || isLoadingGMC ? (
                                            <StyledLoaderWrapper>
                                                <Loader />
                                            </StyledLoaderWrapper>
                                        ) : (
                                            <>
                                                &#8377;&nbsp;
                                                {totalCoverage}
                                            </>
                                        )}
                                    </StyledAmount>
                                </StyledCoverageWrapper>
                            </StyledCoverageContainer>
                            {selfContribution !== 0 ||
                                (superTopUpAvailable && !showBreakdown && (
                                    <StyledSeeBreakdown onClick={() => handleShowBreakdown(true)}>
                                        See Breakdown
                                        <img src={ChevronDownIcon} />
                                    </StyledSeeBreakdown>
                                ))}
                        </AnnualPremiumContainer>
                    )}
                    {currentSelectedStep === 2 && superTopUpAvailable && (
                        <>
                            <ButtonContainer>
                                {getEnrolledGMCPolicies.length === 0 && (
                                    <Button type="secondary" onClick={handleRemoveTopUpBtn}>
                                        Skip Super Top-up
                                    </Button>
                                )}
                            </ButtonContainer>
                            <CancelTopUpModal
                                isVisible={showCancelModalIsVisible}
                                setIsVisible={setShowCancelModalIsVisible}
                                showSummary={showSummary}
                                selectedSI={topUpAmount?.sumInsured}
                                pageTitle={'family_topup'}
                            />
                        </>
                    )}

                    <ButtonContainer>
                        <Button type="primary" disabled={loading ? true : false} onClick={handleNavigation}>
                            {isLoadingTopUpAmount || isLoadingGMC ? <Loader inverted /> : `${getText()}`}
                        </Button>
                        <span>{errorMsg}</span>
                    </ButtonContainer>
                </StyledSummaryContainer>
            </StyledContainer>
        </div>
    );
};

export default EnrolmentNavigator;
