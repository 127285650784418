import { REDIRECT_URL } from '../../topup-src/utils/constants/RedirectConstants';
import { redirect } from '../common';

export const redirectTo = (
    topupPolicyData: { policyName: string; policyProvider?: string | undefined },
    type: string
): void => {
    let url;
    if (
        topupPolicyData?.policyName &&
        (topupPolicyData?.policyProvider?.toLowerCase().includes('magma') ||
            topupPolicyData?.policyName.toLowerCase().includes('magma'))
    ) {
        url = type === 'terms' ? REDIRECT_URL.TERMS_CONDITIONS : REDIRECT_URL.NETWORK_HOSPITALS;
    } else {
        url = type === 'terms' ? REDIRECT_URL.NETWORK_HOSPITALS : REDIRECT_URL.NETWORK_HOSPITALS_ICICI;
    }
    redirect(url);
};
