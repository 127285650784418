import React from 'react';
import {
    StyledContainer,
    StyledLogo,
    SignoutButton,
    SignoutText,
    SignoutIcon,
    SignoutWrapper,
    StyledTalkBtnWrapper
} from './styles';
import { LoopLogo, PowerIcon } from '../../../assets/img';
import { IHeader } from './types';
import { TextView } from '../../../topup-src/components/atoms/TextView';
import { capitalizeFirstLetter, getSegmentPageName } from '../../../utils/common';
import theme from '../../../theme';

import { useSelector } from 'react-redux';
import { ReduxState } from '../../../redux';
import { NameIcon } from '../../../topup-src/components/atoms';
import Button from '../Button';
import { useLocation } from 'react-router-dom';
import useSegment from '../../../utils/hooks/useSegment';
import { SEGMENT_ACTIONS } from '../../../utils/constants/SegmentActionConstants';
import TopupCallback from '../../../topup-src/components/containers/TopupCallback';

const Header: React.FunctionComponent<IHeader> = ({ signout, redirectToMain }) => {
    const location = useLocation();
    const trackClick = useSegment('click');
    const userName = useSelector((state: ReduxState) => state.user?.userData?.data);
    const currentPageAction = SEGMENT_ACTIONS.PAGE[getSegmentPageName(location.pathname) as any];
    const [callbackIsVisible, setCallbackIsVisible] = React.useState(false);
    const handleCallExpertClick = () => {
        setCallbackIsVisible(true);
        const segmentAction = SEGMENT_ACTIONS.CLICK.CALL_AN_EXPERT;
        trackClick({
            name: segmentAction.name,
            properties: {
                page_title: segmentAction.properties?.page_title,
                login_status: segmentAction.properties?.login_status,
                page_url: (currentPageAction.properties?.page_url as (path: string) => string)(location.pathname) || '',
                page_id: (currentPageAction.properties?.page_id as (path: string) => string)(location.pathname) || '',
                cta_id: (segmentAction.properties?.cta_id as (cta: number) => number)(0)
            }
        });
    };
    return (
        <StyledContainer>
            <StyledLogo src={LoopLogo} alt={'Loop Health Logo'} onClick={redirectToMain} />
            <SignoutWrapper>
                {userName ? (
                    <>
                        <NameIcon name={userName?.firstName} color={theme.colors.loopEmerald} />{' '}
                        <TextView fontSize="16px" margin="0px 0px 0px 10px" color={theme.colors.beyondGrey}>
                            {capitalizeFirstLetter(userName?.firstName) +
                                ' ' +
                                capitalizeFirstLetter(userName?.lastName)}
                        </TextView>
                    </>
                ) : null}

                <SignoutButton onClick={signout}>
                    <SignoutText>Sign Out</SignoutText>
                    <SignoutIcon src={PowerIcon} />
                </SignoutButton>
                {getSegmentPageName(location.pathname) === 'FAMILY_TOPUP_PAGE' ? (
                    <StyledTalkBtnWrapper>
                        <Button
                            type="secondary"
                            fullWidth={true}
                            disabled={false}
                            isTopUpBtn={true}
                            onClick={() => handleCallExpertClick()}
                        >
                            {'Talk to Us'}
                        </Button>
                    </StyledTalkBtnWrapper>
                ) : null}
                <TopupCallback visible={callbackIsVisible} setVisible={setCallbackIsVisible} />
            </SignoutWrapper>
        </StyledContainer>
    );
};

export default Header;
