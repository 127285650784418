import React from 'react';
import { StyledContainerWrapper, StyledContainer, StyledRow, StyledSummaryText, StyledBackIcon } from './styles';
import { SummaryPage } from '../../../../../topup-src/components/pages/SPAContainer/pages';
import { useHistory } from 'react-router-dom';
import { ChevronLeftIcon } from '../../../../../assets/img';
import { SEGMENT_ACTIONS } from '../../../../../utils/constants/SegmentActionConstants';

import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../../redux';
import { getSegmentPageName } from '../../../../../utils/common';
import useSegment from '../../../../../utils/hooks/useSegment';
import { FreshChat } from '../../../../atoms/FreshChat';
import { getEnrolledPolicyCompany } from '../../../../../utils/PolicyServices';
const CheckoutPage: React.FunctionComponent = () => {
    const history = useHistory();
    const handleBack = () => {
        history.goBack();
    };
    const currentlySelectedPolicy = useSelector((state: ReduxState) => state.enrolment.currentlySelectedPolicy);
    const policyList = useSelector((state: ReduxState) => state.policyList.policyList.data) || [];
    const currentPageAction = SEGMENT_ACTIONS.PAGE[getSegmentPageName(location.pathname) as any];
    const trackPage = useSegment('page');
    const checkIfSuperTopUpSelected = useSelector((state: ReduxState) => state.applicationState.isSuperTopupSelected);
    const topUpDetails: any = useSelector((state: ReduxState) => state.TopUp_policyData.memberTopUp);
    const userFeedback = useSelector((state: ReduxState) => state.applicationState.unSelectTopupFeedback);
    const getEnrolledGMCPolicies = getEnrolledPolicyCompany(policyList);
    React.useEffect(() => {
        trackPage({
            name: currentPageAction.name,
            properties: {
                page_url: (currentPageAction.properties?.page_url as (path: string) => string)(location.pathname) || '',
                page_id: (currentPageAction.properties?.page_id as (path: string) => string)(location.pathname) || '',
                page_title: currentPageAction.properties?.page_title,
                login_status: currentPageAction.properties?.login_status,
                referrer_page_title: currentPageAction.properties?.referrer_page_title,
                enrolling_in: (currentPageAction.properties?.enrolling_in as (enrolledIN: string) => string)(
                    getEnrolledGMCPolicies.length
                        ? checkIfSuperTopUpSelected
                            ? 'topup'
                            : 'both'
                        : checkIfSuperTopUpSelected
                            ? 'both'
                            : 'GMC'
                ),
                sum_insured: (currentPageAction.properties?.sum_insured as (sumInsured: string) => string)(
                    topUpDetails?.sumInsured
                ),
                user_feedback: (currentPageAction.properties?.user_feedback as (feedback: string) => string)(
                    userFeedback
                )
            }
        });
    }, [currentPageAction]);
    return (
        <StyledContainerWrapper>
            <FreshChat />
            <StyledRow>
                <StyledSummaryText id="chevron-back" onClick={handleBack}>
                    <StyledBackIcon src={ChevronLeftIcon} alt={`go back`} />
                    Checkout Summary
                </StyledSummaryText>
            </StyledRow>
            <StyledContainer>
                {currentlySelectedPolicy ? <SummaryPage selectedBasePolicy={currentlySelectedPolicy} /> : null}
            </StyledContainer>
        </StyledContainerWrapper>
    );
};

export default CheckoutPage;
