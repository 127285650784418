import styled from 'styled-components';

export const StyledContainer = styled.div`
    position: relative;
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: flex-start;
    @media (max-width: 768px) {
        flex-direction: column;
        margin-bottom: 100px;
    }
`;
export const StyledWrapperContainer = styled.div`
    box-shadow: 0px -2px 14px rgba(168, 196, 206, 0.1);
    border-radius: 16px;
    background-color: ${(p) => p.theme.colors.white};
    /* overflow-x: auto; */
    min-width: 700px;
    @media (max-width: 768px) {
        box-shadow: 0px;
        border-radius: 0;
        min-width: 100%;
    }
`;
export const StyledMenuContainer = styled.div`
    padding-top: 40px;
    padding-left: 50px;
    padding-right: 50px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    max-height: 100%;
    /* height: 400px; */
    position: sticky;
    top: 32px;
    @media (max-width: 768px) {
        padding: 0px;
        width: 100%;
    }
`;
export const StyledContentContainer = styled.div`
    max-height: 100%;
    max-width: 1100px;
    /* overflow-x: auto; */
    margin-bottom: 100px;
    padding: 50px 20px;
    padding-right: 40px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    @media (max-width: 768px) {
        padding: 0px;
        width: 100%;
    }
`;
export const NavigatorContainer = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 100%;
    @media (max-width: 768px) {
        max-width: 100%;
        min-width: 0;
        position: fixed;
        right: 0;
        width: 100%;
    }
`;

export const StyledDivContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 25px;
    margin-top: 45px;
    padding-bottom: 20px;
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;
export const StyledTitleText = styled.div(
    (p) => `
    color: ${p.theme.colors.muted};
    font-size: ${p.theme.fontSizes.body};
    text-transform: uppercase;
    font-weight: 700;
`
);
