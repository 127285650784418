import { SEGMENT_ACTIONS } from './constants/SegmentActionConstants';
import { IPolicy } from '../redux/slices/PolicyListSlice/types';
import { getFullInsuranceName } from './common';

export const confirmationEvents = (
    currentlySelectedPolicy: IPolicy,
    formattedDependents: string[],
    formattedTopup: string[]
): any => {
    const segmentAction = SEGMENT_ACTIONS.CLICK.SUMMARY_CONFIRM;

    return {
        name: segmentAction.name,
        properties: {
            describe: segmentAction.properties?.describe || '',
            insurance_type: (segmentAction.properties?.policy_type as (policyType: string) => string)(
                getFullInsuranceName(currentlySelectedPolicy?.policyType || '')
            ),
            insurer_name: (segmentAction.properties?.insurer_name as (insurerName: string) => string)(
                currentlySelectedPolicy?.policyName || ''
            ),
            insurer_logo: (segmentAction.properties?.insurer_logo as (insurerLogo: string) => string)(
                currentlySelectedPolicy?.policyImage || ''
            ),
            sum_insured: (segmentAction.properties?.sum_insured as (sumInsured: string) => string)(
                currentlySelectedPolicy?.sumInsured || ''
            ),
            family_structure: (segmentAction.properties?.family_structure as (familyStructure: string) => string)(
                currentlySelectedPolicy?.familyStruture || ''
            ),
            dependent1: (segmentAction.properties?.dependent1 as (dependent1: string) => string)(
                formattedDependents[0] || ''
            ),
            dependent2: (segmentAction.properties?.dependent2 as (dependent2: string) => string)(
                formattedDependents[1] || ''
            ),
            dependent3: (segmentAction.properties?.dependent3 as (dependent3: string) => string)(
                formattedDependents[2] || ''
            ),
            dependent4: (segmentAction.properties?.dependent4 as (dependent4: string) => string)(
                formattedDependents[3] || ''
            ),
            dependent5: (segmentAction.properties?.dependent5 as (dependent5: string) => string)(
                formattedDependents[4] || ''
            ),
            dependent6: (segmentAction.properties?.dependent6 as (dependent6: string) => string)(
                formattedDependents[5] || ''
            ),
            dependent7: (segmentAction.properties?.dependent7 as (dependent7: string) => string)(
                formattedDependents[6] || ''
            ),
            dependent8: (segmentAction.properties?.dependent8 as (dependent8: string) => string)(
                formattedDependents[7] || ''
            ),
            dependent9: (segmentAction.properties?.dependent9 as (dependent9: string) => string)(
                formattedDependents[8] || ''
            ),
            dependent10: (segmentAction.properties?.dependent10 as (dependent10: string) => string)(
                formattedDependents[9] || ''
            ),
            topup1: (segmentAction.properties?.topup1 as (topup1: string) => string)(formattedTopup[0] || ''),
            topup2: (segmentAction.properties?.topup2 as (topup2: string) => string)(formattedTopup[1] || ''),
            topup3: (segmentAction.properties?.topup3 as (topup3: string) => string)(formattedTopup[2] || '')
        }
    };
};
