import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchPolicyWiseFamilyDetails } from '../../../adapters';
import { fetchDependents, fetchCompanyDetails } from '../../../adapters';
import { IAllDependents } from './types';

const USER_POLICY_ACTION_TYPES = {
    FETCH_POLICY_LIST: 'userpolicylist/fetch-policy-list',
    FETCH_USER_DEPENDENTS: 'userpolicylist/fetchUserDependents',
    FETCH_COMPANY_DETAILS: 'userpolicylist/getCompanyDetails'
};

export const fetchUserPolicyList = createAsyncThunk(
    USER_POLICY_ACTION_TYPES.FETCH_POLICY_LIST,
    async ({ userId }: { userId: string }) => {
        try {
            const policyList = await fetchPolicyWiseFamilyDetails(userId);
            return {
                policyList: policyList
            };
        } catch (e) {
            throw e;
        }
    }
);

export const fetchUserDependents = createAsyncThunk(
    USER_POLICY_ACTION_TYPES.FETCH_USER_DEPENDENTS,
    async ({ userId }: { userId: string }) => {
        try {
            const response = await fetchDependents(userId);
            return {
                loading: false,
                data: response,
                error: null
            } as IAllDependents;
        } catch (e) {
            throw e;
        }
    }
);

export const getCompanyDetails = createAsyncThunk(
    USER_POLICY_ACTION_TYPES.FETCH_COMPANY_DETAILS,
    async ({ companyId }: { companyId: string }) => {
        try {
            const response = await fetchCompanyDetails(companyId);
            return {
                companyDetails: response
            };
        } catch (e) {
            throw e;
        }
    }
);
