import { PolicyStatusConstants, PolicyTypeConstants } from '../constants';

export const getEnrolBtnTxt = (
    dependentsInsured: string,
    superTopupStatus?: string,
    insuranceCategory?: string
): string => {
    if (insuranceCategory === PolicyTypeConstants.SUPER_TOP_UP) {
        if (
            superTopupStatus === PolicyStatusConstants.NOT_SELECTED ||
            superTopupStatus === PolicyStatusConstants.PENDING
        ) {
            return 'Enrol Now';
        }
    }
    return dependentsInsured?.toLowerCase() === 'self' ? 'Verify Details' : 'Enrol Now';
};

export const showEnrolButton = (
    isEnrolPending: boolean,
    insuranceCategory?: string,
    enrolmentStatus?: string
): boolean => {
    if (
        isEnrolPending ||
        (insuranceCategory === PolicyTypeConstants.SUPER_TOP_UP && enrolmentStatus?.toLowerCase() !== 'enroled')
    ) {
        return true;
    }
    return false;
};

export const showEnrolmentDueDateCard = (
    displayForEnrolment: boolean,
    enrolmentDueDate: string,
    insuranceCategory?: string,
    enrolmentStatus?: string
): boolean => {
    if (
        insuranceCategory !== PolicyTypeConstants.SUPER_TOP_UP &&
        !displayForEnrolment &&
        enrolmentStatus?.toLowerCase() != PolicyStatusConstants.ENROLED.toLowerCase() &&
        enrolmentDueDate?.trim()
    ) {
        return true;
    }
    return false;
};
