import React from 'react';
import { StyledInput } from './styles';
import { IInput } from './types';

const Input: React.FunctionComponent<IInput> = ({ maxLen, onChange, value, errorText, type, ...rest }) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!(maxLen && e.currentTarget.value.length > maxLen)) {
            if (type === 'tel' && !e.currentTarget.value.match(/(^[0-9]+$|^$)/g)) return;
            onChange(e);
        }
    };
    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === '-' && type === 'number') {
            e.preventDefault();
            e.stopPropagation();
            return;
        }
    };
    return (
        <StyledInput
            {...rest}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            value={value}
            isError={!!errorText.length}
            maxLength={maxLen ? maxLen : 524288}
            type={type ? type : 'text'}
        />
    );
};

export default Input;
