import styled from 'styled-components';

export const StyledSummaryContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
    }
`;

export const StyledPremiumMargin = styled.div`
    margin-bottom: 25px;
`;
export const StyledContainer = styled.div`
    padding: 11px 70px;
    display: flex;
    box-shadow: 0px -2px 14px rgba(168, 196, 206, 0.2);
    border-radius: 10px;
    flex-direction: column;
    background-color: ${(p) => p.theme.colors.white};
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    @media (max-width: 768px) {
        padding: 11px;
    }
`;
export const StyledTooltipContainer = styled.div`
    padding: 10px 20px;
    display: flex;
    width: 90%;
    margin: 20px auto;

    box-shadow: 0px -2px 14px rgba(168, 196, 206, 0.2);
    border-radius: 10px;
    flex-direction: column;
    background-color: #6fcf97;
    box-sizing: border-box;
    color: ${(p) => p.theme.colors.white};
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    @media (max-width: 768px) {
        padding: 11px;
    }
`;
export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    min-width: 220px;
    margin: 0px;
    @media (max-width: 768px) {
        width: 100%;
    }
`;
export const AnnualPremiumContainer = styled.div`
    flex-grow: 1;
    height: 52px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${(p) => p.theme.colors.secondary};
    border-radius: 6px;
    margin-right: 50px;
    padding: 9px 15px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    @media (max-width: 768px) {
        width: 100%;
        margin-right: 0px;
        margin-bottom: 20px;
    }
`;
export const AnnualPremiumText = styled.div(
    (p) => `
    max-width: 100%;
    font-weight: 600;
    font-size: ${p.theme.fontSizes.body};
    color: ${p.theme.colors.mutedBG};
    margin-right: 8px;
    @media (max-width: 768px) {
        margin-right: 10px;
        max-width: 80px;
    }
`
);
export const StyledCoverageContainer = styled.div`
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    flex-grow: 1;
`;
export const StyledCoverageWrapper = styled.div<{ $showMobile: boolean }>(
    (p) => `
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 20px;
    border-left: 1px solid ${p.theme.colors.mutedBG};
    padding-left:10px;
    ${
    p.$showMobile
        ? 'text-align: center;'
        : `@media (max-width: 768px) {
            display: none
        }`
}
    `
);
export const StyledAmount = styled.div(
    (p) => `
    font-weight: 700;
    font-size: ${p.theme.fontSizes.heading};
    color: ${p.theme.colors.black};
`
);
export const StyledWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 20px;
`;

export const StyledLoaderWrapper = styled.div`
    display: flex;
    margin: 0px 20px;
`;
export const StyledSeeBreakdown = styled.div(
    (p) => `
    font-weight: 600;
    font-size: ${p.theme.fontSizes.label};
    color: ${p.theme.colors.primary};
    display: flex;
    align-items: center;
`
);

