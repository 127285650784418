import React from 'react';
import {
    StyledInsuranceCategory,
    StyledInsuranceImage,
    StyledInsuranceName,
    StyledInsuranceNameContainer,
    StyledNameContainer
} from './styles';
import { DefaultInsuranceIcon } from '../../../assets/img';
import { IInsuranceIdentity } from './types';
import { getPolicyTitle } from '../../../utils/PolicyServices';


const InsuranceIdentity: React.FunctionComponent<IInsuranceIdentity> = ({
    policyCategory,
    policyImage,
    policyName,
    familyStructure
}) => {
    return (
        <StyledNameContainer>
            <StyledInsuranceImage src={policyImage || DefaultInsuranceIcon} alt={'Insurance Image'} />
            <StyledInsuranceNameContainer>
                <StyledInsuranceName>{getPolicyTitle(policyCategory, familyStructure)}</StyledInsuranceName>
                <StyledInsuranceCategory>{policyName}</StyledInsuranceCategory>
            </StyledInsuranceNameContainer>
        </StyledNameContainer>
    );
};

export default InsuranceIdentity;
