import React from 'react';
import {
    StyledButtonContainer,
    StyledButtonRow,
    StyledCard,
    StyledCardContent,
    StyledCardTitle,
    StyledContainer,
    StyledCounter,
    StyledImage,
    StyledRow,
    StyledSubTitle,
    StyledTitle
} from './styles';
import { Welcome1Icon, Welcome2Icon, Welcome3Icon } from '../../../assets/img';
import { Button } from '../../atoms';
import { IWelcomeCard } from './types';

const WelcomeCard: React.FunctionComponent<IWelcomeCard> = ({ onProceed }) => {
    return (
        <StyledContainer>
            <StyledTitle>You’re now Looped in!</StyledTitle>
            <StyledSubTitle>Here’s how to make the most of the enrolment portal</StyledSubTitle>
            <StyledRow>
                <StyledCard>
                    <StyledImage src={Welcome1Icon} />
                    <StyledCounter>1</StyledCounter>
                    <StyledCardTitle>Select Policy</StyledCardTitle>
                    <StyledCardContent>Select the policy to enrol your family in.</StyledCardContent>
                </StyledCard>
                <StyledCard>
                    <StyledImage src={Welcome2Icon} />
                    <StyledCounter>2</StyledCounter>
                    <StyledCardTitle>Edit Member Details</StyledCardTitle>
                    <StyledCardContent>
                        Add family members, edit information that is not correct or delete members that you wish to
                        remove
                    </StyledCardContent>
                </StyledCard>
                <StyledCard>
                    <StyledImage src={Welcome3Icon} />
                    <StyledCounter>3</StyledCounter>
                    <StyledCardTitle>Confirm details</StyledCardTitle>
                    <StyledCardContent>
                        Make sure all the details are correct before confirming. If not, go back to edit those details.
                    </StyledCardContent>
                </StyledCard>
            </StyledRow>
            <StyledButtonRow>
                <StyledButtonContainer>
                    <Button type="secondary" onClick={onProceed} fullWidth={true}>
                        Proceed
                    </Button>
                </StyledButtonContainer>
            </StyledButtonRow>
        </StyledContainer>
    );
};

export default WelcomeCard;
