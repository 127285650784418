import styled from 'styled-components';

export const StyledContainerWrapper = styled.div`
    height: 100%;
    flex-grow: 1;
    width: 100%;
    display: flex;
    align-items: flex-start;
`;

export const StyledContainer = styled.div`
    height: 100%;
    flex-grow: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const StyledSummarySpan = styled.div(
    (p) => `
    font-size: ${p.theme.fontSizes.label};
    color: ${p.theme.colors.error};
    font-weight: bold;
    font-style: normal;
    margin: 0px 5px;
    letter-spacing: 0.5px;
`
);

export const StyledSummaryDaysSpan = styled.span(
    (p) => `
    font-size: ${p.theme.fontSizes.label};
    color: ${p.theme.colors.black};
    font-weight: bold;
    font-style: normal;
    margin: 0px 5px;
    letter-spacing: 0.5px;
`
);

export const StyledRow = styled.div`
    width: 96%;
    margin-bottom: 0px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    @media (max-width: 768px) {
        padding: 25px 20px;
        margin-bottom: 0px;
    }
`;
export const StyledContent = styled.div`
    margin-bottom: 30px;
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 138px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    @media (max-width: 768px) {
        padding: 0px;
        margin-bottom: 0;
    }
`;
export const StyledSummaryText = styled.div(
    (p) => `
    font-size: ${p.theme.fontSizes.title};
    color: ${p.theme.colors.black};
    margin-top:20px;
    font-weight: bold;
    font-style: normal;
    line-height: 24px;
    width: 90%;
    letter-spacing: 0.5px;
`
);
export const StyledBackIcon = styled.img`
    margin-right: 20px;
`;

export const StyledMenuContainer = styled.div`
    padding-top: 40px;
    padding-left: 50px;
    /* padding-right: 50px; */
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    max-height: 100%;
    /* height: 400px; */
    position: sticky;
    top: 32px;
    @media (max-width: 768px) {
        padding: 0px;
        width: 100%;
    }
`;

export const StyledAlertContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0px 10px;
    padding: 0px 20px;
    margin: 20px 0px 0px 0px;
`;

export const StyledAlertDescContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
