import React from 'react';
import styled from 'styled-components';
import { CloseIcon } from '../../../assets/img';
import { EmployeeContributionNotice } from '../../atoms';
import { IPremiumBreakdown } from './types';
import {
    StyledContainer,
    StyledTableCell,
    StyledTableCellBody,
    StyledTableCellBodyName,
    StyledTableCellName,
    StyledTableContainer,
    StyledTableRow,
    StyledTitle,
    StyledTitleRow
} from './styles';
import { IMemberTopUp } from '../../../redux/slices/EnrolmentSlice/types';

const StyledImage = styled.img`
    cursor: pointer;
`;
const PremiumBreakdown: React.FunctionComponent<IPremiumBreakdown> = ({
    onClose,
    topupList,
    selectedPolicyPremiums,
    showContributionNotice,
    showSuperTopUpList
}) => {
    const { annualPremium, annualPremiumWithoutGST, gstOfAnnualPremium } = selectedPolicyPremiums;
    return (
        <StyledContainer>
            <StyledTitleRow>
                <StyledTitle>Premium Breakdown</StyledTitle>
                {(onClose && <StyledImage src={CloseIcon} alt="close breakdown" onClick={onClose} />) || <></>}
            </StyledTitleRow>
            <StyledTableContainer>
                <StyledTableRow>
                    <StyledTableCellName>PREMIUM</StyledTableCellName>
                    <StyledTableCell $showMobile={false}>
                        ANNUAL PREMIUM <br /> (EXCL. GST)
                    </StyledTableCell>
                    <StyledTableCell $showMobile={false}>18% GST</StyledTableCell>
                    <StyledTableCell $showMobile={false}>
                        ANNUAL PREMIUM <br /> (INCL. GST)
                    </StyledTableCell>
                </StyledTableRow>
                {annualPremium ? (
                    <StyledTableRow>
                        <StyledTableCellBodyName>
                            {showSuperTopUpList ? 'Family Top-up Premium' : 'Base Policy Premium'}
                        </StyledTableCellBodyName>
                        <StyledTableCellBody $showMobile={false}>
                            &#8377;&nbsp;{(annualPremiumWithoutGST ?? 0).toFixed(2)}
                        </StyledTableCellBody>
                        <StyledTableCellBody $showMobile={false}>
                            &#8377;&nbsp;{(gstOfAnnualPremium ?? 0).toFixed(2)}
                        </StyledTableCellBody>
                        <StyledTableCellBody $showMobile={false}>
                            &#8377;&nbsp; {(annualPremium ?? 0).toFixed(2)}
                        </StyledTableCellBody>
                    </StyledTableRow>
                ) : null}
                {topupList?.map((topup: IMemberTopUp) => (
                    <StyledTableRow key={topup.topUpSlabId}>
                        <StyledTableCellBodyName>Top-Up</StyledTableCellBodyName>
                        <StyledTableCellBody $showMobile={false}>
                            &#8377;&nbsp;{(topup.annualPremiumWithoutGST ?? 0).toFixed(2)}
                        </StyledTableCellBody>
                        <StyledTableCellBody $showMobile={false}>
                            &#8377;&nbsp;{(topup.gstOfAnnualPremium ?? 0).toFixed(2)}
                        </StyledTableCellBody>
                        <StyledTableCellBody $showMobile={false}>
                            &#8377;&nbsp; {(topup.annualPremium ?? 0).toFixed(2)}
                        </StyledTableCellBody>
                    </StyledTableRow>
                ))}
            </StyledTableContainer>
            {showContributionNotice && <EmployeeContributionNotice showBG={false} />}
        </StyledContainer>
    );
};

export default PremiumBreakdown;
