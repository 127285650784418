import styled from 'styled-components';

export const StyledContainer = styled.div(
    (p) => `
    background-color: ${p.theme.colors.secondary};
    border-radius: 12px;
    padding: 20px;
    margin-top: 20px;
    width: 100%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`
);
