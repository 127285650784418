
export const loadFreshChat = (email?: string, mobile?: string, trackEvent?:()=>void): void => {
    const freshchatScript = document.createElement('script');
    freshchatScript.setAttribute('src', `${process.env.REACT_APP_FRESHCHAT_SRC_URL}`);
    freshchatScript.onload = function() {
        window.fcWidget.init({
            token: `${process.env.REACT_APP_FRESHCHAT_TOKEN}`,
            host: `${process.env.REACT_APP_FRESHCHAT_HOST}`,
            widgetUuid: `${process.env.REACT_APP_FRESHCHAT_WIDGET_UUID}`,
            tags: ['super_top_up']
        });
        window.fcWidget.user.setProperties({
            userEmail: email,
            userMobile: mobile
        });
        window.fcWidget.on('widget:opened', () => {
            trackEvent && trackEvent();
        });
    };
    document.head.appendChild(freshchatScript);
};

export const destroyFreshChat = (): void => {
    window.fcWidget.destroy();
};

export const openFreshChat = (): void => {
    window.fcWidget.open();
};
