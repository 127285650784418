import React from 'react';
import { StyledContainer, StyledContent } from './styles';
import { InsuranceCardHeader } from '../../containers';
import { IEnrolmentCard } from './types';

const EnrolmentCard: React.FunctionComponent<IEnrolmentCard> = ({ children, ...insuranceCardHeaderProps }) => {
    return (
        <StyledContainer>
            <InsuranceCardHeader
                {...insuranceCardHeaderProps}
                isEnrolPending={false}
                displayForEnrolment={true}
                policyImage={insuranceCardHeaderProps.policyImage}
            />
            <StyledContent>{children}</StyledContent>
        </StyledContainer>
    );
};

export default EnrolmentCard;
