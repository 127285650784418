import styled from 'styled-components';

export const StyledContainer = styled.div<{ $isActive: boolean }>(
    (p) => `
    background-color: ${p.$isActive ? p.theme.colors.primaryTransparent : '#f6f9fb'};
    padding: 24px;
    border-radius: 12px;
    border: 1px solid ${p.$isActive ? p.theme.colors.primary : 'transparent'};
    margin-bottom: 24px;
    box-sizing: border-box;
    -webkit-box-sizing:border-box;
    -moz-box-sizing: border-box;
    @media (max-width: 550px) {
        padding: 16px;
    }
`
);

export const StyledContainerHeader = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
`;

export const StyledTopUpWrapper = styled.div`
    @media (max-width: 550px) {
        margin-top: 30px;
    }
`;

export const StyledPremiumWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 30px;
`;

export const StyledTopUpTitleText = styled.h1(
    (p) => `
    font-size: ${p.theme.fontSizes.subHeading};
    font-weight: bold;
    color: ${p.theme.colors.black};
    margin: 0px;
`
);

export const StyledTopupAmountText = styled.p(
    (p) => `
    font-size: ${p.theme.fontSizes.label};
    color: ${p.theme.colors.mutedBG};;
    font-weight: 600;
    margin-bottom: 16px;
    margin-top: 0px;
`
);
export const StyledPremiumTitleText = styled.p(
    (p) => `
    font-size: ${p.theme.fontSizes.label};
    color: ${p.theme.colors.mutedBG};;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 0px;
`
);

export const StyledTopupButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    @media (max-width: 550px) {
        display: none;
    }
`;

export const StyledPremiumText = styled.p(
    (p) => `
    font-size: ${p.theme.fontSizes.label};
    color: ${p.theme.colors.mutedBG};;
    font-weight:400;
    margin:0;
`
);

export const StyledTopUpCoversText = styled.p(
    (p) => `
    font-size: ${p.theme.fontSizes.label};
    color: ${p.theme.colors.mutedBG};
    margin: 10px 0px;
    margin-bottom: 0px;
`
);
export const StyledDiscountContainer = styled.div<{
    $src: string;
}>`
    margin-left: 6px;
    width: 183px;
    display: flex;
    flex-direction: row;
    padding: 4px 10px 5px 15px;
    background-position: center;
    justify-content: center;
    align-items: center;
    background-image: url(${(p) => p.$src});
    background-repeat: no-repeat;
    margin-top: -10px;
`;
export const StyledMobileOnly = styled.div`
    display: none;
    width: 100%;
    @media (max-width: 550px) {
        display: block;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        margin-bottom: 15px;
    }
`;
export const StyledDiscountText = styled.span(
    (p) => `
    font-size: ${p.theme.fontSizes.label};
    color: ${p.theme.colors.white};
    margin: 0;
    width: 182;
    height: 24;
`
);
export const StyledDeleteImage = styled.img`
    height: 14px;
    width: 14px;
    margin-right: 6px;
`;
export const StyledRemoveTagWrapper = styled.div`
    position: relative;
`;
export const StyledRemoveTag = styled.span(
    (p) => `
    color: ${p.theme.colors.primaryBG};
    font-size: ${p.theme.fontSizes.label};
    cursor: pointer;
    position: absolute;
    right: 0;
    bottom: -25px;
    width: 80px;
    display: flex;
    align-items: center;
    font-weight: bold;
    @media (max-width: 550px) {
        bottom: -46px;
    }
`
);
export const StyledVerticalDivider = styled.img`
    margin: 0 32px;
    @media (max-width: 768px) {
        display: none;
    }
`;

export const StyledTitleAmount = styled.div<{ $isActive: boolean }>(
    (p) => `
    line-height: 22px;
    font-size: ${p.theme.fontSizes.heading};
    font-weight: bold;
    color: ${p.$isActive ? p.theme.colors.white : p.theme.colors.black};
`
);
export const StyledAmount = styled.p(
    (p) => `
    margin:0;
    margin-bottom: 8px;
    font-weight: 600;
    font-size: ${p.theme.fontSizes.heading};
    color: black;
    @media (max-width: 550px) {
        font-size: ${p.theme.fontSizes.title};
    }`
);
export const AnnualPremiumContainer = styled.div`
    display: flex;
    position: relative;
`;
