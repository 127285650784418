import React from 'react';
import {
    StyledContainer,
    StyledInsuranceImage,
    InsuranceDetailsWrapper,
    StyledDaysLeftText,
    StyledHeading,
    StyledSubheading
} from './styles';
import { ChevronRightIcon, DefaultInsuranceIcon } from '../../../assets/img';
import { IPendingInsuranceCard } from './types';

const PendingInsuranceCard: React.FunctionComponent<IPendingInsuranceCard> = ({
    daysLeft,
    policyImage,
    policyName,
    policyType
}) => {
    return (
        <StyledContainer>
            <StyledInsuranceImage
                src={policyImage || DefaultInsuranceIcon}
                alt={'Insurance Image'}
            />
            <InsuranceDetailsWrapper>
                <StyledHeading>{policyName}</StyledHeading>
                <StyledSubheading>{policyType}</StyledSubheading>
                <StyledDaysLeftText>{daysLeft}</StyledDaysLeftText>
            </InsuranceDetailsWrapper>
            <img src={ChevronRightIcon} alt="enrol in insurance" />
        </StyledContainer>
    );
};

export default PendingInsuranceCard;
