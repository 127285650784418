import React from 'react';
import {
    StyledTableCell,
    DisplayOnlyMobile,
    StyledTableCellName,
    StyledTableRow,
    WrapperDiv
} from './styles';

const TableHeader: React.FunctionComponent = () => {
    return (
        <StyledTableRow>
            <StyledTableCellName>
                <WrapperDiv>
                    <DisplayOnlyMobile>Enrolled</DisplayOnlyMobile>Members
                </WrapperDiv>
            </StyledTableCellName>
            <StyledTableCell>Relationship</StyledTableCell>
            <StyledTableCell>Gender</StyledTableCell>
            <StyledTableCell>Date of Birth</StyledTableCell>
            <StyledTableCell>Mobile</StyledTableCell>
        </StyledTableRow>
    );
};

export default TableHeader;
