export const COMPANY_ID = ['LPE-NVNJXV', '10b82559-f48f-41fd-8388-0af97c8fbd3f'];

export const COMPANY_ID_ESCP = ['10b82559-f48f-41fd-8388-0af97c8fbd3f'];

export interface SlabCheckCompany {
    id: string;
    slabs: number;
}

export const SLAB_CHECK_COMPANY_ID: SlabCheckCompany[] = [
    { id: 'LPE-ATM46E', slabs: 1000000 },
    { id: '23', slabs: 1000000 },
    { id: 'LPE-FUQFEK', slabs: 1000000 },
    { id: 'LPE-9GU1DC', slabs: 750000 },
    { id: 'LPE-2CXQA2', slabs: 1000000 },
    { id: 'LPE-UV0DJB', slabs: 1000000 },
    { id: 'LPE-P7QX5Z', slabs: 750000 },
    { id: '10b82559-f48f-41fd-8388-0af97c8fbd3f', slabs: 1000000 },
    { id: 'LPE-0ET1I1', slabs: 1000000 },
    { id: 'LPE-B1DFEM', slabs: 1000000 },
    { id: 'LPE-X82GSM', slabs: 1000000 },
    { id: 'LPE-Y8TJKV', slabs: 1000000 }
];

export const SHOW_DISCLAIMER = ['LPE-5F6KX4', 'LPE-OW2WV4'];
