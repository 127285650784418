import styled from 'styled-components';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    @media (max-width: 768px) {
        overflow-y: auto;
        margin: 30px 0px;
    }
    .leadpage-input {
        width: 100%;
        box-shadow: 0px 4px 10px rgba(149, 159, 190, 0.1);
        border-radius: 8px;
        padding: 9px;
        box-sizing: border-box;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #3d4144;
        background: #edeff3;
        border: 0px;
    }
    .react-date-picker__wrapper {
        border: 0;
    }
    .date-picker-error {
        border: 1px solid ${(p) => p.theme.colors.error};
    }
`;
export const StyledRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    @media (max-width: 768px) {
        flex-direction: column;
        width: 90%;
        justify-content: center;
    }
`;
export const StyledInputGroup = styled.div`
    position:: relative;
    margin-right: 30px;
    &:last-child {
        margin-right: 0px;
    }
    margin-bottom: 30px;
    max-width: 50%;
    min-width: 300px;
    @media (max-width: 768px) {
        min-width: 100%;
        margin-right: 0px;
    }
`;

export const StyledSelectOption = styled.div`
    /* margin-right: 15px; */
    /* margin-bottom: 40px; */
    width: 100%;
    min-width: 300px;
    @media (max-width: 768px) {
        min-width: 100%;
        margin-right: 0px;
    }
`;
export const StyledLabel = styled.div(
    (p) => `
    margin-bottom: 15px;
    color: ${p.theme.colors.muted};
    font-size: ${p.theme.fontSizes.label};
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.05em;
    display:flex;
`
);

export const StyledSpan = styled.div(
    (p) => `
    color: ${p.theme.colors.error};
    margin-left:2px;
`
);

export const StyledMobileWrapper = styled.div(
    (p) => `
    color: ${p.theme.colors.error};
    display:flex;
`
);

export const StyledCountryCode = styled.span(
    (p) => `
    color: ${p.theme.colors.mutedBG};
    width:70px;
    text-align:center;
    background-color: #edeff3;
    font-size: ${p.theme.fontSizes.label};
    line-height:50px;
    border-radius: 6px;
    margin-right:8px;
`
);

export const StyledHeading = styled.div(
    (p) => `
    margin-bottom: 20px;
    color: ${p.theme.colors.black};
    font-size: ${p.theme.fontSizes.subHeading};
    font-weight: 700;
    letter-spacing: 0.05em;
    width:100%;
`
);

export const StyledRowButtons = styled.div`
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;
    }
`;
export const StyledErrorText = styled.div(
    (p) => `
    color: ${p.theme.colors.error};
    font-size: ${p.theme.fontSizes.label};
    margin-bottom:10px;
`
);
export const StyledErrorMsg = styled.div(
    (p) => `
    color: ${p.theme.colors.error};
    font-size: ${p.theme.fontSizes.label};
    margin-bottom:10px;
`
);
export const CancelButton = styled.div(
    (p) => `
    color: ${p.theme.colors.primary};
    font-size: ${p.theme.fontSizes.body};
    border: 1px solid ${p.theme.colors.primary};
    font-weight: 700;
    min-width: 150px;
    cursor: pointer;
    border-radius: 8px;
    padding: 14px 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    @media (max-width: 768px) {
        margin-right: 0px;
        width: 70%;
    }
`
);
export const SaveButton = styled.div(
    (p) => `
    color: ${p.theme.colors.white};
    font-size: ${p.theme.fontSizes.body};
    border: 1px solid ${p.theme.colors.primary};
    background-color: ${p.theme.colors.primary};
    font-weight: 700;
    min-width: 150px;
    cursor: pointer;
    border-radius: 8px;
    padding: 14px 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
        margin-top: 15px;
        width: 70%;
    }
`
);
export const StyledSelect = styled.select<{
    $src: string;
    $color: boolean;
    $error: boolean;
}>`
    width: 100%;
    box-shadow: 0px 4px 10px rgba(149, 159, 190, 0.1);
    border-radius: 8px;
    padding: 15px;
    box-sizing: border-box;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: ${(p) => (p.$error ? p.theme.colors.error : p.$color ? p.theme.colors.muted : p.theme.colors.black)};
    border: 0px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #edeff3 url(${(p) => p.$src}) no-repeat scroll 95% center;
    ${(p) => (p.$error ? `border: 1px solid ${p.theme.colors.error}` : '')}
`;

export const StyledDividerContainer = styled.div`
    width: 100%;
    margin: 10px;
`;
