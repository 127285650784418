import React from 'react';
import { ReduxState } from '../../../redux';
import { useSelector } from 'react-redux';
import {
    StyledContainer,
    StyledTable,
    StyledTableContainer,
    StyledTablePaddingContainer,
    StyledPremiumTablePaddingContainer,
    StyledTableCell
} from './styles';

import { PremiumBreakdown } from '..';
import { ISummaryCard } from './types';
import TopUpTableHeader from '../../atoms/TopUpTableHeader';
import TopUpTableRow from '../../atoms/TopUpTableRow';
import { StyledTableCell as StyledTableHeaderCell, StyledTableRow } from '../../atoms/TableHeader/styles';
import { fetchNomineesAPI } from '../../../adapters';
import { NomineeData } from '../ManageNomineesComponent/types';
import { defaultDateFormat } from '../../../utils/common';
import { getEnrolledPolicyCompany } from '../../../utils/PolicyServices';

const SummaryCard: React.FunctionComponent<ISummaryCard> = ({
    selectedMemberTopUp,
    dependents,
    ...insuranceProps
}) => {
    const currentlySelectedPolicy = useSelector((state: ReduxState) => state.enrolment.currentlySelectedPolicy);
    const checkIfSuperTopUpSelected = useSelector((state: ReduxState) => state.applicationState.isSuperTopupSelected);
    const topupPolicyData = useSelector((state: ReduxState) => state.TopUp_policyData.topupPolicyDetails.data);
    const topUpAmount: any = useSelector((state: ReduxState) => state.TopUp_policyData.memberTopUp);
    const policyList = useSelector((state: ReduxState) => state.policyList.policyList.data) || [];
    const userData = useSelector((state: ReduxState) => state.user.userData.data);
    const [nominees, setNominees] = React.useState<NomineeData[]>([]);
    const getEnrolledGMCPolicies = getEnrolledPolicyCompany(policyList);
    const isNomineeEnabledForCompany =
        useSelector((state: ReduxState) => state.stepperData.nomineeConfig.data?.isEnabled);
    React.useEffect(() => {
        if (isNomineeEnabledForCompany && userData && userData.userId) {
            (async (uId) => {
                const nominees = await fetchNomineesAPI(uId, currentlySelectedPolicy?.policyId || '');
                setNominees(nominees);
            })(userData.userId);
        }
    }, []);
    return (
        <>
            <StyledContainer>
                <StyledTablePaddingContainer>
                    <StyledTableContainer>
                        <StyledTable>
                            <TopUpTableHeader
                                isTopUpAvailable={checkIfSuperTopUpSelected}
                                topUpPolicyLogo={checkIfSuperTopUpSelected ? topupPolicyData?.policyLogo : ''}
                                gmcLogo={currentlySelectedPolicy?.policyImage}
                                gmcPolicyName={currentlySelectedPolicy?.policyName}
                                topUpPolicyName={topupPolicyData?.policyName}
                                membersCount={dependents.length}
                                isGMCEnrolled={getEnrolledGMCPolicies.length ? true : false}
                            />
                            {dependents.map((dep, index) => (
                                <TopUpTableRow
                                    index={index}
                                    isTopUpSelected={checkIfSuperTopUpSelected}
                                    topUpSummaryRow={false}
                                    showBorder={index !== dependents.length}
                                    key={`${insuranceProps.insuranceName}-${index}`}
                                    {...dep}
                                    isGMCEnrolled={getEnrolledGMCPolicies.length ? true : false}
                                />
                            ))}
                            <TopUpTableRow
                                isTopUpSelected={checkIfSuperTopUpSelected}
                                topUpSummaryRow={true}
                                showBorder={false}
                                gmcAnnualPremium={currentlySelectedPolicy?.annualPremium}
                                topUpAnnualPremium={topUpAmount?.annualPremium || 0}
                                isGMCEnrolled={getEnrolledGMCPolicies.length ? true : false}
                            />
                        </StyledTable>
                    </StyledTableContainer>
                </StyledTablePaddingContainer>
            </StyledContainer>
            {(nominees.length && (
                <StyledContainer>
                    <StyledTablePaddingContainer>
                        <StyledTableContainer>
                            <StyledTable>
                                <StyledTableRow>
                                    <StyledTableHeaderCell>Nominee</StyledTableHeaderCell>
                                    <StyledTableHeaderCell>Relationship</StyledTableHeaderCell>
                                    <StyledTableHeaderCell>Gender</StyledTableHeaderCell>
                                    <StyledTableHeaderCell>Date of Birth</StyledTableHeaderCell>
                                    <StyledTableHeaderCell>Percentage SI (%)</StyledTableHeaderCell>
                                </StyledTableRow>
                                {nominees.map((nominee, index) => (
                                    <StyledTableRow key={index}>
                                        <StyledTableCell $showBottomPadding={true}>
                                            {nominee.firstName + ' ' + nominee.lastName}
                                        </StyledTableCell>
                                        <StyledTableCell $showBottomPadding={true}>
                                            {nominee.relationship}
                                        </StyledTableCell>
                                        <StyledTableCell $showBottomPadding={true}>{nominee.gender}</StyledTableCell>
                                        <StyledTableCell $showBottomPadding={true}>
                                            {defaultDateFormat(nominee.dob.getTime() / 1000)}
                                        </StyledTableCell>
                                        <StyledTableCell $showBottomPadding={true}>
                                            {nominee.percentage}%
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </StyledTable>
                        </StyledTableContainer>
                    </StyledTablePaddingContainer>
                </StyledContainer>
            )) || <></>}
            {(selectedMemberTopUp &&
                selectedMemberTopUp?.length > 0 &&
                currentlySelectedPolicy?.annualPremium &&
                currentlySelectedPolicy?.annualPremium > 0 && (
                <StyledContainer>
                    <StyledPremiumTablePaddingContainer>
                        {/* TODO: Pass value Dynamically after API Integration / Breakdown Calculations */}
                        <PremiumBreakdown
                            topupList={selectedMemberTopUp}
                            selectedPolicyPremiums={{
                                annualPremium: currentlySelectedPolicy?.annualPremium || 0,
                                annualPremiumWithoutGST: currentlySelectedPolicy?.annualPremiumWithoutGST || 0,
                                gstOfAnnualPremium: currentlySelectedPolicy?.gstOfAnnualPremium || 0
                            }}
                        />
                    </StyledPremiumTablePaddingContainer>
                </StyledContainer>
            )) || <></>}
        </>
    );
};

export default SummaryCard;
