import React from 'react';
import { StyledBox } from './styles';
import { INameIcon } from './types';

const NameIcon: React.FunctionComponent<INameIcon> = ({ firstName, lastName, color }) => {
    const [letters, setLetters] = React.useState('');
    React.useEffect(() => {
        const first = firstName.trim();
        const last = lastName.trim();
        if (first && last) {
            setLetters(`${first[0]}${last[0]}`.trim().toUpperCase());
        } else {
            setLetters(`${first[0]}`.trim().toUpperCase());
        }
    }, [firstName, lastName]);
    return <StyledBox $color={color}>{letters}</StyledBox>;
};

export default NameIcon;
