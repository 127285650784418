import styled from 'styled-components';

export const StyledContainer = styled.div`
    width: 84%;
    display: flex;
    flex-direction: column;
    padding: 0px 5%;
    margin-bottom: 50px;
`;

export const StyledDetailsSection = styled.div`
    /* width: 64%; */
    min-height: 300px;
    background-color: ${(p) => p.theme.colors.lightGrey};
    margin-right: auto;
    padding: 24px 30px;
    border-radius: 16px;
`;

export const StyledSection = styled.div`
    width: 67%;
    /* min-height: 300px; */
    margin-right: auto;
    /* padding: 0% 2%; */
`;

export const StyledOrderSection = styled.div`
    width: 28%;
    min-height: 300px;
    /* border-left: 1px solid ${(p) => p.theme.colors.black}; */
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    padding-left: 2%;
`;

export const StyledOrderHeadingWrapper = styled.div``;

export const StyledOrderHeading = styled.h1`
    font-size: ${(p) => p.theme.fontSizes.heading};
    color: ${(p) => p.theme.colors.loopEmerald};
    font-style: normal;
    font-weight: 500;
`;

export const StyledOrderWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledPremiumLabel = styled.label`
    font-style: normal;
    font-weight: 400;
    font-size: ${(p) => p.theme.fontSizes.body};
    color: ${(p) => p.theme.colors.beyondGrey};
`;

export const StyledPremiumSpan = styled.span`
    letter-spacing: -0.02em;
    font-style: normal;
    font-weight: 500;
    font-size: ${(p) => p.theme.fontSizes.heading};
    color: ${(p) => p.theme.colors.beyondGrey};
`;

export const StyledTotalAmountWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    border-top: 1px solid #dbdbdb;
    border-bottom: 1px solid #dbdbdb;
    padding: 15px 0px;
    margin: 20px 0px;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const StyledTotalAmountLabel = styled.label`
    font-style: normal;
    font-weight: 400;
    font-size: ${(p) => p.theme.fontSizes.body};
    color: ${(p) => p.theme.colors.beyondGrey};
`;

export const StyledTotalAmountSpan = styled.span`
    letter-spacing: -0.02em;
    font-style: normal;
    font-weight: 500;
    line-height: 54px;
    font-size: ${(p) => p.theme.fontSizes.landing};
    color: ${(p) => p.theme.colors.loopEmerald};
`;

export const StyledGSTWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const StyledHeadingSection = styled.div`
    text-align: center;
`;

export const StyledHeadingText = styled.h1`
    font-size: ${(p) => p.theme.fontSizes.landing};
    color: ${(p) => p.theme.colors.loopEmerald};
    font-style: normal;
    font-weight: 400;
`;

export const StyledWrapper = styled.div`
    display: flex;

    @media (max-width: 768px) {
        flex-direction: column;
        margin-bottom: 100px;
    }
`;

export const StyledTopUpPolicyCardWrapper = styled.div`
    margin: 30px 0px;
`;

export const StyledMemberWrapper = styled.div``;

export const StyledMemberHeading = styled.div``;

export const StyledMemberHeadingSpan = styled.span`
    color: ${(p) => p.theme.colors.beyondGrey};
`;

export const StyledNameContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: start;
    /* column-gap: 20px; */
`;

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 768px) {
        width: 100%;
        margin-top: 25px;
    }
`;

export const SaveButton = styled.button(
    (p) => `
    color: ${p.theme.colors.loopEmerald};
    font-size: ${p.theme.fontSizes.body};
    background-color: ${p.theme.colors.coverUpGreen};
    font-weight: 700;
    width:100%;
    cursor: pointer;
    border-radius: 16px;
    padding: 18px 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    border:none;
    @media (max-width: 768px) {
        padding: 14px 3px;
        width: 70%;
        min-width: 45%;
    }
`
);

export const StyledDiscountSpan = styled.span`
    letter-spacing: -0.02em;
    font-style: normal;
    font-weight: 500;
    margin: 0px 15px;
    font-size: ${(p) => p.theme.fontSizes.heading};
    color: ${(p) => p.theme.colors.beyondGrey};
`;

export const StyledPremiumWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 15px 0px;
    background: ${(p) => p.theme.colors.lightGrey};
    border-radius: 16px;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;
