import styled from 'styled-components';

export const StyledContainer = styled.div<{ $isCardClick: boolean }>`
    box-shadow: 0px -2px 14px rgba(168, 196, 206, 0.1);
    border-radius: 16px;
    background-color: ${(p) => p.theme.colors.white};
    ${(p) => (p.$isCardClick ? 'cursor: pointer;' : '')}
    @media (max-width: 550px) {
        border-radius: 0px;
    }
`;
export const StyledTablePaddingContainer = styled.div`
    overflow: hidden;
    width: 100%;
    padding: 20px;
    padding-top: 0px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    @media (max-width: 550px) {
        padding: 0px 20px 20px 20px;
    }
`;
export const StyledTableContainer = styled.div`
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    background-color: ${(p) => p.theme.colors.secondaryBG};
    position: relative;
    cursor: pointer;
    @media (max-width: 550px) {
        padding: 0px 20px 20px 20px;
    }
`;
export const StyledTable = styled.div`
    display: table;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: fixed;
    border-radius: 8px;
`;
export const StyledImage = styled.img<{ $isCollapsed: boolean; $showMobile: boolean }>(
    (p) => `
    cursor: pointer;
    @media (max-width: 768px) {
            display: flex;
    };
    ${p.$isCollapsed ? 'transform: scaleY(-1);' : ''}
    ${
    p.$showMobile
        ? `display: none`
        : `@media (max-width: 768px) {
            display: none
        }`
}
`
);

export const ImageContainer = styled.div`
    position: absolute;
    cursor: pointer;
    top: 15px;
    right: 20px;
    z-index: 1000000;
    @media (max-width: 768px) {
        top: 15px;
        right: 8px;
    }
`;
export const StyledOverdueContainer = styled.div(
    (p) => `
    padding: 8px;
    color: ${p.theme.colors.error};
    background-color: ${p.theme.colors.errorBG};
    border: 1px solid ${p.theme.colors.error};
    font-size: ${p.theme.fontSizes.label};
    font-weight: 600;
    border-radius: 4px;
`
);
export const StyledOverdueWrapper = styled.div`
    display: flex;
    padding: 0px 25px;
    padding-top: 30px;
`;

export const StyledEnrolledMemberWrapper = styled.div<{ $isCollapsed: boolean }>(
    (p) => `
    padding: 0px 0px 20px 0px
    box-sizing: border-box;
    @media (max-width: 550px) {
        ${p.$isCollapsed ? 'padding: 0px' : 'padding: 0px 0px 20px 0px'}
    }
`
);

export const StyledEnrolledMemberContainer = styled.div<{ $isCollapsed: boolean }>(
    (p) => `
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    padding: 35px 20px 20px 20px;
    border-radius: 6px;
    @media (max-width: 550px) {
        padding: 15px;
        margin: 0px 20px;
        ${
    p.$isCollapsed
        ? `
        padding: 15px 15px 0px 15px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;`
        : ''
}
        background-color: ${p.theme.colors.secondary};
    }
`
);

export const StyledEnrolledMemberHeading = styled.div(
    (p) => `
    font-size: ${p.theme.fontSizes.body};
    font-weight: 600;
`
);

export const StyledEnrolledMemberButton = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const StyledEnrolledMemberButtonText = styled.div(
    (p) => `
    font-size: ${p.theme.fontSizes.label};
    font-weight: 600;
    color: ${p.theme.colors.primary};
    @media (max-width: 550px) {
       display: none;
    }
`
);

export const StyledEnrolledContainer = styled.div(
    (p) => `
    padding: 8px;
    color: ${p.theme.colors.loopEmerald};
    background-color: ${p.theme.colors.mutedTransparent};
    border: 1px solid ${p.theme.colors.mutedTransparent};
    font-size: ${p.theme.fontSizes.label};
    font-weight: 600;
    border-radius: 4px;
`
);
