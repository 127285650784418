import styled from 'styled-components';

export const StyledInsuranceCardHeaderHeading = styled.div(
    (p) => `
    letter-spacing: 0.05em;
    font-weight: 500;
    text-transform: uppercase;
    color: ${p.theme.colors.muted};
    font-size: ${p.theme.fontSizes.label};
    text-align: left;
`
);

export const StyledInsuranceCardHeaderContainer = styled.div`
    min-width: 100px;
    margin: 25px 25px 0px 25px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    @media (max-width: 550px) {
        margin: 10px 25px;
    }
`;

export const StyledInsuranceCardHeaderContent = styled.div<{ $displayForEnrolment: boolean }>(
    (p) => `
    ${
    p.$displayForEnrolment
        ? `display: flex;
         flex-direction: column;
    `
        : ``
}
    position: relative;
    margin-top: 7px;
    font-weight: 600;
    color: ${p.theme.colors.black};
    font-size: ${p.theme.fontSizes.body};
`
);

export const StyledCardHeaderErrorText = styled.span<{ $displayForEnrolment: boolean }>(
    (p) => `
    margin-left:10px;
    color: ${p.theme.colors.error};
    font-weight: 600;
    font-size: ${p.theme.fontSizes.label};
    width: 100%;
    line-height: 12px;
    ${
    p.$displayForEnrolment
        ? `
        position: absolute;
        bottom: -42px;
        left: 0;
    `
        : ``
}
`
);
