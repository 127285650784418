import styled from 'styled-components';

export const CoveredMemberContainer = styled.div<{
    $color: string;
}>`
    background-color: ${(p) => (p.$color === 'white' ? p.theme.colors.white : p.theme.colors.lightGrey)};
    display: flex;
    margin-top: 12px;
    flex-direction: row;
    padding: 13px 0px 13px 0px;
    border-radius: 12px;
    min-width: 200px;
    width: ${(p) => (p.$color === 'white' ? '100%' : '')};
    @media (max-width: 768px) {
        width: 90%;
    }
}
`;
export const MemberNameContainer = styled.div`
    margin-left: 10px;
    display: flex;
    flex-direction: column;
`;

export const StyledRelationText = styled.span`
    color: ${(p) => p.theme.colors.beyondGrey};
    font-size: ${(p) => p.theme.fontSizes.label};
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.02em;
    margin-top: 3px;
`;

export const StyledNameText = styled.span`
    color: ${(p) => p.theme.colors.beyondGrey};
    font-style: normal;
    font-weight: 500;
    width: 112px;
    font-size: ${(p) => p.theme.fontSizes.body};
    letter-spacing: -0.02em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;
