import { IEnrolmentSlice } from './types';

export const initialState: IEnrolmentSlice = {
    currentlySelectedPolicy: null,
    // crud dependents is used to temp get data from apis, when doing crud operations on dependents
    crudDependents: {
        data: null,
        error: null,
        loading: false
    },
    topUpOptions: {
        data: null,
        error: null,
        loading: false
    },
    updateEnrolmentStatus: {
        data: null,
        error: null,
        loading: false
    },
    updateTopUpOption: {
        data: false,
        loading: false,
        error: null
    },
    selectedMemberTopUp: null,
    policyDetails: {
        data: null,
        loading: false,
        error: null
    },
    superTopUpPolicy: {
        data: null,
        loading: false,
        error: null
    },
    enrolUserFamily: {
        data: null,
        loading: false,
        error: null
    }
};
