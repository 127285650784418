import { FirebaseRemoteConfig } from '../../../adapters/provider';
import { getSumInsured } from '../../../utils/common';
import { SlabCheckCompany } from '../../../utils/constants/CompanyConstants';

export const validateSI = (sumInsured: string, companyId: string): string => {
    const companyIDs = FirebaseRemoteConfig.getValue('CompaniesSILimit').asString();
    const companyIDsArray: SlabCheckCompany[] = companyIDs && JSON.parse(companyIDs);
    const companyEntryRemote = companyIDsArray && companyIDsArray?.find((entry) => entry.id === companyId);
    // const companyEntry = SLAB_CHECK_COMPANY_ID.find((entry) => entry.id === companyId);
    if (companyEntryRemote) {
        return getSumInsured(companyEntryRemote.slabs, '');
    }
    return sumInsured;
};
