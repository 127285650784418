import styled from 'styled-components';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @media (max-width: 768px) {
        margin-top: 20px;
    }
    @media (max-height: 550px) and (orientation: landscape) {
        margin-top: 20px;
    }
`;
export const StyledInsuranceDetailContainer = styled.div`
    min-width: 130px;
    margin: 25px;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    @media (max-width: 550px) {
        margin: 10px 25px;
    }
`;
export const StyledInsuranceDetailHeading = styled.div(
    (p) => `
    letter-spacing: 0.05em;
    font-weight: 600;
    text-transform: uppercase;
    color: ${p.theme.colors.muted};
    font-size: ${p.theme.fontSizes.label};
    text-align: left;
`
);
export const StyledInsuranceDetailContent = styled.div(
    (p) => `
    position: relative;
    margin-top: 7px;
    font-weight: bold;
    color: ${p.theme.colors.black};
    font-size: ${p.theme.fontSizes.body};
`
);
