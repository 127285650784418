import React from 'react';
import { StyledContainer, StyledImage } from './styles';
import { WarningIcon } from '../../../assets/img';
import { IEmployeeContributionNotice } from './types';

const EmployeeContributionNotice: React.FunctionComponent<IEmployeeContributionNotice> = ({ showBG = true }) => {
    return (
        <StyledContainer $showBG={showBG}>
            <StyledImage src={WarningIcon}/>
            Employee contribution will be deducted from your salary
        </StyledContainer>
    );
};

export default EmployeeContributionNotice;
