import React from 'react';
import {
    StyledModalContainer,
    StyledTitleContainer,
    StyledContentText,
    StyledButtonContainer,
    StyledFeedbackWrapper,
    StyledContentImage,
    StyledSuccessTextBTN,
    StyledBTNWrapper
} from './styles';
import { Modal } from '../../atoms';
import { IFeedbackModal } from './types';
import { FeedbackIcon, IosIcon, PlayStoreIcon } from '../../../assets/img';
import { redirect } from '../../../utils/common';
import { REDIRECT_URL } from '../../../utils/constants/RedirectConstants';
import { SEGMENT_ACTIONS } from '../../../utils/constants/SegmentActionConstants';
import useSegment from '../../../../utils/hooks/useSegment';

const FeedbackSuccessModal: React.FunctionComponent<IFeedbackModal> = ({ isVisible, setIsVisible }) => {
    const trackClick = useSegment('click');
    const redirectToStore = (url: string) => {
        const segmentAction = SEGMENT_ACTIONS.CLICK.CONFIRMATION_REDIRECT_TO_STORE;
        const store = url === REDIRECT_URL.APP_STORE ? 'App' : 'Play';
        trackClick({
            name: segmentAction.name,
            properties: {
                describe: (segmentAction.properties?.describe as (store: string) => string)(store) || '',
                store: (segmentAction.properties?.store as (store: string) => string)(store) || ''
            }
        });
        redirect(url);
    };
    return (
        <Modal setIsVisible={setIsVisible} isVisible={isVisible}>
            <StyledModalContainer>
                <StyledTitleContainer>Thank you for your feedback :) </StyledTitleContainer>

                <StyledFeedbackWrapper>
                    <StyledContentImage src={FeedbackIcon} />
                </StyledFeedbackWrapper>
                <StyledContentText>
                    Download the Loop App to view your policy and top up details today!
                </StyledContentText>

                <StyledButtonContainer>
                    <StyledBTNWrapper>
                        <StyledSuccessTextBTN onClick={() => redirectToStore(REDIRECT_URL.PLAY_STORE)}>
                            <img src={PlayStoreIcon} />
                            Download for Android
                        </StyledSuccessTextBTN>
                        <StyledSuccessTextBTN onClick={() => redirectToStore(REDIRECT_URL.APP_STORE)}>
                            <img src={IosIcon} />
                            Download for iOS
                        </StyledSuccessTextBTN>
                    </StyledBTNWrapper>
                </StyledButtonContainer>
            </StyledModalContainer>
        </Modal>
    );
};

export default FeedbackSuccessModal;
