import { INTERNAL_ROUTES, MAIN_ROUTES } from './constants/RouteConstants';
import { customAlphabet } from 'nanoid';
import { IDependent } from '../redux/slices/PolicyListSlice/types';
import { IFamilyComposition, RecommendedConfig, RecommendedSIElement } from './types';
const getMarginForButtons = (size: number, index: number, marginPoints: number, numOfColumns: number): string => {
    if (size === 1) {
        return '';
    } else if (numOfColumns % 2 === 0) {
        // Handle for even columns
        return `0px ${marginPoints}px 0px 0px`;
    } else if (numOfColumns % 2 === 1) {
        // Handle for odd columns
        if (index % numOfColumns === 1) {
            return `0px ${marginPoints}px 0px ${marginPoints}px`;
        }
    }
    return '';
};

const validateMobile = (phoneTxt: string): boolean => {
    if (!phoneTxt) {
        return false;
    }
    const regEx = /^[6789]\d{9}$/;
    return regEx.test(phoneTxt);
};

const validateEmail = (emailTxt: string): boolean => {
    if (!emailTxt) {
        return false;
    }
    const regEx = /^[^ ]+@[^ ]+\.[a-z]{2,3}$/;
    return regEx.test(emailTxt);
};

export const enrollmentDaysLeft = (date: number, days = 30): number => {
    const dateToFormat = new Date(date * 1000);
    const today = new Date();
    const diffTime = +today - +dateToFormat;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const daysLeft = isNaN(days - diffDays) ? 0 : days - diffDays;
    return daysLeft;
};

export const getDaysLeft = (date: number, type: string, days = 30): boolean => {
    const dateToFormat = new Date(date * 1000);
    const today = new Date();
    let diffTime;
    let diffDays;
    if (type === 'startDate') {
        diffTime = +today - +dateToFormat;
        diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (diffDays <= days) {
            return true;
        }
    }
    if (type === 'endDate') {
        diffTime = +dateToFormat - +today;
        diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (diffDays > 0) {
            return true;
        }
    }
    return false;
};

export const capitalizeFirstLetter = (name: string): string => {
    return name.charAt(0).toUpperCase() + name.slice(1);
};

export const getSumInsured = (sumInsured: string | number): string => {
    const sumInsuredValue = Number(sumInsured) / 100000;
    const sumInsuredString = sumInsuredValue !== 0 ? `${sumInsuredValue} Lakhs` : `N/A`;
    return `${sumInsuredString} 
            ${sumInsuredValue === 0 ? '' : ''} `.trim();
};
export const redirect = (url: string): void => {
    window.open(url, '_blank');
};

export const getGSTAmount = (amount: number): number => {
    return Number((amount * (18 / 100)).toFixed());
};

export const getDeductible = (sumInsured: number): number => {
    if (sumInsured >= 500000) {
        return 500000;
    } else if (sumInsured >= 300000 && sumInsured < 500000) {
        return 300000;
    } else if (sumInsured >= 200000 && sumInsured < 300000) {
        return 200000;
    } else {
        return 0;
    }
};

export const getAPWithoutGST = (amount: number): number => {
    return Number((amount / (1 + 18 / 100)).toFixed());
};

export const ageFromDateOfBirthday = (dateOfBirth: { _seconds: number }): number => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth?._seconds * 1000);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age;
};

export const getDiscountAmount = (amount: number, percentage: number): number => {
    return Number((amount * (percentage / 100)).toFixed());
};

export const getSegmentPageName = (pathName: string): string => {
    switch (pathName) {
    case MAIN_ROUTES.login:
        return 'LOGIN_PAGE';
    case INTERNAL_ROUTES.dashboard:
        return 'DASHBOARD_PAGE';
    case INTERNAL_ROUTES.summary:
        return 'SUMMARY_PAGE';
    default:
        return 'LANDING_PAGE';
    }
};

export const createLoopId = (prefix: string): string => {
    const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyz', 6);
    const documentId = `${prefix}-` + nanoid().toLocaleUpperCase();
    return documentId;
};

export const getFamilyStructure = (members: IDependent[]): string => {
    const relationships = members.map((item: { relationship: string }) => {
        if (item.relationship != 'parent' && item.relationship != 'parent-in-law' && item.relationship != 'child') {
            return `${capitalizeFirstLetter(item.relationship)}`;
        }
    });

    // remove empty values
    let relationshipString̉ = relationships.filter((item: string | undefined) => item != undefined).join(' + ');

    // count child in array
    const child = members.filter((item: { relationship: string }) => {
        if (item.relationship == 'child') {
            return item;
        }
    }).length;

    if (child > 0) {
        relationshipString̉ = `${relationshipString̉} + ${child} Kids`;
    }
    return relationshipString̉;
};

export { getMarginForButtons, validateMobile, validateEmail };

export const familyComposition = (users: IDependent[]): IFamilyComposition => {
    const selfUserCount = users.find((user: { relationship: string }) => user.relationship === 'self') ? 1 : 0;
    const spouseUserCount = users.find((user: { relationship: string }) => user.relationship === 'spouse') ? 1 : 0;
    const childUserCount = users.filter((user: { relationship: string }) => user.relationship === 'child').length;
    const parentUserCount = users.filter((user: { relationship: string }) => user.relationship === 'parent').length;
    const parentInLawUserCount = users.filter((user: { relationship: string }) => user.relationship === 'parent-in-law')
        .length;

    return {
        selfUserCount,
        spouseUserCount,
        childUserCount,
        parentUserCount,
        parentInLawUserCount
    };
};

export const getHighestAgeUser = (users: IDependent[]): number => {
    let maxAge = 0;
    const currentTimestamp = Date.now() / 1000; // convert milliseconds to seconds
    for (const user of users) {
        const age = Math.floor((currentTimestamp - user.doB) / (365.25 * 24 * 60 * 60)); // calculate age in years
        if (age > maxAge) {
            maxAge = age;
        }
    }
    return maxAge;
};

export const getRecommendedSIDetails = (
    policyType: string,
    highestAge: number,
    defaultConfig: RecommendedConfig | any,
    membersCount: number
): RecommendedSIElement => {
    const data = (defaultConfig?.recommendedSI?.[policyType]).find(
        (item: { AgeRange: number[]; FamilyMembers: number[] }) => {
            return (
                highestAge >= item.AgeRange[0] &&
                highestAge <= item.AgeRange[1] &&
                membersCount >= item.FamilyMembers[0] &&
                membersCount <= item.FamilyMembers[1]
            );
        }
    );
    return data;
};

export const getRecommendedTemplate = (userId: string, defaultConfig: RecommendedConfig | any): number => {
    const template = defaultConfig?.defaultTemplate;
    const templateOne = defaultConfig?.template1?.find((user: string) => user === userId);
    if (templateOne) {
        return 1;
    }
    const templateTwo = defaultConfig?.template2?.find((user: string) => user === userId);
    if (templateTwo) {
        return 2;
    }
    const templateThree = defaultConfig?.template3?.find((user: string) => user === userId);
    if (templateThree) {
        return 3;
    }
    return template;
};
