import styled from 'styled-components';

export const StyledContainer = styled.div<{
    $width?: string;
    $margin?: string;
}>`
    display: flex;
    background-color: #f7f7f7;
    border-radius: 8px;
    height: 100%;
    align-items: center;
    justify-content: center;
    min-width: 76px;
    width: ${(p) => (p.$width ? p.$width : '100%')};
    margin: ${(p) => (p.$margin ? p.$margin : '0px')};
`;
export const StyledLabel = styled.span`
    color: ${(p) => p.theme.colors.beyondGrey};
    font-weight: 400;
    font-size: ${(p) => p.theme.fontSizes.body};
    font-style: normal;
    font-weight: 500;
    display: flex;
`;
