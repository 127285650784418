import React from 'react';
import Loader from '../Loader';
import { IDialogProps } from './types';
import {
    StyledDialogIcon,
    StyledDescription,
    StyledDialogContainer, StyledTitle
} from './styles';
import LoopButton from '../Button';


const Dialog: React.FunctionComponent<IDialogProps> = ({
    icon,
    title,
    description,
    isLoading,
    primaryButtonText,
    onPrimaryButtonClicked,
    isSecondaryButton,
    secondaryButtonText,
    onSecondaryButtonClicked
}) => {
    return (
        <StyledDialogContainer>
            <StyledDialogIcon src={icon}/>
            <StyledTitle>{title}</StyledTitle>
            {description && <StyledDescription>{description}</StyledDescription>}
            <LoopButton type='primary' onClick={onPrimaryButtonClicked}>
                {isLoading ? <Loader/> : primaryButtonText}
            </LoopButton>
            {isSecondaryButton && onSecondaryButtonClicked ?
                <LoopButton type='secondary'
                    onClick={onSecondaryButtonClicked}>{secondaryButtonText}</LoopButton> : null}
        </StyledDialogContainer>
    );
};
export default Dialog;
