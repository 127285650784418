import React from 'react';
import { StyledDividerBorder, StyledDividerContainer, StyledDividerContent } from './styles';

const Divider: React.FunctionComponent = ({ children }) => {
    return (
        <StyledDividerContainer>
            <StyledDividerBorder />
            <StyledDividerContent>{children}</StyledDividerContent>
            <StyledDividerBorder />
        </StyledDividerContainer>
    );
};

export default Divider;
