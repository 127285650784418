import React from 'react';
import { StyledContainer, ButtonContainer } from './styles';
import { IEnrolmentValidityDetail } from './types';
import Button from '../Button';
import { getEnrolBtnTxt, showEnrolButton, showEnrolmentDueDateCard } from '../../../utils/EnrolmentServices';
import EnrolmentDueDateDetail from '../EnrolmentDueDateDetail';

const EnrolmentValidityDetail: React.FunctionComponent<IEnrolmentValidityDetail> = ({
    displayForEnrolment = false,
    isEnrolPending,
    onEnrolNowClick = () => null,
    enrolmentDueDate,
    enrolmentStatus,
    daysLeft,
    dependentsInsured,
    showMobile,
    insuranceCategory,
    superTopupStatus
}) => {
    const showEnrolmentDate = showEnrolmentDueDateCard(
        displayForEnrolment,
        enrolmentDueDate,
        insuranceCategory,
        enrolmentStatus
    );
    const renderEnrolNowButton = () => {
        if (showEnrolButton(isEnrolPending, insuranceCategory, enrolmentStatus)) {
            return (
                <ButtonContainer>
                    <Button
                        type={'primary'}
                        fullWidth={true}
                        onClick={(e) => onEnrolNowClick(e)}
                        disabled={dependentsInsured ? false : true}
                    >
                        {getEnrolBtnTxt(dependentsInsured, superTopupStatus, insuranceCategory)}
                    </Button>
                </ButtonContainer>
            );
        }
        return null;
    };
    return (
        <StyledContainer $showMobile={showMobile}>
            <EnrolmentDueDateDetail
                enrolmentDueDate={enrolmentDueDate?.trim() ? enrolmentDueDate : 'N/A'}
                daysLeft={daysLeft.trim() ? (daysLeft !== 'OVERDUE' ? daysLeft : '') : 'N/A'}
                displayForEnrolment={displayForEnrolment}
                showEnrolmentDate={showEnrolmentDate}
            />
            {renderEnrolNowButton()}
        </StyledContainer>
    );
};

export default EnrolmentValidityDetail;
