import { IPolicyListSlice } from './types';

export const initialState: IPolicyListSlice = {
    policyList: {
        data: null,
        loading: false,
        error: null
    },
    allDependents: {
        data: null,
        loading: false,
        error: null
    },
    companyDetails: {
        data: null,
        loading: false,
        error: null
    }
};

