import React from 'react';
import { StyledContainerUNAvailable, StyledContainerAvailable, StyledLoaderContainer } from './style';

import TopUpUnAvailable from '../../../../containers/TopUpUnAvailable';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../../../redux';
import { Loader } from '../../../../atoms';
import { getDaysLeft } from '../../../../../utils/common';
import TopUpAvailable from '../../../../containers/TopUpAvailable';
import { fetchPolicyBenefitsDetails } from '../../../../../redux/slices/PolicyListSlice/thunks';
import { Header } from '../../../../containers';
import { LoopGreenLogo } from '../../../../../assets/img';
import TopupSummary from '../../../../containers/TopupSummary';
import { fetchTopUpPolicyDetails } from '../../../../../redux/slices/PolicyListSlice';
import { TextView } from '../../../../atoms/TextView';
import theme from '../../../../../theme';
import { fetchFaqsData } from '../../../../../redux/slices/TopUpLandingSlice/thunk';
import { getAgeFromDOB } from '../../../../../../utils/common';

const DashboardPage: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state: ReduxState) => state.TopUp_policyData.policyList.loading);
    const topUpPolicyDetailsLoading = useSelector(
        (state: ReduxState) => state.TopUp_policyData.topupPolicyDetails.loading
    );
    const error = useSelector((state: ReduxState) => state.TopUp_policyData.policyList.error);
    const policyData: any = useSelector((state: ReduxState) => state.TopUp_policyData.policyList.data);
    const basePolicyData = policyData?.baseGmcPolicyData;
    const topPolicyData = policyData?.topUpPolicyData;
    const policyId = useSelector((state: ReduxState) => state.TopUp_applicationState.topupPolicyId);
    React.useEffect(() => {
        if (policyId) {
            dispatch(fetchTopUpPolicyDetails({ policyId }));
            dispatch(fetchFaqsData());
        }
    }, [policyId]);
    const topUpPolicyDetails = useSelector((state: ReduxState) => state.TopUp_policyData.topupPolicyDetails.data);
    const topUpAvailable = getDaysLeft(
        basePolicyData?.policyStartDate,
        'startDate',
        topUpPolicyDetails?.enrolmentThreshold || 30
    );
    const policyActive = getDaysLeft(basePolicyData?.policyEndDate, 'endDate');
    const userDob = useSelector((state: ReduxState) => state.TopUp_user?.userData?.data?.dob);
    React.useEffect(() => {
        if (basePolicyData?.policyId && !isLoading) {
            dispatch(
                fetchPolicyBenefitsDetails({ policyId: basePolicyData?.policyId, slabId: basePolicyData?.slabId })
            );
        }
    }, [basePolicyData]);
    return (
        <>
            {isLoading || topUpPolicyDetailsLoading ? (
                <StyledLoaderContainer>
                    <Loader />
                    <TextView
                        textAlign={'center'}
                        fontSize={'20px'}
                        margin={'30px 0px 0px 0px'}
                        color={theme.colors.beyondGrey}
                    >
                        Please wait while we fetch your <br /> policy details..
                    </TextView>
                </StyledLoaderContainer>
            ) : error?.message.length ? (
                <>
                    {!process.env.REACT_APP_SUPERTOPUP_INTEGRATED && <Header bgColor={false} logo={LoopGreenLogo} />}

                    <StyledContainerUNAvailable>
                        <TopUpUnAvailable
                            isTopUpAvailable={topUpAvailable || false}
                            isPolicyActive={policyActive || false}
                            isPolicyAvailable={false}
                            userAge={getAgeFromDOB(userDob?.seconds || 0) > 90 ? true : false}
                        />
                    </StyledContainerUNAvailable>
                </>
            ) : (
                <>
                    {topPolicyData &&
                    Object.keys(topPolicyData).length > 0 &&
                    topPolicyData.enrolmentStatus === 'ENROLED' ? (
                            <TopupSummary />
                        ) : (
                            <>
                                {!isLoading &&
                            policyData &&
                            topUpAvailable &&
                            policyActive &&
                            getAgeFromDOB(userDob?.seconds || 0) <= 90 ? (
                                        <StyledContainerAvailable>
                                            <TopUpAvailable policyData={basePolicyData} />
                                        </StyledContainerAvailable>
                                    ) : (
                                        <>
                                            {basePolicyData && (
                                                <>
                                                    {!process.env.REACT_APP_SUPERTOPUP_INTEGRATED && (
                                                        <Header bgColor={false} logo={LoopGreenLogo} />
                                                    )}
                                                    <StyledContainerUNAvailable>
                                                        <TopUpUnAvailable
                                                            isTopUpAvailable={topUpAvailable || false}
                                                            isPolicyActive={policyActive || false}
                                                            // eslint-disable-next-line max-len
                                                            isPolicyAvailable={basePolicyData?.isTopUpPolicy ? false : true}
                                                            // eslint-disable-next-line max-len
                                                            userAge={getAgeFromDOB(userDob?.seconds || 0) > 90 ? false : true}
                                                        />
                                                    </StyledContainerUNAvailable>
                                                </>
                                            )}
                                        </>
                                    )}
                            </>
                        )}
                </>
            )}
        </>
    );
};
export default DashboardPage;
