import React from 'react';
import { Switch } from 'react-router-dom';
import { INTERNAL_ROUTES } from '../../../utils/constants';
import { InternalRouteGuard } from '../../../utils/RouteGuards';
import {
    ConfirmationPage,
    DashboardPage,
    EnrolmentPage,
    SuperTopUpPage,
    SummaryPage,
    WelcomePage,
    CheckoutPage
} from './pages';

const InternalRouter: React.FunctionComponent = () => {
    return (
        <Switch>
            <InternalRouteGuard path={INTERNAL_ROUTES.welcome} Component={WelcomePage} exact />
            <InternalRouteGuard path={INTERNAL_ROUTES.home} Component={DashboardPage} exact />
            <InternalRouteGuard path={INTERNAL_ROUTES.enrolment} Component={EnrolmentPage} exact />
            <InternalRouteGuard path={INTERNAL_ROUTES.dashboard} Component={DashboardPage} exact />
            <InternalRouteGuard path={INTERNAL_ROUTES.summary} Component={SummaryPage} exact />
            <InternalRouteGuard path={INTERNAL_ROUTES.confirm} Component={ConfirmationPage} exact />
            <InternalRouteGuard path={INTERNAL_ROUTES.topup} Component={SuperTopUpPage} exact />
            <InternalRouteGuard path={INTERNAL_ROUTES.checkout} Component={CheckoutPage} exact />
        </Switch>
    );
};

export default InternalRouter;
