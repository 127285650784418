import React from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../redux';
import { destroyFreshChat, loadFreshChat } from '../../../topup-src/utils/Freshchat';
import useSegment from '../../../utils/hooks/useSegment';
import { SEGMENT_ACTIONS } from '../../../utils/constants/SegmentActionConstants';
import { getSegmentPageName } from '../../../utils/common';

export const FreshChat: React.FunctionComponent = () => {
    const user = useSelector((state: ReduxState) => state.user.userData?.data);
    const trackClick = useSegment('click');
    const currentPageAction = SEGMENT_ACTIONS.PAGE[getSegmentPageName(location.pathname) as any];
    const trackEvent = () => {
        const segmentAction = SEGMENT_ACTIONS.CLICK.enrolment_chatbot;
        trackClick({
            name: segmentAction.name,
            properties: {
                page_url: (segmentAction.properties?.page_url as (path: string) => string)(location.pathname) || '',
                page_id: (segmentAction.properties?.page_id as (path: string) => string)(location.pathname) || '',
                page_title: (segmentAction.properties?.page_title as (pageTile: string) => string)(
                    currentPageAction.name
                ),
                login_status: segmentAction.properties?.login_status
            }
        });
    };
    React.useEffect(() => {
        loadFreshChat(user?.email, user?.mobile, trackEvent);
        return () => {
            destroyFreshChat();
        };
    }, []);
    return <React.Fragment></React.Fragment>;
};
