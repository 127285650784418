import styled from 'styled-components';

export const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;
export const StyledTitle = styled.h2(
    (p) => `
    font-size: ${p.theme.fontSizes.landing};
    color: ${p.theme.colors.black};
    margin: 0;
    margin-bottom: 15px;
`
);
export const StyledHeading = styled.p(
    (p) => `
    font-size: ${p.theme.fontSizes.heading};
    color: ${p.theme.colors.muted};
    margin: 0px;
    font-weight: 300;
`
);
export const StyledOAuthContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    margin-top: 50px;
    @media (max-width: 768px) {
        max-width: 100%;
    }
`;
export const StyledDivider = styled.h6`
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #e6eff0;
    line-height: 0.1em;
    margin: 0;
    font-weight: 500;
    margin: 46px 0px;
    color: ${(p) => p.theme.colors.muted};
    font-size: ${(p) => p.theme.fontSizes.label};
`;
export const StyledErrorText = styled.span`
    color: ${(p) => p.theme.colors.error};
    margin: 5px 0px;
    font-size: ${(p) => p.theme.fontSizes.label};
`;
export const StyledDividerText = styled.span`
    background-color: ${(p) => p.theme.colors.white};
    padding: 0px 5px;
`;
export const StyledMobileFormContainer = styled.div`
    width: 100%;
`;
