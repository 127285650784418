import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 15px 0;
  .leadpage-input {
    width: 100%;
    box-shadow: 0px 4px 10px rgba(149, 159, 190, 0.1);
    border-radius: 8px;
    padding: 9px;
    box-sizing: border-box;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #3d4144;
    background: #edeff3;
    border: 0px;
  }

  .react-date-picker__wrapper {
    border: 0;
  }

  .date-picker-error {
    border: 1px solid ${(p) => p.theme.colors.error};
  }
`;

export const StyledTitle = styled.div`
  font-size: ${(p) => p.theme.fontSizes.body};
  color: ${(p) => p.theme.colors.black};
  font-weight: 700;
  margin: 0px 15px;
  display: flex;
  align-items: center;
  span {
    color: ${(p) => p.theme.colors.muted};
    font-weight: 500;
    margin-left: 8px;
  }
`;

export const StyledNomineesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const StyledFormError = styled.div(
    (p) => `
  position: absolute;
  color: ${p.theme.colors.error};
  margin-top: 6px;
  margin-left: 6px;
  font-size: 12px;
`
);
export const StyledFormTip = styled.div(
    (p) => `
  position: absolute;
  color: ${p.theme.colors.muted};
  margin-top: 6px;
  margin-left: 6px;
  font-size: 12px;
`
);
export const StyledTitleWarningText = styled.div((p) => `
  color: ${p.theme.colors.error};
  font-size: 12px;
`);

export const StyledMutedWarningText = styled.div((p) => `
  color: ${p.theme.colors.mutedBG};
  font-size: 12px;
`);
