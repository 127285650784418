import styled from 'styled-components';

export const StyledTopUpContainer = styled.div`
    padding: 25px;
    flex-direction: row;
    @media (max-width: 768px) {
        padding: 16px 20px;
    }
`;
export const StyledDivContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0px 25px;
    margin-top: 45px;
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 25px;
    }
`;
export const StyledTitleText = styled.div(
    (p) => `
    color: ${p.theme.colors.black};
    font-size: ${p.theme.fontSizes.body};
    text-transform: capitalize;
    font-weight: bold;
`
);
export const StyledNoticeWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin: 40px 25px 25px;
    @media (max-width: 768px) {
        justify-content: center;
        padding: 10px 0px;
        margin-top: 0px;
    }
`;
