import styled from 'styled-components';

export const StyledContainer = styled.div`
    width: 96%;
    display: flex;
    flex-direction: column;
    margin-right: auto;
    /* margin-bottom: 50px; */
`;

export const StyledDetailsSection = styled.div`
    /* width: 64%; */
    /* min-height: 300px; */
    /* width: 100%; */
    background-color: ${(p) => p.theme.colors.lightGrey};
    padding: 24px 22px 5px 22px;
    border-radius: 16px;
    margin-bottom: 20px;
`;

export const StyledPremiumSpan = styled.span`
    letter-spacing: -0.02em;
    font-style: normal;
    font-weight: 500;
    font-size: ${(p) => p.theme.fontSizes.heading};
    color: ${(p) => p.theme.colors.beyondGrey};
`;

export const StyledGSTWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const StyledHeadingText = styled.h1`
    font-size: ${(p) => p.theme.fontSizes.landing};
    color: ${(p) => p.theme.colors.loopEmerald};
    font-style: normal;
    font-weight: 400;
`;

export const StyledMemberWrapper = styled.div``;

export const StyledNameContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: start;
    /* column-gap: 20px; */
`;

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 768px) {
        width: 100%;
        margin-top: 25px;
    }
`;

export const SaveButton = styled.button(
    (p) => `
    color: ${p.theme.colors.loopEmerald};
    font-size: ${p.theme.fontSizes.body};
    background-color: ${p.theme.colors.coverUpGreen};
    font-weight: 700;
    width:100%;
    cursor: pointer;
    border-radius: 16px;
    padding: 18px 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    border:none;
    @media (max-width: 768px) {
        padding: 14px 3px;
        width: 70%;
        min-width: 45%;
    }
`
);

//

export const StyledInsuranceContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 24px;
    border-bottom: 1px solid #d4e3eb;
    width: 100%;
    /* justify-content: space-between; */
    align-items: center;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const StyledDetailsWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    /* width: 40%; */
    /* min-width: 370px; */
    @media (max-width: 768px) {
        flex-direction: column;
        text-align: center;
        margin-top: 20px;
    }
`;
export const StyledSIContainer = styled.div`
    display: flex;
    flex-direction: column;
    /* width: 100%;
    align-items: flex-end; */
    @media (max-width: 768px) {
        align-items: center;
    }
`;

export const SIDetailsWrapper = styled.div`
    display: flex;
    /* min-width: 100px; */
    justify-content: flex-start;
    @media (max-width: 768px) {
        justify-content: center;
        margin-bottom: 20px;
    }
`;

export const StyledDivider = styled.div`
    /* width: 10%; */
    display: flex;
    justify-content: center;
    margin: 0px 30px;
    @media (max-width: 768px) {
        display: none;
    }
`;

export const StyledDividerSpan = styled.span`
    width: 1px;
    height: 40px;
    background-color: #dbdbdb;
`;

export const StyledMembersSection = styled.div`
    margin-top: 20px;
`;

export const StyledSection = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledDelIcon = styled.img`
    cursor: pointer;
`;

export const StyledEnrolledMemberButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
`;

export const StyledEnrolledMemberButtonText = styled.div(
    (p) => `
    font-size: ${p.theme.fontSizes.label};
    font-weight: 600;
    color: ${p.theme.colors.primary};
    cursor: pointer;
    @media (max-width: 550px) {
       display: none;
    }
`
);
