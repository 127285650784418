import styled from 'styled-components';

export const StyledContainer = styled.div`
    height: 100vh;
    width: 100vw;
    max-height: 100vh;
    max-width: 100vw;
    overflow-y: auto;
    background-color: ${(p) => p.theme.colors.secondary};
`;
