import styled from 'styled-components';

export const StyledDividerContainer = styled.div`
    display: flex;
    margin: 20px 0px;
    box-sizing: border-box;
    align-items: center;
`;

export const StyledDividerBorder = styled.div`
    border-bottom: 1px solid ${(p) => p.theme.colors.mutedTransparent};
    width: 100%;
`;

export const StyledDividerContent = styled.span`
    padding: 0 10px 0 10px;
    font-weight: 400;
    font-size: ${(p) => p.theme.fontSizes.label};
    line-height: 20px;
`;
