import styled from 'styled-components';
import SVG from 'react-inlinesvg';

export const StyledDialogContainer = styled.div`
  width: 500px;
  min-height: 150px;
  display: flex;
  gap: 24px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 768px) {
    padding: 15px 30px;
  }
`;
export const StyledDialogIcon = styled(SVG)`
  height: 72px
`;
export const StyledTitle = styled.span`
  font-weight: bolder;
  line-height: 22px;
  text-align: center;
  color: ${(p) => p.theme.colors.black};
  font-size: ${(p) => p.theme.fontSizes.heading};
`;
export const StyledDescription = styled.span`
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #667985;
  opacity: 0.8;
  font-size: ${(p) => p.theme.fontSizes.subHeading};
`;
