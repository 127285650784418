import { createSlice } from '@reduxjs/toolkit';
import { initialState, initialAdapterState } from './initialState';
import { FirebaseAuth } from '../../../adapters/provider';
import {
    loginWithMobile,
    loginWithOAuth,
    verifyUserOTP,
    checkUserAccess,
    getUserDetails
} from './thunks';

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        logoutUser(state) {
            state.userData = initialState.userData;
            state.currentUser = initialState.currentUser;
            state.userMobile = initialState.userMobile;
            state.userProfile = initialState.userProfile;
            state.isUserAuthenticated = initialState.isUserAuthenticated;
            FirebaseAuth.signOut();
        },
        setCurrentUser(state, action) {
            state.currentUser = action.payload.user;
        },
        setClearUserData(state) {
            state.userData = {
                ...initialAdapterState
            };
        },
        clearUserError(state) {
            state.userData.error = initialState.userData.error;
        },
        authenticateUser(state) {
            state.isUserAuthenticated.data = true;
        }
    },
    extraReducers: {
        [loginWithOAuth.pending as any]: (state) => {
            state.userData.loading = true;
            state.userData.error = null;
        },
        [loginWithOAuth.fulfilled as any]: (state, action) => {
            state.userData.data = action.payload.data;
            state.userData.loading = false;
            state.userData.error = null;
        },
        [loginWithOAuth.rejected as any]: (state, action) => {
            state.userData.loading = false;
            state.userData.error = action.error;
        },
        [loginWithMobile.pending as any]: (state) => {
            state.userMobile.data = null;
            state.userMobile.loading = true;
            state.userMobile.error = null;
        },
        [loginWithMobile.fulfilled as any]: (state, { payload }) => {
            state.userMobile.data = payload.mobileNumber;
            state.userMobile.loading = false;
            state.userMobile.error = null;
        },
        [loginWithMobile.rejected as any]: (state, { error }) => {
            state.userMobile.data = null;
            state.userMobile.loading = false;
            state.userMobile.error = error;
        },
        [verifyUserOTP.pending as any]: (state) => {
            state.userData.loading = true;
            state.userData.error = null;
            state.userData.data = null;
        },
        [verifyUserOTP.fulfilled as any]: (state, { payload }) => {
            state.userData.data = payload.data;
            state.userData.loading = false;
            state.userData.error = null;
        },
        [verifyUserOTP.rejected as any]: (state, { error }) => {
            state.userData.loading = false;
            state.userData.data = null;
            state.userData.error = error;
        },
        [checkUserAccess.pending as any]: (state) => {
            state.isUserAuthenticated.loading = true;
            state.isUserAuthenticated.error = null;
        },
        [checkUserAccess.fulfilled as any]: (state, { payload }) => {
            state.isUserAuthenticated.data = payload.isUserAuthenticated;
            state.isUserAuthenticated.loading = false;
            state.isUserAuthenticated.error = null;
        },
        [checkUserAccess.rejected as any]: (state, { error }) => {
            state.isUserAuthenticated.data = false;
            state.isUserAuthenticated.loading = false;
            state.isUserAuthenticated.error = error;
        },
        [getUserDetails.pending as any]: (state) => {
            state.userData.loading = true;
            state.userData.error = null;
            state.userData.data = null;
        },
        [getUserDetails.fulfilled as any]: (state, { payload }) => {
            state.userData.data = payload.data;
            state.isUserAuthenticated.data = true;
            state.userData.loading = false;
            state.userData.error = null;
        },
        [getUserDetails.rejected as any]: (state, { error }) => {
            state.userData.loading = false;
            state.userData.data = null;
            state.userData.error = error;
        },
        'common/cleanup': (state) => {
            state.userData = { ...initialAdapterState };
        }
    }
});
// actions
export const { setCurrentUser, setClearUserData, logoutUser, clearUserError, authenticateUser } = userSlice.actions;
// thunks
export { loginWithMobile, loginWithOAuth, verifyUserOTP, checkUserAccess, getUserDetails };
export default userSlice.reducer;
