import styled from 'styled-components';

export const StyledContainer = styled.div`
    min-height: 500px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 275px;
    max-width: 275px;
    @media (max-width: 768px) {
        position: relative;
        min-height: 0;
        margin-left: 20px;
    }
`;
