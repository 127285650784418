import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteMemberModal, ManageMemberModal, EnrolDependents } from '..';
import { ReduxState } from '../../../redux';
import {
    setShowAddModal,
    setShowDeleteModal,
    setShowEditModal,
    setMemberIdToDelete,
    setDependent,
    setRelation
} from '../../../redux/slices/ApplicationStateSlice';
import {
    removeSelectedUserFromPolicy,
    resetCrudErrors,
    setCurrentlySelectedPolicy
} from '../../../redux/slices/EnrolmentSlice';
import { IDependent } from '../../../redux/slices/PolicyListSlice/types';
import { capitalizeFirstLetter, replaceFamilyStructureText } from '../../../utils/common';
import { SEGMENT_ACTIONS } from '../../../utils/constants/SegmentActionConstants';
import useSegment from '../../../utils/hooks/useSegment';
import { familyDefinition, getDependentMappings, IMappings } from '../../../utils/mapping';
import {
    StyledAlertContainer,
    StyledAlertDescContainer,
    StyledContainer,
    StyledDivContainer,
    StyledText,
    StyledTitleText
} from './styles';
import { IDependentsToDisplay, IEnrolMembers } from './types';
import ManageNominees from '../ManageNomineesComponent';
import { ToastProvider } from '../../../utils/hooks/useToast';
import { getDependentsOnSelectedParentType } from './EnrolMemberService';
import { IRelationship } from '../EnrolDependents/types';
import { Alerts, Typography } from '@loophealth/loop-ui-web-library';
import { Modal } from '../../atoms';
import Dialog from '../../atoms/Dialog';
import { ModalAlertIcon } from '../../../assets/img';
import { PolicyTypeConstants } from '../../../utils/constants';
import { SHOW_DISCLAIMER } from '../../../utils/constants/CompanyConstants';

const initialFormData = {
    firstName: '',
    lastName: '',
    mobileNumber: '',
    doB: new Date(),
    gender: '',
    realtionship: '',
    dependentId: ''
};
const EnrolMembers: React.FunctionComponent<IEnrolMembers> = ({
    familyStructure,
    dependents,
    nomineeConsumedPercentage,
    setNomineeConsumedPercentage
}) => {
    const trackClick = useSegment('click');
    const dispatch = useDispatch();
    const currentlySelectedPolicy = useSelector((state: ReduxState) => state.enrolment.currentlySelectedPolicy);
    const policyId = currentlySelectedPolicy?.policyId || '';
    const isNomineeEnabledForCompany = useSelector(
        (state: ReduxState) => state.stepperData.nomineeConfig.data.isEnabled
    );
    const isNomineeConfigLoading = useSelector((state: ReduxState) => state.stepperData.nomineeConfig.loading);
    const showAddMemberModal = useSelector((state: ReduxState) => state.applicationState.showAddModal);
    const showEditMemberModal = useSelector((state: ReduxState) => state.applicationState.showEditModal);
    const showDeleteMemberModal = useSelector((state: ReduxState) => state.applicationState.showDeleteModal);
    const companyId = useSelector((state: ReduxState) => state.user?.userData?.data?.employer);
    const policyList = useSelector((state: ReduxState) => state.policyList.policyList.data);
    const userId = useSelector((state: ReduxState) => state.user?.userData?.data?.userId || '');
    const dependentIdToDelete = useSelector((state: ReduxState) => state.applicationState.memberIdToDelete);
    const selectedRelation = useSelector((state: ReduxState) => state.applicationState.selectedRelation);
    const selectedDependent = useSelector((state: ReduxState) => state.applicationState.selectedDependent);
    const [editMemberForm, setEditMemberForm] = React.useState<typeof initialFormData>(initialFormData);
    const [currentMapping, setCurrentMapping] = React.useState<null | IMappings>(null);
    const [targetMapping, setTargetMapping] = React.useState<null | IMappings>(null);
    const [filteredDependents, setFilteredDependents] = React.useState<null | IDependentsToDisplay>(null);
    const [category, setCategory] = React.useState<[] | string[]>([]);
    const [isMaxDependentModalVisible, setIsMaxDependentModalVisible] = useState(false);
    const [selectedParentType, setSelectedParentType] = React.useState('');
    React.useEffect(() => {
        const updatedSelectedPolicy = (policyList || [])?.filter((policy) => policy.policyId === policyId)[0];
        dispatch(setCurrentlySelectedPolicy({ currentlySelectedPolicy: updatedSelectedPolicy }));
    }, [policyList]);

    React.useEffect(() => {
        let dependent: IDependentsToDisplay = {};
        const spouseSelf = ['spouse', 'self'];
        const children = dependents.filter((rel) => rel.relationship.toLowerCase() === 'Child'.toLowerCase());
        const selfSpouse = dependents.filter((rel) => spouseSelf.includes(rel.relationship.toLowerCase()));
        const parents = dependents.filter((rel) => ['parent'].includes(rel.relationship.toLowerCase()));
        const parentsInLaw = dependents.filter((rel) => ['parent-in-law'].includes(rel.relationship.toLowerCase()));
        dependent = { selfSpouse, children, parents, parentsInLaw };
        setFilteredDependents(dependent);
    }, [dependents, familyStructure]);

    // get latest target mappings, when familyStructure changes
    React.useEffect(() => {
        // note, dependents length in mapping will always be 1 less.
        // this is because mappings do not consider 'self' count
        if (familyStructure?.length) {
            setTargetMapping(familyDefinition[familyStructure]);
            const dependentTypes = familyStructure.split(' + ');
            setCategory(dependentTypes);
        }
    }, [dependents, familyStructure]);

    // get latest current mappings, when dependents change
    React.useEffect(() => {
        setCurrentMapping(getDependentMappings(dependents, familyStructure));
    }, [dependents, familyStructure]);
    const confirmDeleteDependent = (dependentId: string, relationship: string) => {
        if (relationship == 'Parents') {
            relationship = 'Parent';
        }
        if (relationship == 'Parents-in-law' || relationship == 'Parent-in-laws') {
            relationship = 'Parent-in-law';
        }
        dispatch(
            removeSelectedUserFromPolicy({
                userId: dependentId,
                policyId: policyId || '',
                companyId: companyId || '',
                selfId: userId,
                relationship
            })
        );
    };
    const getDependent = (relationship: string) => {
        const dependentFound = dependents.filter((dep) => dep?.relationship?.toLowerCase() === relationship)[0];
        if (dependentFound) {
            return dependentFound;
        }
        return null;
    };
    const onAddMemberClick = (dependentRelation: string) => {
        if (isMaxDependentsEnabled && isMaxDependentsAdded) {
            setIsMaxDependentModalVisible(true);
            return;
        }
        setSelectedDependent(dependentRelation);
        setSelectedRelation(dependentRelation as any);
        trackClick(SEGMENT_ACTIONS.CLICK.ENROLMENT_ADD_MEMBER_CARD);
        if (targetMapping && dependents.length <= targetMapping.totalDependents + 1) {
            setSelectedRelation(getRelation(dependentRelation));
            setShowAddMemberModal(true);
        }
    };
    const onMemberDeleteClick = (id: string, dependentRelation: string) => {
        const segmentAction = SEGMENT_ACTIONS.CLICK.ENROLMENT_DELETE_MEMBER_CARD;
        trackClick({
            name: segmentAction.name,
            properties: {
                describe: (segmentAction.properties?.describe as (depId: string) => string)(id) || '',
                dependent_id: (segmentAction.properties?.dependent_id as (depId: string) => string)(id) || ''
            }
        });
        setSelectedRelation(getRelation(dependentRelation));
        setDependentIDToDelete(id);
        setShowDeleteMemberModal(true);
    };
    const onMemberEditClick = (dep: IDependent) => {
        setSelectedDependent(dep.relationship);
        const segmentAction = SEGMENT_ACTIONS.CLICK.ENROLMENT_EDIT_MEMBER_CARD;
        trackClick({
            name: segmentAction.name,
            properties: {
                describe: (segmentAction.properties?.describe as (depId: string) => string)(dep.userId) || '',
                dependent_id: (segmentAction.properties?.dependent_id as (depId: string) => string)(dep.userId) || ''
            }
        });
        const relation: any = dep?.relationship?.charAt(0).toUpperCase() + dep?.relationship?.slice(1) || '';
        setSelectedRelation(relation);
        setEditMemberForm({
            firstName: dep.firstName,
            lastName: dep.lastName,
            mobileNumber: dep.mobile,
            doB: dep?.doB ? new Date(dep.doB * 1000) : new Date(),
            gender: dep?.gender,
            realtionship: dep?.relationship?.charAt(0).toUpperCase() + dep?.relationship?.slice(1),
            dependentId: dep.userId
        });
        setShowEditMemberModal(true);
    };
    const setShowAddMemberModal = (isVisible: boolean) => {
        dispatch(resetCrudErrors());
        dispatch(setShowAddModal({ showAddModal: isVisible }));
    };
    const setShowEditMemberModal = (isVisible: boolean) => {
        dispatch(resetCrudErrors());
        dispatch(setShowEditModal({ showEditModal: isVisible }));
    };
    const setShowDeleteMemberModal = (isVisible: boolean) => {
        dispatch(resetCrudErrors());
        dispatch(setShowDeleteModal({ showDeleteModal: isVisible }));
    };
    const setDependentIDToDelete = (id: string) => {
        dispatch(setMemberIdToDelete({ memberIdToDelete: id }));
    };
    const setSelectedDependent = (selectedDependent: string) => {
        dispatch(setDependent({ selectedDependent }));
    };
    const setSelectedRelation = (selectedRelation: string) => {
        dispatch(setRelation({ selectedRelation }));
    };

    const getFilteredDependents = (category: string) => {
        const children = ['1 Kid', '2 Kids', '3 Kids', '4 Kids'];
        const oneOfParentsOrInLawsOptions = ['Parents OR parents-in-law', 'Parents OR parent-in-laws'];
        const anyOfParentsOrInLawsOptions = ['Parents (including in-laws)'];

        if (category === 'Spouse' || category === 'Self') return filteredDependents?.selfSpouse || [];
        else if (children.includes(category)) return filteredDependents?.children || [];
        else if (category === 'Parents' || category === 'Parents Only') return filteredDependents?.parents || [];
        else if (category === 'Parents-in-law') return filteredDependents?.parentsInLaw || [];
        else if (oneOfParentsOrInLawsOptions.includes(category)) {
            if (filteredDependents?.parents && filteredDependents?.parents?.length > 0) {
                return filteredDependents?.parents || [];
            }
            return filteredDependents?.parentsInLaw || [];
        } else if (anyOfParentsOrInLawsOptions.includes(category)) {
            return [...(filteredDependents?.parents ?? []), ...(filteredDependents?.parentsInLaw ?? [])];
        } else return [];
    };

    const getTitle = (index: number) => {
        if (category.length === 2 || index === 1) {
            return `${category[0]} + ${category[1]}`;
        } else if (category.length > 4 && index === 3) {
            return `${category[3]} + ${category[4]}`;
        } else return category[index];
    };

    const getRelation = (relationship: string) => {
        const children = ['1 Kid', '2 Kids', '3 Kids', '4 Kids'];
        const oneOfParentsOrInLawsOptions = [
            'Parents OR parents-in-law',
            'Parents OR parent-in-laws',
            'Parents Only',
            'Parents (including in-laws)',
            'Parents'
        ];
        if (relationship === 'Spouse') {
            return 'Spouse';
        } else if (children.includes(relationship)) {
            return 'Child';
        } else if (oneOfParentsOrInLawsOptions.includes(relationship)) {
            if (filteredDependents?.parents && filteredDependents?.parents?.length > 0) {
                return 'Parent';
            }
            return 'Parent-in-law';
        } else {
            return relationship;
        }
    };

    const maxDependents = Number(currentlySelectedPolicy?.maximumDependentsAllowed ?? 0) + 1;
    const isMaxDependentsEnabled = !!currentlySelectedPolicy?.maximumDependentsAllowed;
    const isMaxDependentsAdded = dependents.length >= maxDependents;
    const coveredFamilyMembersText = capitalizeFirstLetter(replaceFamilyStructureText(familyStructure));

    return (
        <StyledContainer>
            <StyledDivContainer>
                <StyledTitleText>COVERED FAMILY MEMBERS</StyledTitleText>
                <StyledText>
                    {coveredFamilyMembersText}
                    {!!isMaxDependentsEnabled && (
                        <Typography variant="extraSmall" color="error">
                            &nbsp;(The policy covers a maximum of {maxDependents} members)
                        </Typography>
                    )}
                </StyledText>
            </StyledDivContainer>
            <StyledAlertContainer>
                {currentlySelectedPolicy?.policyType === PolicyTypeConstants.GMC &&
                SHOW_DISCLAIMER.includes(companyId) ? (
                        <Alerts.LightAlert
                            variant="warning"
                            title={'IMPORTANT'}
                            description={
                                <StyledAlertDescContainer>
                                    <Typography variant="medium">
                                    1. Premiums are calculated only for dependents added in the policy, and will be
                                    deducted from your salary
                                    </Typography>
                                    <Typography variant="medium">
                                    2. Deletion of family members after the enrolment due date will NOT be possible.
                                    </Typography>
                                </StyledAlertDescContainer>
                            }
                            closable={false}
                        />
                    ) : null}
            </StyledAlertContainer>

            <>
                {Array.from(Array(category.length ?? 0), (_element, index) => {
                    if (category.length === 1 || category[index] !== 'Self' || index !== 0) {
                        return (
                            <EnrolDependents
                                key={index}
                                title={capitalizeFirstLetter(replaceFamilyStructureText(getTitle(index)))}
                                category={category[index]}
                                filteredDependents={getFilteredDependents(category[index])}
                                currentMapping={currentMapping}
                                targetMapping={targetMapping}
                                onAddMemberClick={() => onAddMemberClick(category[index])}
                                onMemberEditClick={(dep: IDependent) => onMemberEditClick(dep)}
                                onMemberDeleteClick={(id: string) => onMemberDeleteClick(id, category[index])}
                                noOfCoveredMembers={category.length}
                                relationship={getRelation(category[index])}
                                selectedRelationship={(
                                    relation: 'Self' | 'Spouse' | 'Child' | 'Parent' | 'Parent-in-law' | ''
                                ) => setSelectedParentType(relation)}
                            />
                        );
                    }
                })}
                {isNomineeEnabledForCompany && !isNomineeConfigLoading && (
                    <ToastProvider>
                        <ManageNominees
                            userId={userId}
                            policyId={policyId}
                            consumedPercentage={nomineeConsumedPercentage}
                            setConsumedPercentage={setNomineeConsumedPercentage}
                        />
                    </ToastProvider>
                )}
            </>

            <DeleteMemberModal
                setIsVisible={setShowDeleteMemberModal}
                isVisible={showDeleteMemberModal}
                confirmDelete={confirmDeleteDependent}
                dependentId={dependentIdToDelete}
                selectedRelationship={selectedRelation}
            />
            {showEditMemberModal && (
                <ManageMemberModal
                    isEditMode={true}
                    {...(editMemberForm as any)}
                    firstName={editMemberForm.firstName}
                    lastName={editMemberForm.lastName}
                    self={getDependent('self')}
                    spouse={getDependent('spouse')}
                    shouldShowSelf={dependents.filter((dep) => dep?.relationship?.toLowerCase() === 'self').length <= 0}
                    currentMapping={currentMapping}
                    targetMapping={targetMapping}
                    isVisible={showEditMemberModal}
                    setIsVisible={setShowEditMemberModal}
                    selectedRelationship={selectedRelation}
                    selectedDependent={selectedDependent}
                />
            )}

            {showAddMemberModal && (
                <ManageMemberModal
                    isEditMode={false}
                    self={getDependent('self')}
                    spouse={getDependent('spouse')}
                    shouldShowSelf={dependents.filter((dep) => dep?.relationship?.toLowerCase() === 'self').length <= 0}
                    existingDependents={getDependentsOnSelectedParentType(dependents, selectedParentType)}
                    currentMapping={currentMapping}
                    targetMapping={targetMapping}
                    isVisible={showAddMemberModal}
                    setIsVisible={setShowAddMemberModal}
                    selectedRelationship={selectedParentType as IRelationship}
                    selectedDependent={selectedDependent}
                />
            )}
            {isMaxDependentsEnabled && (
                <Modal isVisible={isMaxDependentModalVisible} setIsVisible={setIsMaxDependentModalVisible}>
                    <Dialog
                        isLoading={false}
                        icon={ModalAlertIcon}
                        isSecondaryButton={false}
                        primaryButtonText="Got it!"
                        title={`The policy covers a maximum of ${maxDependents} family members`}
                        // eslint-disable-next-line max-len
                        description="In order to add another family member, please remove any one of the previously added members"
                        onPrimaryButtonClicked={() => setIsMaxDependentModalVisible(false)}
                    />
                </Modal>
            )}
        </StyledContainer>
    );
};

export default EnrolMembers;
