import styled from 'styled-components';

export const StyledErrorContainer = styled.div(
    (p) => `
    box-shadow: 0px 2px 26px rgba(215, 0, 0, 0.1);
    border-radius: 6px;
    background-color: ${p.theme.colors.error};
    color: ${p.theme.colors.white};
    font-size: ${p.theme.fontSizes.heading};
    padding: 15px;
    width: 100%;
    padding: 20px 15px;
    margin: 20px 0px;
    box-sizing: border-box;
    -webkit-box-sizing:border-box;
    -moz-box-sizing: border-box;
`
);
export const StyledTitle = styled.div`
    font-weight: 600;
`;
export const StyledSubtitle = styled.div`
    font-size: ${(p) => p.theme.fontSizes.body};
`;
