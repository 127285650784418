import styled from 'styled-components';

export const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    max-width: 100%;
`;

export const StyledTopMargin = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

export const StyledErrorText = styled.span`
    color: ${(p) => p.theme.colors.error};
    margin: 5px 0px;
    font-size: ${(p) => p.theme.fontSizes.label};
`;
export const StyledResendButon = styled.span`
    cursor: pointer;
    color: ${(p) => p.theme.colors.primary};
    font-size: ${(p) => p.theme.fontSizes.body};
    margin-top: 30px;
`;
