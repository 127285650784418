import React from 'react';
import { StyledErrorContainer, StyledSubtitle, StyledTitle } from './styles';
import { IErrorContainer } from './types';


const ErrorContainer: React.FunctionComponent<IErrorContainer> = ({ message }) => {
    return (
        <StyledErrorContainer>
            <StyledTitle>Something went wrong!</StyledTitle>
            <StyledSubtitle>{message}</StyledSubtitle>
        </StyledErrorContainer>
    );
};

export default ErrorContainer;
