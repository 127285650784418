import { createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase';
import { FirebaseCaptcha, FirebaseAuth } from '../../../../../../adapters/provider';
import { IOAuthType } from '../../types';
import { SEGMENT_ACTIONS } from '../../../../../../utils/constants/SegmentActionConstants';
import { getAppPermissionID, getRolesPermissions, getUserData } from '../../../../../../adapters';
// import { getAgeFromDOB } from '../../../../../../utils/common';
export const USER_ACTION_TYPES = {
    LOGIN_WITH_MOBILE: 'userdata/loginWithMobile',
    VERIFY_OTP: 'userData/verifyOTP',
    FETCH_USER: 'userdata/fetchUser',
    UPDATE_USER: 'userdata/updateData',
    LOGIN_WITH_OAUTH: 'userdata/loginWithOAuth',
    CHECK_USER_ACCESS: 'userdata/checkUserAccess'
};
//

export const loginWithMobile = createAsyncThunk(USER_ACTION_TYPES.LOGIN_WITH_MOBILE, async (mobileNumber: string) => {
    try {
        const appVerificationDisabledForTesting =
            process.env.REACT_APP_DISABLED_FIREBASE_CAPTCHA === 'true' ? true : false;
        if (appVerificationDisabledForTesting) {
            firebase.auth().settings.appVerificationDisabledForTesting = true;
        }
        const confirmationData = await firebase
            .auth()
            .signInWithPhoneNumber('+91' + mobileNumber, window.recaptchaVerifier);
        window.confirmationResult = confirmationData || null;
        // TODO: Code to be removed after testing
        // firebase.auth().settings.appVerificationDisabledForTesting = true;
        // const phoneNumber = '+918269717898';
        // const testVerificationCode = '123456';
        // const appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');
        // firebase
        //     .auth()
        //     .signInWithPhoneNumber('+91' + mobileNumber, window.recaptchaVerifier)
        //     .then(function(confirmationResult) {
        //         // confirmationResult can resolve with the fictional testVerificationCode above.
        //         console.log('I am confirmationResult', confirmationResult);
        //         return confirmationResult.confirm(testVerificationCode);
        //     })
        //     .catch(function(error) {
        //         // Error; SMS not sent
        //         console.log('I am error', error);
        //         // ...
        //     });
        return {
            mobileNumber
        };
    } catch (error) {
        throw error;
    }
});
export const verifyUserOTP = createAsyncThunk(
    USER_ACTION_TYPES.VERIFY_OTP,
    async ({ otp, enteredMobile }: { otp: string; enteredMobile: string }) => {
        try {
            if (window.confirmationResult) {
                // ask firebase confirmationResult to confirm the OTP
                const response = await window.confirmationResult.confirm(otp);
                // if response.user is present, otp authentication was successful
                if (response.user && enteredMobile.length) {
                    try {
                        const result = await getUserData('mobile', enteredMobile);
                        if (result) {
                            return {
                                data: { ...result, loginMethod: 'mobile' }
                            };
                        }
                    } catch (e) {
                        throw e;
                    }
                }
            } else {
                throw new Error('Something went wrong! Please refresh the page and try again.');
            }
        } catch (e: any) {
            let errorMessage = e.message;
            // The error from the backend is thrown in this format
            // HTTP Cloud Function returned an error:
            // {"error":{"message":"The provided Phone Number is not registered in Loop","status":"INVALID_ARGUMENT"}}
            // Extracting the message part of the error using the below code
            if (errorMessage.indexOf('{') !== -1) {
                const jsonResponse = errorMessage.substring(errorMessage.indexOf('{'));
                const errorObject = JSON.parse(jsonResponse);
                errorMessage = errorObject.error.message;
            }
            if (e.code === 'auth/invalid-verification-code') {
                throw new Error('Phone number or OTP is incorrect.');
            }
            throw new Error(errorMessage);
        }
    }
);
export const loginWithOAuth = createAsyncThunk(
    USER_ACTION_TYPES.LOGIN_WITH_OAUTH,
    async ({ loginMethod }: { loginMethod: IOAuthType }) => {
        let provider;
        if (loginMethod === 'google') {
            provider = new FirebaseCaptcha.GoogleAuthProvider();
            provider.addScope('profile');
            provider.addScope('email');
        } else if (loginMethod === 'outlook') {
            provider = new FirebaseCaptcha.OAuthProvider('microsoft.com');
        }

        if (provider) {
            return await FirebaseAuth.signInWithPopup(provider).then(async (res) => {
                const email =
                    loginMethod === 'google'
                        ? (res?.additionalUserInfo?.profile as any).email || ''
                        : (res?.additionalUserInfo?.profile as any).userPrincipalName || '';
                if (email) {
                    try {
                        const result = await getUserData('email', email);
                        if (result) {
                            return {
                                data: { ...result, loginMethod: 'email', loginMethodType: loginMethod }
                            };
                        }
                    } catch (e) {
                        throw e;
                    }
                }
            }).catch((err) => {
                let errorMessage = err.message;
                // The error from the backend is thrown in this format
                // HTTP Cloud Function returned an error:
                // {"error":{"message":"The provided Email Id is not registered in Loop","status":"INVALID_ARGUMENT"}}
                // Extracting the message part of the error using the below code
                if (errorMessage.indexOf('{') !== -1) {
                    const jsonResponse = errorMessage.substring(errorMessage.indexOf('{'));
                    const errorObject = JSON.parse(jsonResponse);
                    errorMessage = errorObject.error.message;
                }
                throw new Error(errorMessage);
            });
        }
    }
);
type ILoginTracker = ({ name, properties }: { name: string; properties?: Record<string, unknown> | undefined }) => void;
export const checkUserAccess = createAsyncThunk(
    USER_ACTION_TYPES.CHECK_USER_ACCESS,
    async (
        {
            redirectFunction,
            roles,
            setIsLoading,
            trackLogin
        }: {
            redirectFunction: () => void;
            roles: string[];
            setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
            trackLogin: ILoginTracker;
        }
        // { getState }
    ) => {
        try {
            if (!roles.length) {
                throw new Error('User does not have any roles');
            }
            const permissions = await getRolesPermissions(roles) as any;
            if (!permissions.length) {
                throw new Error('User roles do not have any permissions');
            }
            if (!(process.env.REACT_APP_NAME || '').trim()) {
                throw new Error('Cannot find application name');
            }
            const appPermissionId = await getAppPermissionID(process.env.REACT_APP_NAME || '') as any;
            if (!appPermissionId.trim()) {
                throw new Error('Cannot find application permission in IAM');
            }
            if (permissions.includes(appPermissionId)) {
                // TODO: to use below code to pass traits to Segment
                // const {
                //     user: { userData }
                // } = getState() as {
                //     user: { userData: any };
                // };
                setIsLoading(false);
                trackLogin({
                    name: SEGMENT_ACTIONS.IDENTIFY.USER_LOGIN.name
                    // traits: {
                    //     user_id: userData?.data.id
                    //     employer_id: userData?.data?.employer || '',
                    //     gender: userData?.data?.gender || '',
                    //     patient_type: userData?.data?.patientType || '',
                    //     user_age: getAgeFromDOB(userData?.data?.dob?.seconds) || '',
                    //     user_type: userData?.data?.userType || '',
                    //     coordinator_id: userData?.data?.coordinatorId || '',
                    //     login_application: process.env.REACT_APP_NAME,
                    //     user_id: userData?.data.id
                    // }
                });
                redirectFunction();
                return {
                    isUserAuthenticated: true
                };
            } else {
                throw new Error('User does not have permission to access this application');
            }
        } catch (error) {
            throw error;
        }
    }
);
export const getUserDetails = createAsyncThunk(
    USER_ACTION_TYPES.FETCH_USER,
    async ({ key, value }: { key: string; value: string }) => {
        try {
            const result = await getUserData(key, value);
            if (result) {
                return {
                    data: { ...result, loginMethod: 'mobile' }
                };
            } else {
                throw new Error('Unable to fetch user details');
            }
        } catch (e) {
            throw e;
        }
    }
);
