/* eslint-disable max-len */
import React from 'react';
import {
    StyledContainer,
    StyledImage,
    StyledOverdueContainer,
    StyledOverdueWrapper,
    StyledTable,
    StyledTableContainer,
    StyledTablePaddingContainer,
    StyledEnrolledMemberContainer,
    StyledEnrolledMemberHeading,
    StyledEnrolledMemberButton,
    StyledEnrolledMemberButtonText,
    StyledEnrolledMemberWrapper,
    StyledEnrolledContainer
} from './styles';
import { ArrowDown, ChevronDownIcon } from '../../../assets/img';
import { EmployeeSummaryNotice, TableHeader, TableRow } from '../../atoms';
import { InsuranceCardHeader } from '..';
import { IInsuranceCard } from './types';
import { familyDefinition } from '../../../utils/mapping';
import { PARENTAL_POLICY } from '../../../utils/constants';
import { ReduxState } from '../../../redux';
import { useSelector } from 'react-redux';
import { showTaxStripUtil } from './utils';

const InsuranceCard: React.FunctionComponent<IInsuranceCard> = ({
    dependents,
    onClick,
    superTopupStatus,
    enrolmentStatus,
    SI,
    policyId,
    ...insuranceProps
}) => {
    const companyId = useSelector((state: ReduxState) => state.user.userData.data?.employer);
    const [isCollapsed, setIsCollapsed] = React.useState(false);

    return (
        <StyledContainer
            $isCardClick={!insuranceProps.isEnrolPending}
            onClick={(e) => !insuranceProps.isEnrolPending && onClick(e)}
        >
            {/* TODO: Removed Card Click. onClick={(e) => !insuranceProps.isEnrolPending && onClick(e)} */}
            {insuranceProps.insuranceCategory != 'SUPER_TOP_UP' && insuranceProps.daysLeft === 'OVERDUE' && (
                <StyledOverdueWrapper>
                    <StyledOverdueContainer>
                        Enrolment Period for this policy lapsed. Contact your HR Representative for support
                    </StyledOverdueContainer>
                </StyledOverdueWrapper>
            )}

            {insuranceProps?.dependentsInsured === null && (
                <StyledOverdueWrapper>
                    <StyledOverdueContainer>
                        User Information Missing. Contact your HR Representative for support
                    </StyledOverdueContainer>
                </StyledOverdueWrapper>
            )}

            {enrolmentStatus === 'ENROLED' && (
                <StyledOverdueWrapper>
                    <StyledEnrolledContainer>
                        You’ve successfully added members to the policy. If you wish to add more at this point, please
                        contact your HR.
                    </StyledEnrolledContainer>
                </StyledOverdueWrapper>
            )}
            <InsuranceCardHeader
                policyId={policyId}
                onEnrolNowClick={onClick}
                {...insuranceProps}
                superTopupStatus={superTopupStatus}
                enrolmentStatus={enrolmentStatus}
            />

            <StyledEnrolledMemberWrapper $isCollapsed={isCollapsed}>
                <StyledEnrolledMemberContainer $isCollapsed={isCollapsed}>
                    <StyledEnrolledMemberHeading>
                        {dependents?.length}/
                        {(familyDefinition[insuranceProps?.dependentsInsured]?.totalDependents || 0) +
                            (PARENTAL_POLICY.includes(insuranceProps.dependentsInsured) ? 0 : 1)}{' '}
                        Member Enrolled
                    </StyledEnrolledMemberHeading>

                    <StyledEnrolledMemberButton onClick={() => setIsCollapsed(!isCollapsed)}>
                        <StyledEnrolledMemberButtonText>
                            VIEW ENROLLED MEMBERS<>&nbsp;&nbsp;</>{' '}
                        </StyledEnrolledMemberButtonText>
                        <StyledImage
                            src={ArrowDown}
                            alt={'arrow-down'}
                            $isCollapsed={isCollapsed}
                            $showMobile={false}
                        />
                        <StyledImage
                            src={ChevronDownIcon}
                            alt={'arrow-down'}
                            $isCollapsed={isCollapsed}
                            $showMobile={true}
                        />
                    </StyledEnrolledMemberButton>
                </StyledEnrolledMemberContainer>
                {showTaxStripUtil(insuranceProps, SI, enrolmentStatus, superTopupStatus, companyId, dependents) && (
                    <EmployeeSummaryNotice errorNotice={false} Icon={true} showBG={false} fontColor={true}>
                        Now Available: Top-ups that enhance your medical coverage, pre-existing diseases and provide tax
                        benefits under Section 80D
                    </EmployeeSummaryNotice>
                )}
            </StyledEnrolledMemberWrapper>

            {isCollapsed && (
                <StyledTablePaddingContainer>
                    <StyledTableContainer>
                        <StyledTable>
                            <TableHeader />
                            {dependents.map((dep, index) => (
                                <TableRow
                                    index={index}
                                    showBorder={index !== dependents.length - 1}
                                    key={`${insuranceProps.insuranceName}-${index}`}
                                    {...dep}
                                />
                            ))}
                        </StyledTable>
                    </StyledTableContainer>
                </StyledTablePaddingContainer>
            )}
        </StyledContainer>
    );
};

export default InsuranceCard;
