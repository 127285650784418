import { useEffect, useState } from 'react';
import { checkIfTopUpIsConfigured } from '../../../adapters';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../redux';

export const usePolicyTopUpConfigurationCheck = () : boolean => {
    const [configuredState, setConfiguredState] = useState<boolean>(false);
    const currentlySelectedPolicy = useSelector(
        (state: ReduxState) => state.enrolment.currentlySelectedPolicy
    );

    useEffect(() => {
        if (currentlySelectedPolicy) {
            checkIfTopUpIsConfigured(currentlySelectedPolicy.policyId)
                .then((data) => setConfiguredState(!!data))
                .catch(() => setConfiguredState(false));
        }
    }, [currentlySelectedPolicy]);

    return configuredState;
};
export default usePolicyTopUpConfigurationCheck;
