import { IDependent } from '../redux/slices/PolicyListSlice/types';

export interface IMappings {
    totalDependents: number;
    value: string;
    dependents: { relation: string; number: number }[] | null;
}

export const familyDefinition: { [familyStructure: string]: IMappings } = {
    'Self': {
        totalDependents: 0,
        value: 'Self',
        dependents: null
    },
    'Self + Spouse': {
        totalDependents: 1,
        value: 'Self + Spouse',
        dependents: [
            {
                relation: 'spouse',
                number: 1
            }
        ]
    },
    'Self + Spouse + 1 Kid': {
        totalDependents: 2,
        value: 'Self + Spouse + 1 Kid',
        dependents: [
            {
                relation: 'spouse',
                number: 1
            },
            {
                relation: 'child',
                number: 1
            }
        ]
    },
    'Self + Spouse + 2 Kids': {
        totalDependents: 3,
        value: 'Self + Spouse + 2 Kids',
        dependents: [
            {
                relation: 'spouse',
                number: 1
            },
            {
                relation: 'child',
                number: 2
            }
        ]
    },
    'Self + Spouse + 3 Kids': {
        totalDependents: 4,
        value: 'Self + Spouse + 3 Kids',
        dependents: [
            {
                relation: 'spouse',
                number: 1
            },
            {
                relation: 'child',
                number: 3
            }
        ]
    },
    'Self + Spouse + 4 Kids': {
        totalDependents: 5,
        value: 'Self + Spouse + 4 Kids',
        dependents: [
            {
                relation: 'spouse',
                number: 1
            },
            {
                relation: 'child',
                number: 4
            }
        ]
    },
    'Self + Spouse + 2 Kids + Parents': {
        totalDependents: 5,
        value: 'Self + Spouse + 2 Kids + Parents',
        dependents: [
            {
                relation: 'spouse',
                number: 1
            },
            {
                relation: 'child',
                number: 2
            },
            {
                relation: 'parent',
                number: 2
            }
        ]
    },
    'Self + Spouse + 3 Kids + Parents': {
        totalDependents: 6,
        value: 'Self + Spouse + 3 Kids + Parents',
        dependents: [
            {
                relation: 'spouse',
                number: 1
            },
            {
                relation: 'child',
                number: 3
            },
            {
                relation: 'parent',
                number: 2
            }
        ]
    },
    'Self + Spouse + 4 Kids + Parents': {
        totalDependents: 7,
        value: 'Self + Spouse + 4 Kids + Parents',
        dependents: [
            {
                relation: 'spouse',
                number: 1
            },
            {
                relation: 'child',
                number: 4
            },
            {
                relation: 'parent',
                number: 2
            }
        ]
    },
    'Self + Spouse + 2 Kids + Parents (including in-laws)': {
        totalDependents: 5,
        value: 'Self + Spouse + 2 Kids + Parents (including in-laws)',
        dependents: [
            {
                relation: 'spouse',
                number: 1
            },
            {
                relation: 'child',
                number: 2
            },
            {
                relation: 'parent/parent-in-law',
                number: 2
            }
        ]
    },
    'Self + Spouse + 3 Kids + Parents (including in-laws)': {
        totalDependents: 6,
        value: 'Self + Spouse + 3 Kids + Parents (including in-laws)',
        dependents: [
            {
                relation: 'spouse',
                number: 1
            },
            {
                relation: 'child',
                number: 3
            },
            {
                relation: 'parent/parent-in-law',
                number: 2
            }
        ]
    },
    'Self + Spouse + 4 Kids + Parents (including in-laws)': {
        totalDependents: 7,
        value: 'Self + Spouse + 4 Kids + Parents (including in-laws)',
        dependents: [
            {
                relation: 'spouse',
                number: 1
            },
            {
                relation: 'child',
                number: 4
            },
            {
                relation: 'parent/parent-in-law',
                number: 2
            }
        ]
    },
    'Self + Spouse + 2 Kids + Parents OR parent-in-laws': {
        totalDependents: 5,
        value: 'Self + Spouse + 2 Kids + Parents OR parent-in-laws',
        dependents: [
            {
                relation: 'spouse',
                number: 1
            },
            {
                relation: 'child',
                number: 2
            },
            {
                relation: 'parent|parent-in-law',
                number: 2
            }
        ]
    },
    'Self + Spouse + 3 Kids + Parents OR parent-in-laws': {
        totalDependents: 6,
        value: 'Self + Spouse + 3 Kids + Parents OR parent-in-laws',
        dependents: [
            {
                relation: 'spouse',
                number: 1
            },
            {
                relation: 'child',
                number: 3
            },
            {
                relation: 'parent|parent-in-law',
                number: 2
            }
        ]
    },
    'Self + Spouse + 4 Kids + Parents OR parent-in-laws': {
        totalDependents: 7,
        value: 'Self + Spouse + 4 Kids + Parents OR parent-in-laws',
        dependents: [
            {
                relation: 'spouse',
                number: 1
            },
            {
                relation: 'child',
                number: 4
            },
            {
                relation: 'parent|parent-in-law',
                number: 2
            }
        ]
    },
    'Self + Spouse + 2 Kids + Parents + Parents-in-law': {
        totalDependents: 7,
        value: 'Self + Spouse + 2 Kids + Parents + Parents-in-law',
        dependents: [
            {
                relation: 'spouse',
                number: 1
            },
            {
                relation: 'child',
                number: 2
            },
            {
                relation: 'parent',
                number: 2
            },
            {
                relation: 'parent-in-law',
                number: 2
            }
        ]
    },
    'Self + Spouse + 3 Kids + Parents + Parents-in-law': {
        totalDependents: 8,
        value: 'Self + Spouse + 3 Kids + Parents + Parents-in-law',
        dependents: [
            {
                relation: 'spouse',
                number: 1
            },
            {
                relation: 'child',
                number: 3
            },
            {
                relation: 'parent',
                number: 2
            },
            {
                relation: 'parent-in-law',
                number: 2
            }
        ]
    },
    'Self + Spouse + 4 Kids + Parents + Parents-in-law': {
        totalDependents: 9,
        value: 'Self + Spouse + 4 Kids + Parents + Parents-in-law',
        dependents: [
            {
                relation: 'spouse',
                number: 1
            },
            {
                relation: 'child',
                number: 4
            },
            {
                relation: 'parent',
                number: 2
            },
            {
                relation: 'parent-in-law',
                number: 2
            }
        ]
    },
    'Parents Only': {
        totalDependents: 2,
        value: 'Parents Only',
        dependents: [
            {
                relation: 'parent',
                number: 2
            }
        ]
    },
    'Parents (including in-laws)': {
        totalDependents: 2,
        value: 'Parents (including in-laws)',
        dependents: [
            {
                relation: 'parent/parent-in-law',
                number: 2
            }
        ]
    },
    'Parents OR parents-in-law': {
        totalDependents: 2,
        value: 'Parents OR parents-in-law',
        dependents: [
            {
                relation: 'parent|parent-in-law',
                number: 2
            }
        ]
    },
    'Parents + Parents-in-law': {
        totalDependents: 4,
        value: 'Parents + Parents-in-law',
        dependents: [
            {
                relation: 'parent',
                number: 2
            },
            {
                relation: 'parent-in-law',
                number: 2
            }
        ]
    }
};

export const getDependentMappings = (dependents: IDependent[], familyStruture: string): IMappings => {
    const value: IMappings = {
        value: familyStruture,
        dependents: [],
        totalDependents: 0
    };

    const newDependents: { relation: string; number: number }[] = [];

    const totalDependentsExceptSelf = dependents.filter((dependent) => {
        return dependent.relationship.toLocaleLowerCase() !== 'self';
    });

    totalDependentsExceptSelf.forEach((dependent) => {
        value.totalDependents += 1;
        value.dependents?.push({ relation: dependent.relationship.toLocaleLowerCase(), number: 1 });
    });

    value.dependents?.forEach((item) => {
        const items = value.dependents?.filter((itemAlt) => {
            return item.relation.toLocaleLowerCase() === itemAlt.relation.toLocaleLowerCase();
        });
        const index = newDependents.findIndex((itemAlt) => {
            return itemAlt.relation === item.relation;
        });
        if (index == -1 && items?.length) {
            newDependents.push({ relation: item.relation, number: items?.length });
        }
    });

    value.dependents = newDependents;
    return value;
};
